import * as d3 from "d3";
import React, { FC } from "react";
import { TrackContentProps } from "../track";

const HIGHLIGHTED_DEPTH_INDICATOR_THICKNESS = 2;

interface HighlightedDepthIndicatorProps extends TrackContentProps {
  highlightedIndex: number | undefined;
}

export const HighlightedDepthIndicator: FC<HighlightedDepthIndicatorProps> = ({
  highlightedIndex,
  renderingConstraints,
  size,
}) => {
  if (size && highlightedIndex !== undefined) {
    const highlightedDepthTop =
      renderingConstraints.trackStartCenter +
      renderingConstraints.trackStep * highlightedIndex -
      HIGHLIGHTED_DEPTH_INDICATOR_THICKNESS / 2;

    return (
      <div
        className="highlighted-depth-indicator"
        style={{ top: `${highlightedDepthTop}px` }}
      ></div>
    );
  }

  return null;
};

interface LinearHighlightedDepthIndicatorProps extends TrackContentProps {
  highlightedDepth: number | undefined;
  minDepth: number;
  maxDepth: number;
}

export const LinearHighlightedDepthIndicator: FC<
  LinearHighlightedDepthIndicatorProps
> = ({ highlightedDepth, renderingConstraints, size, minDepth, maxDepth }) => {
  if (size && highlightedDepth !== undefined) {
    const scale = d3
      .scaleLinear()
      .range([
        renderingConstraints.trackStartCenter -
          HIGHLIGHTED_DEPTH_INDICATOR_THICKNESS / 2,
        size.height -
          renderingConstraints.imageCenteredTrackMargin.bottom +
          -HIGHLIGHTED_DEPTH_INDICATOR_THICKNESS / 2,
      ])
      .domain([minDepth, maxDepth]);

    const highlightedDepthTop = scale(highlightedDepth);

    return (
      <div
        className="highlighted-depth-indicator"
        style={{ top: `${highlightedDepthTop}px` }}
      ></div>
    );
  }

  return null;
};
