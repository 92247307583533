import "firebase/compat/analytics";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { useEffect, useState } from "react";
import { firebaseConfig } from "./configureFirebase";

type AuthenticationDataType = {
  user: firebase.User | undefined;
  loading: boolean;
};

// Sessions should last a maximum of one week
const SESSION_DURATION = 1000 * 60 * 60 * 24 * 7;

const useFirebaseAuthentication = (): AuthenticationDataType => {
  const [user, setUser] = useState<firebase.User>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unregisterAuthObserver = firebase
      .auth()
      .onAuthStateChanged((user) => {
        setUser(user ?? undefined);
        setLoading(false);

        // Add logic for logging out user after a maximum session of one day. Forcing a new login after expiration.
        let sessionTimeout = null;
        if (user === null || user === undefined) {
          sessionTimeout && clearTimeout(sessionTimeout);
          sessionTimeout = null;
        } else {
          user.getIdTokenResult().then((idTokenResult) => {
            const authTime = idTokenResult.claims.auth_time * 1000;
            const millisecondsUntilExpiration = Math.max(
              0,
              SESSION_DURATION - (Date.now() - authTime)
            );
            sessionTimeout = setTimeout(
              () => firebase.auth().signOut(),
              millisecondsUntilExpiration
            );
          });
        }

        // Only set user property if analytics is activated.
        if (firebaseConfig.measurementId) {
          firebase.analytics().setUserProperties({
            email_domain: user?.email?.split("@").pop(),
          });
        }
      });
    return unregisterAuthObserver;
  }, []);

  return { user, loading };
};

export default useFirebaseAuthentication;
