import { NeutralColors } from "@fluentui/react";
import { CSSProperties } from "react";
import { AnalyticsName } from "../../constants";
import { AnalyticsDataColorsOverride } from "../../types";

export const getElementColor = (
  element: string,
  activeTab: AnalyticsName
): string => {
  const tabSpecificColor = tabSpecificDataColors[activeTab][element];
  return tabSpecificColor
    ? tabSpecificColor
    : commonAnalyticsDataColors[element];
};

export const getElementColorStyles = (
  elementName: string,
  analyticsName: AnalyticsName
): CSSProperties => {
  const elementColor = getElementColor(elementName, analyticsName);

  if (!elementColor) {
    return { border: `1px solid ${NeutralColors.gray130}` };
  }
  return { background: elementColor };
};

const tabSpecificDataColors: AnalyticsDataColorsOverride = {
  [AnalyticsName.SPEC_CAM]: {
    TOC: "rgb(100, 100, 100)",
    Bassanite: "rgb(127, 255, 212)",
    Gypsum: "rgb(230, 230, 250)",
  },
  [AnalyticsName.QEM_SCAN]: {
    Ankerite: "rgb(128,92,255)",
    Goethite: "rgb(193,117,116)",
    Celestine: "rgb(157,51,200)",
    Titanite: "rgb(210,180,140)",
    Olivine: "rgb(200,255,140)",
    Fluorite: "rgb(118,26,150)",
    Jarosite: "rgb(190,250,20)",
  },
  [AnalyticsName.TOC]: {
    TOC: "rgb(0, 0, 0)",
  },
  [AnalyticsName.XRD]: { Dolomite: "rgb(128,122,255)" },
  [AnalyticsName.XRF]: {},
};

export const commonAnalyticsDataColors: Record<string, string> = {
  "MgO (Magnesium oxide)": "rgb(192, 100, 255)",
  "Al2O3 (Aluminium oxide)": "rgb(154, 205, 50)",
  "SiO2 (Silicon dioxide)": "rgb(255, 255, 0)",
  "K2O (Potassium oxide)": "rgb(0, 128, 128)",
  "CaO (Calcium oxide)": "rgb(100, 92, 255)",
  "TiO2 (Titanium dioxide)": "rgb(255, 0, 0)",
  "Fe2O3 (Iron(III) oxide)": "rgb(128, 0, 0)",
  "Fe (Iron)": "rgb(120, 120, 0)",
  "Ti (Titanium)": "rgb( 255, 0, 0)",
  "Ca (Calcium)": "rgb( 128, 122, 255)",
  "K (Potassium)": "rgb( 77, 230, 230)",
  "Al (Aliminium)": "rgb(176, 196, 222)",
  "Si (Silicon)": "rgb(255, 255, 0)",
  "Mg (Magnesium)": "rgb(192, 192, 255)",
  "Sb (Antimony)": "rgb(210, 180, 140)",
  "Sn (Tin)": "rgb(190, 200, 0)",
  "Cd (Cadmium)": "rgb(204, 102, 0)",
  "Pd (Palladium)": "rgb(107, 142, 35)",
  "Ag (Silver)": "rgb(255, 204, 255)",
  "Mo (Molybdenum)": "rgb(84, 102, 118)",
  "Nb (Niobium)": "rgb(189, 183, 107)",
  "Th (Thorium)": "rgb(48, 84, 150)",
  "Zr (Zirconium)": "rgb( 255, 0, 255)",
  "Sr (Strontium)": "rgb(255, 127, 80)",
  "U (Uranium)": "rgb(130, 174, 42)",
  "Rb (Rubidium)": "rgb(255, 215, 0)",
  "As (Arsenic)": "rgb(175, 238, 238)",
  "Se (Selenium)": "rgb(190, 250, 20)",
  "Au (Gold)": "rgb(218, 165, 32)",
  "Pb (Lead)": "rgb(99, 182, 165)",
  "W (Wolfram)": "rgb(58, 77, 19)",
  "Zn (Zinc)": "rgb(204, 0, 102)",
  "Cu (Copper)": "rgb(255, 192, 128)",
  "Ni (Nickel)": "rgb(0, 51, 153)",
  "Co (Cobalt)": "rgb(51, 204, 255)",
  "Cr (Chromium)": "rgb(150, 83, 169)",
  "V (Vanadium)": "rgb(152, 251, 152)",
  "Ba (Barium)": "rgb( 128, 64, 128)",
  "Cl (Chlorine)": "rgb(181, 119, 121)",
  "Mn (Manganese)": "rgb(238, 169, 184)",
  "S (Sulfur)": "rgb(255, 128, 0)",
  "P (Phosphorus)": "rgb(255, 20, 147)",
  Quartz: "rgb(240, 230, 140)",
  "K-Feldspar": "rgb(0, 128, 128)",
  Plagioclase: "rgb(0, 153, 204)",
  Calcite: "rgb(192,192,255)",
  Ankerite: "rgb(128,122,255)",
  Kutnohorite: "rgb(148, 0, 211)",
  Magnesite: "rgb(102, 137, 144)",
  Siderite: "rgb(181,119,121)",
  Rhodochrosite: "rgb(238, 169, 184)",
  Aragonite: "rgb(255, 221, 255)",
  "Total Clay": "rgb(174, 170, 170)",
  Pyrite: "rgb(255,215,0)",
  Marcasite: "rgb(169, 208, 142)",
  "Other Sulphides": "rgb(255, 255, 153)",
  Hematite: "rgb(153, 102, 51)",
  Goethite: "rgb(255, 204, 153)",
  Magnetite: "rgb(255, 153, 153)",
  Anatase: "rgb(255, 0, 0)",
  Rutile: "rgb(255, 153, 51)",
  Titanite: "rgb(255, 204, 153)",
  "Other Oxides": "rgb(204, 153, 0)",
  Olivine: "rgb(102, 102, 51)",
  Pyroxenes: "rgb(0, 204, 153)",
  Amphiboles: "rgb(153, 0, 51)",
  Garnet: "rgb(204, 153, 0)",
  Gypsum: "rgb(204, 255, 255)",
  Bassanite: "rgb(221, 235, 247)",
  Anhydrite: "rgb(189, 215, 238)",
  Barite: "rgb(128,64,128)",
  Celestine: "rgb(102, 153, 255)",
  Jarosite: "rgb(0, 204, 255)",
  Fluorite: "rgb(102, 255, 255)",
  Halite: "rgb(255,192,192)",
  "Other Halides": "rgb(250, 184, 241)",
  Apatite: "rgb(255, 20, 147)",
  Zeolite: "rgb(50, 50, 50)",
  "Opaline silica": "rgb(128, 128, 128)",
  Portlandite: "rgb(191, 191, 191)",
  Brownmillerite: "rgb(142, 169, 219)",
  Hydrocalumite: "rgb(0, 51, 153)",
  Ettringite: "rgb(68, 114, 196)",
  Amorphous: "rgb(172, 185, 202)",
  "Additional Minerals": "rgb(51, 63, 79)",
  "Total Silicates": "rgb(154, 205, 50)",
  "Total Carbonates": "rgb(153, 51, 255)",
  "Total Other Minerals": "rgb(132, 151, 176)",
  "All minerals total": "rgb(48,84,150)",
  "Carbonate total": "rgb(153,51,255)",
  "Chlorite total": "rgb(171,171,0)",
  "Clay total": "rgb(174,170,170)",
  "HC Pixel total": "rgb(0,170,170)",
  "HC total": "rgb(0,128,128)",
  "Illite total": "rgb(0,192,0)",
  "Kaolin total": "rgb(210,105,30)",
  "Mica total": "rgb(0,100,0)",
  "Smectite total": "rgb(245,222,179)",
  "Sulphate total": "rgb(51,204,255)",
  "Unknown minerals": "rgb(128,64,0)",
  "Fe-carbonate": "rgb(128,122,255)",
  Illite: "rgb(0,192,0)",
  "Smectite-illite": "rgb(84,102,118)",
  Hectorite: "rgb(153,204,0)",
  Nontronite: "rgb(192,192,0)",
  Saponite: "rgb(189,183,107)",
  Montmorillonite: "rgb(245,222,179)",
  Corrensite: "rgb(204,153,0)",
  Paragonite: "rgb(234,220,92)",
  Muscovite: "rgb(0,100,0)",
  Phengite: "rgb(107,142,35)",
  "Mica-illite": "rgb(0,146,0)",
  Glauconite: "rgb(144,238,144)",
  "Kaolinite-lo": "rgb(255,128,0)",
  "Kaolinite-hi": "rgb(255,165,0)",
  Dickite: "rgb(178,34,34)",
  Halloysite: "rgb(218,165,32)",
  Zircon: "rgb(255,0,255)",
  Epidote: "rgb(204,51,0)",
  Biotite: "rgb(160,82,45)",
  "Albedo index": "rgb(0,112,192)",
  "Correlation index": "rgb(128,0,0)",
  "Standard deviation index": "rgb(128,128,128)",
  "Mineral id index": "rgb(237,125,49)",
  "Ferroan index": "rgb(192,0,0)",
  "Carbonate pos index": "rgb(153,51,255)",
  "Dolomite pos index": "rgb(152,152,255)",
  "Dolomite hydroxyl index": "rgb(192,192,255)",
  "Dolomite particle index": "rgb(128,92,255)",
  "Clay hydroxyl depth index": "rgb(107,142,35)",
  "Alohpos index": "rgb(154,205,50)",
  "Alohdepth index": "rgb(144,238,144)",
  "Mica pos index": "rgb(0,100,0)",
  "Illite pos index": "rgb(0,192,0)",
  "Kaolin index": "rgb(210,105,30)",
  "Chlorite index": "rgb(171,171,0)",
  "HC composition index": "rgb(51,63,79)",
  "HC pixelcount solid": "rgb(0,128,128)",
  "HC pixelcount liquid": "rgb(77,230,230)",
  "Hydrocarb-solid": "rgb(0,128,128)",
  "Hydrocarb-liquid": "rgb(155,194,230)",
  Mask: "rgb(0,176,204)",
  "Total pixminsig": "rgb(210,180,140)",
  "DcorrCorFac index": "rgb(210,180,140)",
  "Dcorr pixel total": "rgb(210,180,140)",
  "HydroCorFac index": "rgb(210,180,140)",
  "HydroCorPos index": "rgb(210,180,140)",
  "Alohcrystly index": "rgb(198,224,180)",
  "Alohasym index": "rgb(178,34,34)",
  "Silica index": "rgb(255,255,0)",
  "Silica pixel total": "rgb(255,255,0)",
  "Anhydrite index": "rgb(218,112,214)",
  "Anhydrite pixel total": "rgb(218,112,214)",
  "Hydroxyl area index": "rgb(107,142,35)",
  "Water area index": "rgb(175,238,238)",
  "Clay area index": "rgb(174,170,170)",
  "PLSRADD pixel total": "rgb(210,180,140)",
  "Calculated Gamma Ray": "rgb(255,0,0)",
  "Volume of Clay phases": "rgb(112,173,71)",
  "Calculated Density": "rgb(0,112,192)",
  "Calculated Neutron porosity": "rgb(255,255,0)",
  Albite: "rgb(128,255,255)",
  Oligoclase: "rgb(77,230,230)",
  "Andesine/Anorthite": "rgb(0,192,192)",
  Chlorite: "rgb(171,171,0)",
  Kaolinite: "rgb(210,105,30)",
  Smectite: "rgb(245,222,179)",
  OtherClays: "rgb(107,142,35)",
  QuartzClayMix: "rgb(154,205,50)",
  "Rutile Anatase": "rgb(255,0,0)",
  Dolomite: "rgb(152,152,255)",
  FeDolomite: "rgb(128,122,255)",
  Marl: "rgb(155,160,191)",
  FeOxides: "rgb(128,0,0)",
  "FeOxides main": "rgb(128,0,0)",
  "Other Phases": "rgb(211,211,211)",
  Unclassified: "rgb(50,50,50)",
  "Quartz gs": "rgb(240,230,140)",
  KFeldspar: "rgb(0,128,128)",
  "Albite gs": "rgb(128,255,255)",
  "Oligoclase gs": "rgb(77,230,230)",
  "AndesineAnorthite gs": "rgb(0,192,192)",
  "Biotite gs": "rgb(160,82,45)",
  "Muscovite gs": "rgb(0,100,0)",
  "Glauconite gs": "rgb(144,238,144)",
  "Illite gs": "rgb(0,192,0)",
  "Chlorite gs": "rgb(171,171,0)",
  "Kaolinite gs": "rgb(210,105,30)",
  "Smectite gs": "rgb(245,222,179)",
  "OtherClays gs": "rgb(107,142,35)",
  "QuartzClayMix gs": "rgb(154,205,50)",
  "Zircon gs": "rgb(255,0,255)",
  "Rutile Anatase gs": "rgb(255,0,0)",
  "Apatite gs": "rgb(255,20,147)",
  "Calcite gs": "rgb(192,192,255)",
  "Dolomite gs": "rgb(152,152,255)",
  "FeDolomite gs": "rgb(128,122,255)",
  "Ankerite gs": "rgb(128,92,255)",
  "Marl gs": "rgb(155,160,191)",
  "Siderite gs": "rgb(181,119,121)",
  "FeOxides gs": "rgb(128,0,0)",
  "Pyrite gs": "rgb(255,215,0)",
  "Barite gs": "rgb(128,64,128)",
  "Anhydrite gs": "rgb(218,112,214)",
  "Halite gs": "rgb(255,192,192)",
  "OtherPhases gs": "rgb(211,211,211)",
  "Unclassified gs": "rgb(50,50,50)",
  Anorthoclase: "rgb(0,170,170)",
  "K-feldspar main": "rgb(0,128,128)",
  Andesine: "rgb(221,235,247)",
  Labradorite: "rgb(155,194,230)",
  Bytownite: "rgb(0,176,204)",
  Anorthite: "rgb(0,0,204)",
  Phlogopite: "rgb(204,153,0)",
  "Biotite main": "rgb(160,82,45)",
  Annite: "rgb(204,51,0)",
  "Muscovite main": "rgb(0,100,0)",
  FeMuscovite: "rgb(221,160,221)",
  MuscoviteIllite: "rgb(0,146,0)",
  "Illite main": "rgb(0,192,0)",
  Clinochlore: "rgb(230,230,0)",
  "Chlorite main": "rgb(171,171,0)",
  Chamosite: "rgb(120,120,0)",
  Berthierine: "rgb(244,164,96)",
  "Illite-Smectite": "rgb(84,102,118)",
  Beidellite: "rgb(146,168,0)",
  Stevensite: "rgb(238,232,170)",
  Vermiculite: "rgb(222,184,135)",
  Palygorskite: "rgb(171,153,0)",
  Sepiolite: "rgb(102,205,170)",
  OtherClaysBLS: "rgb(107,142,35)",
  "Siderite main": "rgb(181,119,121)",
  "Siderite/Magnesite": "rgb(140,128,133)",
  FeTiOxides: "rgb(255,225,0)",
  Ilmenite: "rgb(255,128,0)",
  Ulvospinel: "rgb(255,165,0)",
  "Barite main": "rgb(128,64,128)",
  "Barite/Celestine": "rgb(128,64,192)",
  "Halite main": "rgb(255,192,192)",
  Sylvite: "rgb(255,192,255)",
  Dravite: "rgb(30,240,30)",
  "Dravite/Schorl": "rgb(0,176,80)",
  Schorl: "rgb(0,128,0)",
  Elbaite: "rgb(250,210,188)",
  Almandine: "rgb(178,34,34)",
  Spessartine: "rgb(255,69,0)",
  Pyrope: "rgb(255,127,80)",
  Grossular: "rgb(150,230,150)",
  Andradite: "rgb(140,180,50)",
  Uvarovite: "rgb(60,179,113)",
  "Epidote main": "rgb(0,255,0)",
  Zoisite: "rgb(230,230,250)",
  Piemontite: "rgb(255,99,71)",
  Allanite: "rgb(128,64,0)",
  "Kyanite/Sillimanite/Andalusite": "rgb(173,216,230)",
  Staurolite: "rgb(128,64,64)",
  Topaz: "rgb(175,238,238)",
  Augite: "rgb(255,50,150)",
  Diopside: "rgb(255,204,255)",
  "Diopside/Hedenbergite": "rgb(255,204,255)",
  Hedenbergite: "rgb(255,102,255)",
  Aegirine: "rgb(204,0,102)",
  Hornblende: "rgb(255,80,80)",
  Tremolite: "rgb(255,255,102)",
  Actinolite: "rgb(191,143,0)",
  Anthophyllite: "rgb(204,102,0)",
  Glaucophane: "rgb(255,204,0)",
  Riebeckite: "rgb(204,255,255)",
  Pargasite: "rgb(0,51,153)",
  Enstatite: "rgb(51,204,255)",
  Orthopyroxene: "rgb(240,220,240)",
  Ferrosillite: "rgb(150,83,169)",
  Talc: "rgb(80,0,80)",
  Antigorite: "rgb(152,251,152)",
  "Lizardite/Chrysotile": "rgb(58,77,19)",
  Forsterite: "rgb(204,255,204)",
  Fayalite: "rgb(130,174,42)",
  Cordierite: "rgb(76,101,25)",
  Chloritoid: "rgb(216,191,216)",
  Thorite: "rgb(128,128,0)",
  Zeolites: "rgb(220,20,60)",
  Magnesiochromite: "rgb(160,210,205)",
  Chromite: "rgb(255,155,255)",
  "Chromite/Spinel": "rgb(100,0,100)",
  Spinel: "rgb(160,0,160)",
  Hercynite: "rgb(255,70,255)",
  Gahnite: "rgb(119,80,169)",
  Cassiterite: "rgb(0,250,154)",
  Corundum: "rgb(132,127,167)",
  Baddeleyite: "rgb(79,40,183)",
  Perovskite: "rgb(255,128,255)",
  "Hollandite/Coronadite": "rgb(205,92,92)",
  "Monazite main": "rgb(0,168,255)",
  Xenotime: "rgb(0,255,153)",
  Florencite: "rgb(135,206,235)",
  Chalcopyrite: "rgb(222,143,0)",
  CuSulphides: "rgb(0,191,255)",
  Sphalerite: "rgb(0,0,255)",
  Galena: "rgb(255,0,102)",
  Pentlandite: "rgb(165,0,33)",
  Gibbsite: "rgb(218,165,32)",
  Scheelite: "rgb(127,255,212)",
  Halotrichite: "rgb(190,200,0)",
  Schwertmannite: "rgb(190,200,0)",
  Aluminium: "rgb(176,196,222)",
  Copper: "rgb(255,192,128)",
  Steel: "rgb(132,151,176)",
  OtherPhasesBLS: "rgb(211,211,211)",
  LowConfidence: "rgb(128,128,128)",
  "Unclassified main": "rgb(50,50,50)",
  Balance: "rgb(50,50,50)",
};
