/* eslint-disable @typescript-eslint/no-explicit-any */
import * as d3 from "d3";
import { throttle } from "lodash";
import { v4 as uuidv4 } from "uuid";
import { TooltipType } from "../../../constants";
import { PinnableTooltipData, Stratigraphy } from "../../../types/types";

interface TooltipRow {
  label: string;
  value: string | number;
  dotColor?: string;
}

export interface TooltipRowGroup {
  heading: string;
  rows: TooltipRow[];
}

export interface TooltipAttributes {
  left: number;
  top: number;
  tooltipAboveMouse: boolean;
}

export interface TooltipData {
  stratigraphy?: Stratigraphy;
  groups: TooltipRowGroup[];
  drawLines?: boolean;
}

const BEAK_HEIGHT = 14;
const TOOLTIP_ROW_HEIGHT = 16;
const TOOLTIP_MARGIN = 8;
export const DISTANCE_FROM_TOOLTIP_TO_MOUSE = 8;

export function getTooltipHeight(numRows: number): number {
  return numRows * TOOLTIP_ROW_HEIGHT + TOOLTIP_MARGIN * 2 + BEAK_HEIGHT;
}

export const addTooltipElement = (
  id: string
): d3.Selection<HTMLDivElement, unknown, HTMLElement, any> =>
  d3.select("body").append("div").attr("class", "plot-tooltip").attr("id", id);

export const createTooltipAdder = (
  tooltipId: string,
  getTooltipData: (data: any) => TooltipData,
  setActiveTooltip: (tooltipData: PinnableTooltipData | undefined) => void,
  tooltipType: TooltipType,
  showStratigraphyInTooltip?: boolean
): [
  (e: any, data: any, useMouseHandler?: boolean) => void,
  (useMouseHandler?: boolean) => void,
  (e: any) => void,
  () => void
] => {
  const tooltip = d3.select(`#${tooltipId}`);

  const getToolTipAttributes = (tooltip: any, e: any): TooltipAttributes => {
    const tooltipNode = tooltip.node() as HTMLDivElement;
    const tooltipWidth = tooltipNode.clientWidth;
    const tooltipHeight = tooltipNode.clientHeight;
    const tooltipAboveMouse =
      e.clientY > tooltipHeight + BEAK_HEIGHT + DISTANCE_FROM_TOOLTIP_TO_MOUSE;

    const left = Math.min(
      e.clientX - tooltipWidth / 2,
      window.innerWidth - tooltipWidth
    );
    const top = tooltipAboveMouse
      ? e.clientY - tooltipHeight - BEAK_HEIGHT - DISTANCE_FROM_TOOLTIP_TO_MOUSE
      : e.clientY + BEAK_HEIGHT + DISTANCE_FROM_TOOLTIP_TO_MOUSE;

    return {
      left: left,
      top: top,
      tooltipAboveMouse: tooltipAboveMouse,
    } as TooltipAttributes;
  };

  const setTooltipAttributes = (
    tooltip: any,
    attributes: TooltipAttributes
  ) => {
    tooltip
      .style("left", `${attributes.left}px`)
      .style("top", `${attributes.top}px`);

    tooltip
      .select(".plot-tooltip__beak")
      .classed("plot-tooltip__beak--top", !attributes.tooltipAboveMouse);
  };

  const setTooltipDataForDrawingLines = (
    e: any,
    tooltipData: TooltipData,
    tooltipAttributes: TooltipAttributes
  ) => {
    let elementDataId =
      (e.target as HTMLElement).getAttribute("data-element-id") ?? undefined;
    if (!elementDataId) {
      elementDataId = uuidv4();
      e.target.setAttribute("data-element-id", elementDataId);
    }

    setActiveTooltip({
      tooltipData: showStratigraphyInTooltip
        ? tooltipData
        : { groups: tooltipData.groups },
      tooltipAttributes: tooltipAttributes,
      sourceElementId: elementDataId,
      tooltipType: tooltipType,
      drawLines: tooltipData.drawLines ?? true,
    });
  };

  const mouseMoveHandler = throttle((e: MouseEvent): void => {
    const data = (e.target as any).__data__;
    if (data) {
      const tooltipData = getTooltipData(data);
      const tooltipAttributes = getToolTipAttributes(tooltip, e);
      tooltip.call(setTooltipAttributes, tooltipAttributes);
      setTooltipDataForDrawingLines(e, tooltipData, tooltipAttributes);
    } else {
      document.removeEventListener("mousemove", mouseMoveHandler);
    }
  }, 25);

  const addHoveredTooltip = (e: any, data: any, useMouseHandler = true) => {
    if (data) {
      const tooltipData = getTooltipData(data);
      const tooltipHtml = getTooltipHtml(
        tooltipData,
        showStratigraphyInTooltip
      );
      tooltip.transition().duration(150).style("opacity", 0.9);
      const tooltipAttributes = getToolTipAttributes(tooltip, e);
      tooltip.html(tooltipHtml).call(setTooltipAttributes, tooltipAttributes);

      if (useMouseHandler) {
        document.addEventListener("mousemove", mouseMoveHandler);
      }

      setTooltipDataForDrawingLines(e, tooltipData, tooltipAttributes);
    }
  };

  const removeHoveredTooltip = (useMouseHandler = true) => {
    tooltip.transition().delay(100).duration(50).style("opacity", 0);
    if (useMouseHandler) {
      document.removeEventListener("mousemove", mouseMoveHandler);
    }

    setActiveTooltip(undefined);
  };

  const addSiblingTooltip = (e: any) => {
    const data = e.target.__data__;
    if (data) {
      const tooltipData = getTooltipData(data);
      const tooltipHtml = getTooltipHtml(
        tooltipData,
        showStratigraphyInTooltip
      );
      tooltip.transition().duration(150).style("opacity", 0.9);
      const tooltipAttributes = getToolTipAttributes(tooltip, e);
      tooltip.html(tooltipHtml).call(setTooltipAttributes, tooltipAttributes);
    }
  };

  const removeSiblingTooltip = () => {
    tooltip.transition().delay(100).duration(50).style("opacity", 0);
  };

  return [
    addHoveredTooltip,
    removeHoveredTooltip,
    addSiblingTooltip,
    removeSiblingTooltip,
  ];
};

function getTooltipHtml(
  data: TooltipData,
  showStratigraphyInTooltip?: boolean
): string {
  let html = "";

  if (showStratigraphyInTooltip && data.stratigraphy) {
    html += `
      <div class="plot-tooltip__stratigraphy">
        <div class="plot-tooltip__stratigraphy__item">
          <span style="background: ${data.stratigraphy.group.color};"></span>
          <span>${data.stratigraphy.group.name}</span>
        </div>
        <div class="plot-tooltip__stratigraphy__item">
          <span style="background: ${data.stratigraphy.formation.color};"></span>
          <span>${data.stratigraphy.formation.name}</span>
        </div>
      </div>
    `;
  }

  for (const group of data.groups) {
    let groupHtml = "";
    groupHtml += `<div class="plot-tooltip__heading">${group.heading}</div>`;

    for (const row of group.rows) {
      groupHtml += `
      <div class="plot-tooltip__row">
        ${
          row.dotColor
            ? `<span class="color-dot" style="background:${row.dotColor}"></span>&nbsp;`
            : ""
        }
        <span class="label">${row.label}${
        row.value !== "" ? ":&nbsp;" : ""
      }</span>
        ${row.value !== "" ? `<span>${row.value}</span>` : ""}
      </div>`;
    }
    html += `<div class="plot-tooltip__group">${groupHtml}</div>`;
  }

  html += '<div class="plot-tooltip__beak"></div>';
  return html;
}
