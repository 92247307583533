import {
  ITextFieldProps,
  ITextFieldStyles,
  mergeStyleSets,
  TextField as FluentTextField,
} from "@fluentui/react";
import { NeutralColors } from "@fluentui/theme";
import React, { FC, useMemo } from "react";
import { Colors } from "../../../constants";

const textFieldStyles: Partial<ITextFieldStyles> = {
  root: {
    color: NeutralColors.gray130,
    selectors: {
      "&.is-active:not([invalid])": {
        ".ms-TextField-fieldGroup::after": {
          border: `2px solid ${Colors.primary} !important`,
        },
      },
      "&.is-active[invalid]": {
        ".ms-TextField-fieldGroup::after": {
          border: `2px solid ${Colors.red} !important`,
        },
      },
    },
  },
  fieldGroup: {
    border: `1px solid ${NeutralColors.gray110}`,
    borderRadius: 2,
    color: NeutralColors.gray110,
    selectors: {
      ".ms-TextField-field": {
        lineHeight: 20,
      },
      "&:hover": {
        border: `1px solid ${NeutralColors.gray160}`,
      },
      "&.is-disabled": {
        background: NeutralColors.gray30,
        border: `1px solid ${NeutralColors.gray90}`,
      },
    },
  },
  subComponentStyles: {
    label: {
      root: {
        color: NeutralColors.gray130,
        fontWeight: 600,
      },
    },
  },
};

export const TextField: FC<ITextFieldProps> = ({ styles, ...restProps }) => {
  const mergedStyles = useMemo(
    () => mergeStyleSets(textFieldStyles, styles),
    [styles]
  );
  return <FluentTextField styles={mergedStyles} {...restProps} />;
};
