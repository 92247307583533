import { FontIcon } from "@fluentui/react";
import React, { FC } from "react";
import { useSelector } from "react-redux";
import { SIDEBAR_MENU_DROPDOWNS, useDropdowns } from "../../../context";
import { useResizeObserver } from "../../../hooks";
import { selectRealDepthModeActive } from "../../../store";
import {
  Cutting,
  MissingDataCollection,
  RenderingConstraints,
} from "../../../types/types";
import { DataWarningChart } from "../../common/dataWarningChart/DataWarningChart";
import { DataWarningFrame } from "../frames/track-frames";
import { TrackContent, TrackFooter, TrackHeader } from "../track";

export const WIDTH_OF_DATA_WARNING_TRACK = 30;

interface DataWarningTrackImplProps {
  renderingConstraints: RenderingConstraints;
  data: MissingDataCollection[];
  cuttings: Cutting[];
}

export const DataWarningTrackImpl: FC<DataWarningTrackImplProps> = ({
  renderingConstraints,
  data,
  cuttings,
}) => {
  const { closeDropdown } = useDropdowns();
  const realDepthModeActive = useSelector(selectRealDepthModeActive);

  const [size, contentRef] = useResizeObserver<HTMLDivElement>();

  return (
    <DataWarningFrame
      className={"data-warning-track"}
      onClick={() => closeDropdown(SIDEBAR_MENU_DROPDOWNS)}
    >
      <TrackHeader>
        <FontIcon aria-label="Warning Track" iconName="Warning" />
      </TrackHeader>
      <TrackContent className="data-warning-track__chart-root" ref={contentRef}>
        {size && (
          <DataWarningChart
            data={data}
            size={size}
            trackMargin={renderingConstraints.imageCenteredTrackMargin}
            cuttingDepths={cuttings.map((cutting) => cutting.depth)}
            realDepthModeActive={realDepthModeActive}
          />
        )}
      </TrackContent>
      <TrackFooter />
    </DataWarningFrame>
  );
};

export const WarningTrack = React.memo(DataWarningTrackImpl);
