import { useEffect } from "react";
import { distinctUntilChanged, map } from "rxjs/operators";
import { AnalyticsState } from "../components/cuttings-insight/dataAnalyticsPane/analyticsSlice";
import { AnalyticsName } from "../constants";
import { JsonWithoutNull, PlotState, StoredPlot } from "../types";
import { setQueryParam } from "../utils";
import {
  DEPTH_QUERY_PARAM_NAME,
  ELEMENT_QUERY_PARAM_SUFFIX,
  GROUP_QUERY_PARAM_SUFFIX,
  IMAGE_A_QUERY_PARAM_NAME,
  PLOTS_QUERY_PARAM_NAME,
  SELECTED_WELL_TOPS_QUERY_PARAM,
  TAB_QUERY_PARAM_NAME,
  WELLBORE_QUERY_PARAM_NAME,
} from "./initialStateFromUrl";
import { StoreState, storeState$ } from "./store";

/**
 * Setup subscriptions to update URL
 */
export function useSyncStateToUrl(): void {
  useEffect(() => {
    const analyticsSubscription = storeState$
      .pipe(
        map<StoreState, AnalyticsState>((state) => state.analytics),
        distinctUntilChanged<AnalyticsState>()
      )
      .subscribe((analytics) => {
        setQueryParam(TAB_QUERY_PARAM_NAME, analytics.selectedTab);

        for (const tabName of Object.values(AnalyticsName)) {
          setQueryParam(
            `${tabName}${ELEMENT_QUERY_PARAM_SUFFIX}`,
            analytics.tabSelections[tabName].elements
          );
          setQueryParam(
            `${tabName}${GROUP_QUERY_PARAM_SUFFIX}`,
            analytics.tabSelections[tabName].groups
          );
        }
      });

    const plotsSubscription = storeState$
      .pipe(
        map((state) => state.analytics.plots),
        distinctUntilChanged()
      )
      .subscribe((plots) => {
        setQueryParam(
          PLOTS_QUERY_PARAM_NAME,
          getPlotStateToStoreInUrl(plots),
          true
        );
      });

    const wellboreSubscription = storeState$
      .pipe(
        map((state) => state.cuttingsInsight.selectedWellbore),
        distinctUntilChanged()
      )
      .subscribe((selectedWellbore) => {
        setQueryParam(WELLBORE_QUERY_PARAM_NAME, selectedWellbore?.name);
      });

    const cuttingSubscription = storeState$
      .pipe(
        map((state) => state.cuttingsInsight.selectedCutting),
        distinctUntilChanged()
      )
      .subscribe((selectedCutting) => {
        setQueryParam(DEPTH_QUERY_PARAM_NAME, selectedCutting?.depth);
      });

    const imageTypeSubscription = storeState$
      .pipe(
        map((state) => state.cuttingsInsight.selectedImageType),
        distinctUntilChanged()
      )
      .subscribe((selectedImageType) => {
        setQueryParam(IMAGE_A_QUERY_PARAM_NAME, selectedImageType);
      });

    const selectedWellTopsSubscription = storeState$
      .pipe(
        map((state) => state.cuttingsInsight.selectedWellTops),
        distinctUntilChanged()
      )
      .subscribe((selectedWellTops) => {
        setQueryParam(SELECTED_WELL_TOPS_QUERY_PARAM, selectedWellTops);
      });

    return () => {
      plotsSubscription.unsubscribe();
      analyticsSubscription.unsubscribe();
      wellboreSubscription.unsubscribe();
      cuttingSubscription.unsubscribe();
      imageTypeSubscription.unsubscribe();
      selectedWellTopsSubscription.unsubscribe();
    };
  }, []);
}

const getPlotStateToStoreInUrl = (plots: PlotState[]): JsonWithoutNull => {
  const storedPlots: StoredPlot[] = plots.map((p) => ({
    t: p.tab,
    c: p.selectedChartType,
    e: p.selectedElements.elements,
    g: p.selectedElements.groups,
  }));
  return storedPlots as unknown as JsonWithoutNull;
};
