import React, { FC, useCallback, useState } from "react";
import { DropdownName } from "../constants";
import { out } from "../utils";

type DropdownContextType = {
  isOpen: Record<DropdownName, boolean>;
  dropdownsAreOpen: boolean;
  closeDropdown: (dropdownName: DropdownName | DropdownName[]) => void;
  openDropdown: (dropdownName: DropdownName) => void;
  toggleDropdown: (dropdownName: DropdownName) => void;
  closeAllDropdowns: () => void;
};

type DropdownProviderProps = {
  children: React.ReactNode;
};

export const SIDEBAR_MENU_DROPDOWNS = [
  DropdownName.BACKDROP,
  DropdownName.WELL_LOG,
  DropdownName.WELL_TOPS,
  DropdownName.WELL_DATA_SYMBOLS,
];

export const MAP_DROPDOWNS = [
  DropdownName.NAVIGATION,
  DropdownName.WELLBORE_FILTER,
  DropdownName.MAP_LAYERS,
];

export const STAGE_DROPDOWNS = [
  DropdownName.NAVIGATION,
  DropdownName.VIEWER_TOOLS,
];

export const MINI_MAP_DROPDOWNS = [DropdownName.MULTIPLE_WELLBORES];

const config: Record<DropdownName, { groupedWith: DropdownName[] }> = {
  [DropdownName.ADJUST1]: {
    groupedWith: [DropdownName.SELECT1],
  },
  [DropdownName.SELECT1]: {
    groupedWith: [DropdownName.ADJUST1],
  },
  [DropdownName.ADJUST2]: {
    groupedWith: [DropdownName.SELECT2],
  },
  [DropdownName.SELECT2]: {
    groupedWith: [DropdownName.ADJUST2],
  },
  [DropdownName.BACKDROP]: {
    groupedWith: SIDEBAR_MENU_DROPDOWNS.filter(
      out<DropdownName>(DropdownName.BACKDROP)
    ),
  },
  [DropdownName.WELL_LOG]: {
    groupedWith: SIDEBAR_MENU_DROPDOWNS.filter(
      out<DropdownName>(DropdownName.WELL_LOG)
    ),
  },
  [DropdownName.WELL_TOPS]: {
    groupedWith: SIDEBAR_MENU_DROPDOWNS.filter(
      out<DropdownName>(DropdownName.WELL_TOPS)
    ),
  },
  [DropdownName.WELL_DATA_SYMBOLS]: {
    groupedWith: SIDEBAR_MENU_DROPDOWNS.filter(
      out<DropdownName>(DropdownName.WELL_DATA_SYMBOLS)
    ),
  },
  [DropdownName.NAVIGATION]: {
    groupedWith: [...MAP_DROPDOWNS, ...STAGE_DROPDOWNS].filter(
      out<DropdownName>(DropdownName.NAVIGATION)
    ),
  },
  [DropdownName.WELLBORE_FILTER]: {
    groupedWith: MAP_DROPDOWNS.filter(
      out<DropdownName>(DropdownName.WELLBORE_FILTER)
    ),
  },
  [DropdownName.MAP_LAYERS]: {
    groupedWith: MAP_DROPDOWNS.filter(
      out<DropdownName>(DropdownName.MAP_LAYERS)
    ),
  },
  [DropdownName.MULTIPLE_WELLBORES]: {
    groupedWith: [],
  },
  [DropdownName.VIEWER_TOOLS]: {
    groupedWith: STAGE_DROPDOWNS.filter(
      out<DropdownName>(DropdownName.VIEWER_TOOLS)
    ),
  },
  [DropdownName.SIFT_COLUMN_ALIGNMENT]: {
    groupedWith: [],
  },
};

function getInitialDropdownState() {
  return Object.values(DropdownName).reduce((acc, name) => {
    acc[name] = false;
    return acc;
  }, {} as Record<DropdownName, boolean>);
}

const DropdownContext = React.createContext<DropdownContextType>({
  isOpen: getInitialDropdownState(),
  dropdownsAreOpen: false,
  closeDropdown: () => null,
  openDropdown: () => null,
  toggleDropdown: () => null,
  closeAllDropdowns: () => null,
});

export const DropdownProvider: FC<DropdownProviderProps> = ({ children }) => {
  const [isOpen, setIsOpen] = useState<Record<DropdownName, boolean>>(
    getInitialDropdownState()
  );

  const dropdownsAreOpen = !!Object.values(isOpen).find((s) => s === true);

  const closeDropdown = useCallback(
    (dropdownName: DropdownName | DropdownName[]) => {
      setIsOpen((state) => {
        if (
          !atLeastOneIsOpen(
            Array.isArray(dropdownName) ? dropdownName : [dropdownName],
            state
          )
        ) {
          return state;
        }

        if (Array.isArray(dropdownName)) {
          const newDropdownsState = dropdownName.reduce((acc, name) => {
            acc[name] = false;
            return acc;
          }, {} as Partial<Record<DropdownName, boolean>>);
          return {
            ...state,
            ...newDropdownsState,
          };
        } else {
          return {
            ...state,
            [dropdownName]: false,
          };
        }
      });
    },
    []
  );

  const openDropdown = useCallback((dropdownName: DropdownName) => {
    setIsOpen((state) => {
      const stateOfGroupedDropdowns = config[dropdownName].groupedWith.reduce(
        (acc, name) => {
          acc[name] = false;
          return acc;
        },
        {} as Partial<Record<DropdownName, boolean>>
      );
      return {
        ...state,
        ...stateOfGroupedDropdowns,
        [dropdownName]: true,
      };
    });
  }, []);

  const toggleDropdown = useCallback((dropdownName: DropdownName) => {
    setIsOpen((state) => {
      const newDropdownState = !state[dropdownName];
      const stateOfGroupedDropdowns: Partial<Record<DropdownName, boolean>> =
        {};
      if (newDropdownState === true) {
        config[dropdownName].groupedWith.forEach((name) => {
          stateOfGroupedDropdowns[name] = false;
        });
      }
      return {
        ...state,
        ...stateOfGroupedDropdowns,
        [dropdownName]: newDropdownState,
      };
    });
  }, []);

  const closeAllDropdowns = useCallback(() => {
    setIsOpen((state) => {
      const atLeastOneIsOpen = !!Object.values(state).find((s) => s === true);
      if (atLeastOneIsOpen) {
        return getInitialDropdownState();
      }
      return state;
    });
  }, []);

  return (
    <DropdownContext.Provider
      value={{
        isOpen,
        dropdownsAreOpen,
        closeDropdown,
        openDropdown,
        toggleDropdown,
        closeAllDropdowns,
      }}
    >
      {children}
    </DropdownContext.Provider>
  );
};

export const useDropdowns = (): DropdownContextType =>
  React.useContext(DropdownContext);

export function atLeastOneIsOpen(
  dropdowns: DropdownName[],
  isOpen: Record<DropdownName, boolean>
): boolean {
  for (let i = 0; i < dropdowns.length; i++) {
    if (isOpen[dropdowns[i]]) {
      return true;
    }
  }
  return false;
}
