import ls from "localstorage-slim";
import { useEffect, useState } from "react";
import { UseState } from "../../types";

export function useLocalStorage<T>(key: string, initialValue: T): UseState<T> {
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      return ls.get<T>(key) ?? initialValue;
    } catch (error) {
      console.log(error);
      return initialValue;
    }
  });

  useEffect(() => {
    try {
      ls.set(key, storedValue);
    } catch (error) {
      console.log(error);
    }
  }, [key, storedValue]);

  return [storedValue, setStoredValue];
}
