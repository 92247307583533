import { useEffect } from "react";
import { useSelector } from "react-redux";
import { fetchWellTopsData, selectWellTopsData } from "../../store";
import { useAppDispatch } from "../../store/store";
import { Optional, ProcessedTops } from "../../types";

export function useWellTopsData(): Optional<ProcessedTops> {
  const dispatch = useAppDispatch();
  const wellTopsData = useSelector(selectWellTopsData);

  useEffect(() => {
    dispatch(fetchWellTopsData());
  }, [dispatch]);

  return wellTopsData;
}
