import { ElementsConfig } from "../../types";

export const xrdConfig: ElementsConfig = {
  Minerals: {
    unit: "wt.%",
    elements: [
      { originalName: "XRD_wp_TClay", outputName: "Total Clay" },
      { originalName: "XRD_wp_TSili", outputName: "Total Silicates" },
      { originalName: "XRD_wp_TCb", outputName: "Total Carbonates" },
      { originalName: "XRD_wp_TOther", outputName: "Total Other Minerals" },
      { originalName: "XRD_wp_Qz", outputName: "Quartz" },
      { originalName: "XRD_wp_Kfs", outputName: "K-Feldspar" },
      { originalName: "XRD_wp_Pl", outputName: "Plagioclase" },
      { originalName: "XRD_wp_Cal", outputName: "Calcite" },
      { originalName: "XRD_wp_Dol", outputName: "Dolomite" },
      { originalName: "XRD_wp_Kut", outputName: "Kutnohorite" },
      { originalName: "XRD_wp_Mgs", outputName: "Magnesite" },
      { originalName: "XRD_wp_Sd", outputName: "Siderite" },
      { originalName: "XRD_wp_Rds", outputName: "Rhodochrosite" },
      { originalName: "XRD_wp_Arg", outputName: "Aragonite" },
      { originalName: "XRD_wp_Py", outputName: "Pyrite" },
      { originalName: "XRD_wp_Mrc", outputName: "Marcasite" },
      { originalName: "XRD_wp_OthSui", outputName: "Other Sulphides" },
      { originalName: "XRD_wp_Hem", outputName: "Hematite" },
      { originalName: "XRD_wp_Gth", outputName: "Goethite" },
      { originalName: "XRD_wp_Mag", outputName: "Magnetite" },
      { originalName: "XRD_wp_Ant", outputName: "Anatase" },
      { originalName: "XRD_wp_Rt", outputName: "Rutile" },
      { originalName: "XRD_wp_Ttn", outputName: "Titanite" },
      { originalName: "XRD_wp_OthOx", outputName: "Other Oxides" },
      { originalName: "XRD_wp_Ol", outputName: "Olivine" },
      { originalName: "XRD_wp_Pyx", outputName: "Pyroxenes" },
      { originalName: "XRD_wp_Amp", outputName: "Amphiboles" },
      { originalName: "XRD_wp_Grt", outputName: "Garnet" },
      { originalName: "XRD_wp_Gp", outputName: "Gypsum" },
      { originalName: "XRD_wp_Bas", outputName: "Bassanite" },
      { originalName: "XRD_wp_Anh", outputName: "Anhydrite" },
      { originalName: "XRD_wp_Brt", outputName: "Barite" },
      { originalName: "XRD_wp_Clt", outputName: "Celestine" },
      { originalName: "XRD_wp_Jrs", outputName: "Jarosite" },
      { originalName: "XRD_wp_Fl", outputName: "Fluorite" },
      { originalName: "XRD_wp_Hl", outputName: "Halite" },
      { originalName: "XRD_wp_OthHld", outputName: "Other Halides" },
      { originalName: "XRD_wp_Ap", outputName: "Apatite" },
      { originalName: "XRD_wp_Zeo", outputName: "Zeolite" },
      { originalName: "XRD_wp_OpalS", outputName: "Opaline silica" },
      { originalName: "XRD_wp_Por", outputName: "Portlandite" },
      { originalName: "XRD_wp_Bwm", outputName: "Brownmillerite" },
      { originalName: "XRD_wp_Hyc", outputName: "Hydrocalumite" },
      { originalName: "XRD_wp_Ett", outputName: "Ettringite" },
      { originalName: "XRD_wp_Amrph", outputName: "Amorphous" },
      { originalName: "XRD_wp_AddMin", outputName: "Additional Minerals" },
    ],
  },
};
