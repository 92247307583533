import { ISpinnerStyles, Spinner, SpinnerSize } from "@fluentui/react";
import React, { FC } from "react";

export const spinnerStyles: ISpinnerStyles = {
  root: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
};
interface LoadingSpinnerProps {
  loading: boolean;
  label?: string;
}

// @ts-ignore
export const LoadingSpinner: FC<LoadingSpinnerProps> = ({
  loading,
  label,
  children,
}) => {
  if (loading) {
    return (
      <Spinner
        role="alert"
        aria-busy={true}
        ariaLabel="spinner"
        styles={spinnerStyles}
        size={SpinnerSize.large}
        label={label}
      />
    );
  }
  return children ?? null;
};
