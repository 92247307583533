import { AnalyticsName } from "../../constants";
import { ElementsConfig, SimpleAnalyticsElement } from "../../types";
import { toRecord } from "../../utils";
import { getOriginalNames, getSelections, getUnits } from "./common";
import { qemscanConfig } from "./qemscanConfig";
import { speccamConfig } from "./speccamConfig";
import { tocConfig } from "./tocConfig";
import { xrdConfig } from "./xrdConfig";
import { xrfConfig } from "./xrfConfig";

export * from "./blocks";
export * from "./common";
export * from "./elementColorsConfig";
export * from "./qemscanConfig";
export * from "./quads";
export * from "./speccamConfig";
export * from "./tocConfig";
export * from "./xrdConfig";
export * from "./xrfConfig";

export const analyticsConfigs: Record<AnalyticsName, ElementsConfig> = {
  [AnalyticsName.XRF]: xrfConfig,
  [AnalyticsName.XRD]: xrdConfig,
  [AnalyticsName.TOC]: tocConfig,
  [AnalyticsName.QEM_SCAN]: qemscanConfig,
  [AnalyticsName.SPEC_CAM]: speccamConfig,
};

export const UNITS: Record<AnalyticsName, Record<string, string>> = {
  [AnalyticsName.XRF]: getUnits(xrfConfig),
  [AnalyticsName.XRD]: getUnits(xrdConfig),
  [AnalyticsName.TOC]: getUnits(tocConfig),
  [AnalyticsName.QEM_SCAN]: getUnits(qemscanConfig),
  [AnalyticsName.SPEC_CAM]: getUnits(speccamConfig),
};

export const ANALYTICS_ORIGINAL_NAMES: Record<
  AnalyticsName,
  Record<string, string>
> = {
  [AnalyticsName.XRF]: getOriginalNames(xrfConfig),
  [AnalyticsName.XRD]: getOriginalNames(xrdConfig),
  [AnalyticsName.TOC]: getOriginalNames(tocConfig),
  [AnalyticsName.QEM_SCAN]: getOriginalNames(qemscanConfig),
  [AnalyticsName.SPEC_CAM]: getOriginalNames(speccamConfig),
};

export const getElementOriginalName = (
  type: AnalyticsName,
  name: string
): string => ANALYTICS_ORIGINAL_NAMES[type][name];

export const ANALYTICS_ELEMENTS_LIST: SimpleAnalyticsElement[] = [
  ...getSelections(xrfConfig, AnalyticsName.XRF),
  ...getSelections(xrdConfig, AnalyticsName.XRD),
  ...getSelections(tocConfig, AnalyticsName.TOC),
  ...getSelections(qemscanConfig, AnalyticsName.QEM_SCAN),
  ...getSelections(speccamConfig, AnalyticsName.SPEC_CAM),
];

export const ANALYTICS_ELEMENT_BY_ORIGINAL_NAME: Record<
  string,
  SimpleAnalyticsElement
> = toRecord(ANALYTICS_ELEMENTS_LIST, "originalName");
