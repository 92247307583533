import { PartialTheme } from "@fluentui/react";

export const WellifyTheme: PartialTheme = {
  palette: {
    themePrimary: "#61ACBB",
    themeLighterAlt: "#D4E9ED",
    themeLighter: "#B7D9E1",
    themeLight: "#8DC3CE",
    themeTertiary: "#3D808F",
    themeSecondary: "#3D808F",
    themeDarkAlt: "#377481",
    themeDark: "#2B5A64",
    themeDarker: "#254E56",
  },
};
