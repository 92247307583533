import { useId } from "@fluentui/react-hooks";
import { NeutralColors } from "@fluentui/theme";
import * as d3 from "d3";
import { clamp } from "lodash";
import React, { FC, useEffect, useMemo } from "react";
import { TooltipType } from "../../../constants";
import { usePinnedTooltips } from "../../../context";
import {
  Optional,
  PinnableTooltipData,
  Size,
  VerticalMargin,
  WellTopsPlotData,
} from "../../../types";
import { clearPlot, DoubleClickDetector, isTWellbore } from "../../../utils";
import {
  addTooltipElement,
  createTooltipAdder,
  TooltipData,
} from "../plot-tooltip/plot-tooltip";

const STROKE_WIDTH_ADJUSTMENT = 1;
const DEFAULT_COLOR = "#fff";

const noDataStyles: React.CSSProperties = {
  alignmentBaseline: "middle",
  textAnchor: "middle",
  writingMode: "vertical-lr",
  fill: NeutralColors.gray130,
  fontSize: 14,
};

interface Scales {
  x: d3.ScaleBand<string>;
  y: d3.ScaleLinear<number, number, never>;
}

interface WellboreTopsChartProps {
  data: Optional<WellTopsPlotData[]>;
  size: Size;
  wellboreName: string;
  outerTrackMargin?: VerticalMargin;
  onClick?: (data: WellTopsPlotData) => void;
  onDoubleClick?: (data: WellTopsPlotData) => void;
  minDepth: number;
  maxDepth: number;
  useLinearChart: boolean;
}

export const StratigraphyChart: FC<WellboreTopsChartProps> = ({
  size,
  data,
  wellboreName,
  outerTrackMargin = { top: 0, bottom: 0 },
  onClick,
  onDoubleClick,
  minDepth,
  maxDepth,
  useLinearChart,
}) => {
  const graphContainerId = useId("stratigraphyTrack");
  const dataContainerID = `${graphContainerId}__data-container`;
  const toolTipId = `${graphContainerId}__tool-tip`;
  const { setActiveTooltip } = usePinnedTooltips();

  const scales = useMemo(
    () => ({
      x: d3
        .scaleBand()
        .range([STROKE_WIDTH_ADJUSTMENT, size.width - STROKE_WIDTH_ADJUSTMENT])
        .domain(["formation", "group"])
        .padding(0),
      y: d3
        .scaleLinear()
        .range([outerTrackMargin.top, size.height - outerTrackMargin.bottom]),
    }),
    [size.width, size.height, outerTrackMargin.top, outerTrackMargin.bottom]
  );

  /**
   * Initialize the plot
   */
  useEffect(() => {
    const svg = d3.select(`#${graphContainerId}`);
    svg.append("g").attr("id", dataContainerID);

    const tooltip = addTooltipElement(toolTipId);

    return () => {
      clearPlot(dataContainerID);
      tooltip.remove();
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (data) {
      updateScales(
        scales,
        data,
        size,
        outerTrackMargin,
        minDepth,
        maxDepth,
        useLinearChart
      );
      drawWellboreTops(
        dataContainerID,
        toolTipId,
        scales,
        data,
        setActiveTooltip,
        minDepth,
        maxDepth,
        useLinearChart,
        onClick,
        onDoubleClick
      );
    } else {
      clearPlot(dataContainerID);
    }
  }, [
    data,
    dataContainerID,
    maxDepth,
    minDepth,
    onClick,
    onDoubleClick,
    outerTrackMargin,
    scales,
    setActiveTooltip,
    size,
    toolTipId,
    useLinearChart,
  ]);

  const isTWell = isTWellbore(wellboreName);

  return (
    <svg width={size.width} height={size.height} id={graphContainerId}>
      {(!data || data.length === 0) && isTWell && (
        <text x={size.width / 2} y={size.height / 2} style={noDataStyles}>
          {size.height > 200 ? "T-well : no NPD information" : "No Data"}
        </text>
      )}
    </svg>
  );
};

function updateScales(
  scales: Scales,
  data: WellTopsPlotData[],
  size: Size,
  trackMargin: VerticalMargin,
  minDepth: number,
  maxDepth: number,
  useLinearChart: boolean
) {
  scales.x.range([
    STROKE_WIDTH_ADJUSTMENT,
    size.width - STROKE_WIDTH_ADJUSTMENT,
  ]);

  const numberOfCuttings = data
    .filter((plotData) => plotData.type === "group")
    .reduce(
      (sumOfCuttings, plotData) =>
        sumOfCuttings + plotData.numberOfCuttingsInFormation,
      0
    );

  const yScale = getStratigraphyScale(
    size.height,
    minDepth,
    maxDepth,
    numberOfCuttings,
    useLinearChart,
    trackMargin
  );

  scales.y.range(yScale.range()).domain(yScale.domain());
}

export function getStratigraphyScale(
  height: number,
  minDepth: number,
  maxDepth: number,
  numberOfCuttings: number,
  realDepthModeActive: boolean,
  trackMargin: VerticalMargin = { top: 0, bottom: 0 }
) {
  const stratigraphyScale = d3
    .scaleLinear()
    .range([trackMargin.top, height - trackMargin.bottom]);

  if (realDepthModeActive) {
    stratigraphyScale.domain([minDepth, maxDepth]);
  } else {
    stratigraphyScale.domain([0, numberOfCuttings]);
  }
  return stratigraphyScale;
}

const doubleClickDetector = new DoubleClickDetector();

function drawWellboreTops(
  graphID: string,
  tooltipId: string,
  scales: Scales,
  data: WellTopsPlotData[],
  setActiveTooltip: (tooltipData: PinnableTooltipData | undefined) => void,
  minDepth: number,
  maxDepth: number,
  useLinearChart: boolean,
  onClick?: (data: WellTopsPlotData) => void,
  onDoubleClick?: (data: WellTopsPlotData) => void
) {
  const dataContainer = d3.select(`#${graphID}`);

  const [addTooltip, removeTooltip] = createTooltipAdder(
    tooltipId,
    getTooltipData,
    setActiveTooltip,
    TooltipType.WELLBORE_TOPS
  );

  // eslint-disable-next-line
  const attributeSetter = (selection: any) => {
    selection
      .attr("x", (d: WellTopsPlotData) => scales.x(d.type) as number)
      .attr("y", (d: WellTopsPlotData) =>
        useLinearChart
          ? scales.y(clamp(d.top, minDepth, maxDepth))
          : scales.y(d.numberOfCuttingsAbove)
      )
      .attr("width", () => scales.x.bandwidth())
      .attr("height", (d: WellTopsPlotData) =>
        useLinearChart
          ? scales.y(clamp(d.bottom, minDepth, maxDepth)) -
            scales.y(clamp(d.top, minDepth, maxDepth))
          : scales.y(d.numberOfCuttingsInFormation + d.numberOfCuttingsAbove) -
            scales.y(d.numberOfCuttingsAbove)
      )
      .attr("fill", (d: WellTopsPlotData) => d.color ?? DEFAULT_COLOR);
  };

  doubleClickDetector
    // eslint-disable-next-line
    .onClick((e: any) => {
      const data: WellTopsPlotData = e.srcElement.__data__;
      if (onClick) {
        onClick(data);
      }
    })
    // eslint-disable-next-line
    .onDoubleClick((e: any) => {
      const data: WellTopsPlotData = e.srcElement.__data__;
      if (onDoubleClick) {
        onDoubleClick(data);
      }
    });

  dataContainer
    .selectAll(".formation-rect")
    .data(data, (d) => {
      const data = d as WellTopsPlotData;
      return `${data.type}-${data.name}`;
    })
    .join(
      (enter) =>
        enter
          .append("rect")
          .attr("class", "formation-rect")
          .on("mouseover", addTooltip)
          .on("click", (e) => {
            doubleClickDetector.detect(e);
          })
          .call(attributeSetter),
      (update) =>
        update
          .on("click", null)
          .on("click", (e) => {
            doubleClickDetector.detect(e);
          })
          .call(attributeSetter),
      (exit) => exit.remove()
    );

  dataContainer.on("mouseout", removeTooltip);
}

const getTooltipData = (plotData: WellTopsPlotData): TooltipData => ({
  groups: [
    {
      heading: plotData.name,
      rows: [
        { label: "Top", value: `${plotData.top} m` },
        { label: "Bottom", value: `${plotData.bottom} m` },
        { label: "Thickness", value: `${plotData.thickness} m` },
      ],
    },
  ],
});
