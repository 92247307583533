import { CategoryData, ElementsConfig, ElementsGroup } from "../../types";

const otherPhasesGroup: ElementsGroup = {
  originalName: "OtherPhases",
  outputName: "Other Phases",
  unit: "%",
  information: {
    description:
      "All other phases not grouped separately; includes boundary textures (for more detail, see the Detailed mineral list group descriptions)",
  },
  elements: [
    {
      originalName: "DraviteSchorl",
      outputName: "Dravite/Schorl",
      decimalPlaces: 3,
      information: {
        description:
          "Tourmaline of intermediate dravite-schorl composition; may include other (Na)Mg-Fe-Al silicates and clay intergrowths",
        wikipediaLinks: ["https://en.wikipedia.org/wiki/Tourmaline"],
      },
    },
    {
      originalName: "Schorl",
      outputName: "Schorl",
      decimalPlaces: 3,
      information: {
        description:
          "Schorl tourmaline; may include other (Na)Fe-Al silicates and clay intergrowths",
        wikipediaLinks: ["https://en.wikipedia.org/wiki/Tourmaline"],
      },
    },
    {
      originalName: "Elbaite",
      outputName: "Elbaite",
      decimalPlaces: 3,
      information: {
        description:
          "Elbaite tourmaline; may include other (Na-Fe)Al silicates and clay intergrowths",
        wikipediaLinks: ["https://en.wikipedia.org/wiki/Tourmaline"],
      },
    },
    {
      originalName: "Almandine",
      outputName: "Almandine",
      decimalPlaces: 3,
      information: {
        description:
          "Almandine garnet and other Fe-rich garnets; may include other (Ca-Mg-Mn)Fe-Al silicates and boundary textures",
        wikipediaLinks: ["https://en.wikipedia.org/wiki/Almandine"],
      },
    },
    {
      originalName: "Spessartine",
      outputName: "Spessartine",
      decimalPlaces: 3,
      information: {
        description:
          "Spessartine garnet and other Mn-rich garnets; may include other (Ca-Fe-Mg)Mn-Al silicates and boundary textures",
        wikipediaLinks: ["https://en.wikipedia.org/wiki/Spessartine"],
      },
    },
    {
      originalName: "Pyrope",
      outputName: "Pyrope",
      decimalPlaces: 3,
      information: {
        description:
          "Pyrope garnet and other Mg-rich garnets; may include other (Ca-Fe-Mn)Mg-Al silicates and boundary textures",
        wikipediaLinks: ["https://en.wikipedia.org/wiki/Pyrope"],
      },
    },
    {
      originalName: "Grossular",
      outputName: "Grossular",
      decimalPlaces: 3,
      information: {
        description:
          "Grossular garnet and other Ca-rich garnets; may include epidote and other (Fe-Mg-Mn)Ca-Al silicates and boundary textures",
        wikipediaLinks: ["https://en.wikipedia.org/wiki/Grossular"],
      },
    },
    {
      originalName: "Andradite",
      outputName: "Andradite",
      decimalPlaces: 3,
      information: {
        description:
          "Andradite garnet and other Ca-Fe-rich garnets; may include other (Cr-Al)Ca-Fe silicates and boundary textures",
        wikipediaLinks: ["https://en.wikipedia.org/wiki/Andradite"],
      },
    },
    {
      originalName: "Uvarovite",
      outputName: "Uvarovite",
      decimalPlaces: 3,
      information: {
        description:
          "Uvarovite garnet and other Ca-Cr-rich garnets; may include other (Fe)Ca-Cr silicates and boundary textures",
        wikipediaLinks: ["https://en.wikipedia.org/wiki/Uvarovite"],
      },
    },
    {
      originalName: "Epidote_main",
      outputName: "Epidote main",
      decimalPlaces: 3,
      information: {
        description:
          "Epidote; may include grossular garnet and minor other Ca-Fe-Al silicates and boundary textures; may include marl (Ca-carbonate and silicate textures)",
        wikipediaLinks: ["https://en.wikipedia.org/wiki/Epidote"],
      },
    },
    {
      originalName: "Zoisite",
      outputName: "Zoisite",
      decimalPlaces: 3,
      information: {
        description:
          "Zoisite epidote; may include minor other Ca-Al silicates and boundary textures; may include marl (Ca-carbonate and silicate textures)",
        wikipediaLinks: ["https://en.wikipedia.org/wiki/Zoisite"],
      },
    },
    {
      originalName: "Piemontite",
      outputName: "Piemontite",
      decimalPlaces: 3,
      information: {
        description:
          "Piemontite epidote; may include minor other Ca-Fe-Mn-Al silicates and boundary textures",
        wikipediaLinks: ["https://en.wikipedia.org/wiki/Piemontite"],
      },
    },
    {
      originalName: "Allanite",
      outputName: "Allanite",
      decimalPlaces: 3,
      information: {
        description:
          "Allanite epidote; may include minor other REE-Ca-Fe-Al silicates and boundary textures",
        wikipediaLinks: ["https://en.wikipedia.org/wiki/Allanite"],
      },
    },
    {
      originalName: "Titanite",
      outputName: "Titanite",
      decimalPlaces: 3,
      information: {
        description:
          "Titanite; may include minor other Ti-bearing silicates and boundary textures with other phases",
        wikipediaLinks: ["https://en.wikipedia.org/wiki/Titanite"],
      },
    },
    {
      originalName: "Kyanite_Sillimanite_Andalusite",
      outputName: "Kyanite/Sillimanite/Andalusite",
      decimalPlaces: 3,
      information: {
        description:
          "Kyanite and/or sillimanite and/or andalusite; may include minor other Al silicates",
        wikipediaLinks: [
          "https://en.wikipedia.org/wiki/Kyanite",
          "https://en.wikipedia.org/wiki/Sillimanite",
          "https://en.wikipedia.org/wiki/Andalusite",
        ],
      },
    },
    {
      originalName: "Staurolite",
      outputName: "Staurolite",
      decimalPlaces: 3,
      information: {
        description:
          "Staurolite; may include minor other Fe(Mg)Al silicates and boundary textures",
        wikipediaLinks: ["https://en.wikipedia.org/wiki/Staurolite"],
      },
    },
    {
      originalName: "Topaz",
      outputName: "Topaz",
      decimalPlaces: 3,
      information: {
        wikipediaLinks: ["https://en.wikipedia.org/wiki/Topaz"],
      },
    },
    {
      originalName: "Augite",
      outputName: "Augite",
      decimalPlaces: 3,
      information: {
        description:
          "Augite clinopyroxene; may include other Ca-Fe-Mg(Al) silicates (including Ca-amphiboles) and boundary textures; may include marl (Ca-carbonate and silicate textures)",
        wikipediaLinks: ["https://en.wikipedia.org/wiki/Augite"],
      },
    },
    {
      originalName: "Diopside",
      outputName: "Diopside",
      decimalPlaces: 3,
      information: {
        description:
          "Diopside clinopyroxene; may include other Ca-Mg silicates and boundary textures; may include marl (Ca-carbonate and silicate textures)",
        wikipediaLinks: ["https://en.wikipedia.org/wiki/Diopside"],
      },
    },
    {
      originalName: "DiopsideHedenbergite",
      outputName: "Diopside/Hedenbergite",
      decimalPlaces: 3,
      information: {
        description:
          "Clinopyroxene of intremediate diopside-hedenbergite composition; may include other Ca-Mg-Fe silicates and boundary textures; may include marl (Ca-carbonate and silicate textures)",
        wikipediaLinks: [
          "https://en.wikipedia.org/wiki/Diopside",
          "https://en.wikipedia.org/wiki/Hedenbergite",
        ],
      },
    },
    {
      originalName: "Hedenbergite",
      outputName: "Hedenbergite",
      decimalPlaces: 3,
      information: {
        description:
          "Hedenbergite clinopyroxene; may include other Ca-Fe silicates and boundary textures; may include marl (Ca-carbonate and silicate textures)",
        wikipediaLinks: ["https://en.wikipedia.org/wiki/Hedenbergite"],
      },
    },
    {
      originalName: "Aegirine",
      outputName: "Aegirine",
      decimalPlaces: 3,
      information: {
        description:
          "Aegirine clinopyroxene; may include other Na-Fe silicates and boundary textures",
        wikipediaLinks: ["https://en.wikipedia.org/wiki/Aegirine"],
      },
    },
    {
      originalName: "Hornblende",
      outputName: "Hornblende",
      decimalPlaces: 3,
      information: {
        description:
          "Hornblende amphibole; may include other Ca-Fe-Mg-Al silicates (including clinopyroxenes) and boundary textures; may include marl (Ca-carbonate and silicate textures)",
        wikipediaLinks: ["https://en.wikipedia.org/wiki/Hornblende"],
      },
    },
    {
      originalName: "Tremolite",
      outputName: "Tremolite",
      decimalPlaces: 3,
      information: {
        description:
          "Tremolite amphibole; may include other Ca-Mg silicates and boundary textures; may include marl (Ca-carbonate and silicate textures)",
        wikipediaLinks: ["https://en.wikipedia.org/wiki/Tremol"],
      },
    },
    {
      originalName: "Actinolite",
      outputName: "Actinolite",
      decimalPlaces: 3,
      information: {
        description:
          "Actinolite amphibole; may include other Ca-Mg-Fe silicates and boundary textures; may include marl (Ca-carbonate and silicate textures)",
        wikipediaLinks: ["https://en.wikipedia.org/wiki/Actinolite"],
      },
    },
    {
      originalName: "Anthophyllite",
      outputName: "Anthophyllite",
      decimalPlaces: 3,
      information: {
        description:
          "Anthophyllite amphibole; may include other Mg-Fe silicates and boundary textures",
        wikipediaLinks: ["https://en.wikipedia.org/wiki/Anthophyllite"],
      },
    },
    {
      originalName: "Glaucophane",
      outputName: "Glaucophane",
      decimalPlaces: 3,
      information: {
        description:
          "Glaucophane amphibole; may include other Na-Mg-Fe-Al silicates and boundary textures",
        wikipediaLinks: ["https://en.wikipedia.org/wiki/Glaucophane"],
      },
    },
    {
      originalName: "Riebeckite",
      outputName: "Riebeckite",
      decimalPlaces: 3,
      information: {
        description:
          "Riebeckite amphibole; may include other Na-Mg-Fe silicates and boundary textures",
        wikipediaLinks: ["https://en.wikipedia.org/wiki/Riebeckite"],
      },
    },
    {
      originalName: "Pargasite",
      outputName: "Pargasite",
      decimalPlaces: 3,
      information: {
        description:
          "Pargasite amphibole; may include other Na-Ca-Mg-Fe-Al silicates and boundary textures; may include marl (Ca-carbonate and silicate textures)",
        wikipediaLinks: ["https://en.wikipedia.org/wiki/Pargasite"],
      },
    },
    {
      originalName: "Enstatite",
      outputName: "Enstatite",
      decimalPlaces: 3,
      information: {
        description:
          "Enstatite orthopyroxene; may include minor other Mg(Fe) silicates and boundary textures",
        wikipediaLinks: ["https://en.wikipedia.org/wiki/Enstatite"],
      },
    },
    {
      originalName: "Orthopyroxene",
      outputName: "Orthopyroxene",
      decimalPlaces: 3,
      information: {
        description:
          "Orthopyroxene of intermediate composition; may include minor other Mg-Fe silicates and boundary textures",
        wikipediaLinks: ["https://en.wikipedia.org/wiki/Pyroxene"],
      },
    },
    {
      originalName: "Ferrosillite",
      outputName: "Ferrosillite",
      decimalPlaces: 3,
      information: {
        description:
          "Ferrosillite orthopyroxene; may include minor other Fe(Mg) silicates and boundary textures",
        wikipediaLinks: ["https://en.wikipedia.org/wiki/Pyroxene"],
      },
    },
    {
      originalName: "Talc",
      outputName: "Talc",
      decimalPlaces: 3,
      information: {
        description:
          "Talc; may include minor other Mg silicates and boundary textures",
        wikipediaLinks: ["https://en.wikipedia.org/wiki/Talc"],
      },
    },
    {
      originalName: "Antigorite",
      outputName: "Antigorite",
      decimalPlaces: 3,
      information: {
        description:
          "Antigorite serpentine; may include minor other Mg-Fe(Al) silicates and boundary textures",
        wikipediaLinks: ["https://en.wikipedia.org/wiki/Antigorite"],
      },
    },
    {
      originalName: "Lizardite_Chrysotile",
      outputName: "Lizardite/Chrysotile",
      decimalPlaces: 3,
      information: {
        description:
          "Lizardite and/or chrysotile serpentine; may include minor other Mg(Al) silicates and boundary textures",
        wikipediaLinks: ["https://en.wikipedia.org/wiki/Serpentinite"],
      },
    },
    {
      originalName: "Forsterite",
      outputName: "Forsterite",
      decimalPlaces: 3,
      information: {
        description:
          "Forsterite olivine; may include minor other Mg(Fe) silicates and boundary textures",
        wikipediaLinks: ["https://en.wikipedia.org/wiki/Forsterite"],
      },
    },
    {
      originalName: "Olivine",
      outputName: "Olivine",
      decimalPlaces: 3,
      information: {
        description:
          "Olivine of intermediate composition; may include minor other Mg-Fe silicates and boundary textures",
        wikipediaLinks: ["https://en.wikipedia.org/wiki/Olivine"],
      },
    },
    {
      originalName: "Fayalite",
      outputName: "Fayalite",
      decimalPlaces: 3,
      information: {
        description:
          "Fayalite olivine; may include minor other Fe(Mg) silicates and boundary textures",
        wikipediaLinks: ["https://en.wikipedia.org/wiki/Fayalite"],
      },
    },
    {
      originalName: "Cordierite",
      outputName: "Cordierite",
      decimalPlaces: 3,
      information: {
        description:
          "Cordierite; may include minor other Mg-Fe-Al silicates and boundary textures",
        wikipediaLinks: ["https://en.wikipedia.org/wiki/Cordierite"],
      },
    },
    {
      originalName: "Chloritoid",
      outputName: "Chloritoid",
      decimalPlaces: 3,
      information: {
        description:
          "Chloritoid; may include minor other Fe-Mg-Al silicates and boundary textures",
        wikipediaLinks: ["https://en.wikipedia.org/wiki/Chloritoid"],
      },
    },
    {
      originalName: "Thorite",
      outputName: "Thorite",
      decimalPlaces: 3,
      information: {
        wikipediaLinks: ["https://en.wikipedia.org/wiki/Thorite"],
      },
    },
    {
      originalName: "Zeolites",
      outputName: "Zeolites",
      decimalPlaces: 3,
      information: {
        description:
          "(K-Na)Ca-Al-bearing zeolites; may include minor other (K-Na)Ca-Al silicates; may include marl (Ca-carbonate and silicate textures)",
        wikipediaLinks: ["https://en.wikipedia.org/wiki/Zeolites"],
      },
    },
    {
      originalName: "Magnesiochromite",
      outputName: "Magnesiochromite",
      decimalPlaces: 3,
      information: {
        wikipediaLinks: ["https://en.wikipedia.org/wiki/Magnesiochromite"],
      },
    },
    {
      originalName: "Chromite",
      outputName: "Chromite",
      decimalPlaces: 3,
      information: {
        description:
          "Chromite; may include minor boundary textures with other phases",
        wikipediaLinks: ["https://en.wikipedia.org/wiki/Chromite"],
      },
    },
    {
      originalName: "ChromiteSpinel",
      outputName: "Chromite/Spinel",
      decimalPlaces: 3,
      information: {
        description:
          "Cr-bearing spinel of intermediate chromite-spinel composition",
        wikipediaLinks: ["https://en.wikipedia.org/wiki/ChromiteSpinel"],
      },
    },
    {
      originalName: "Spinel",
      outputName: "Spinel",
      decimalPlaces: 3,
      information: {
        description: "Spinel; may include minor Cr-bearing spinel",
        wikipediaLinks: ["https://en.wikipedia.org/wiki/Spinel"],
      },
    },
    {
      originalName: "Hercynite",
      outputName: "Hercynite",
      decimalPlaces: 3,
      information: {
        description:
          "Hercynite; may include minor boundary textures with other phases",
        wikipediaLinks: ["https://en.wikipedia.org/wiki/Hercynite"],
      },
    },
    {
      originalName: "Gahnite",
      outputName: "Gahnite",
      decimalPlaces: 3,
      information: {
        wikipediaLinks: ["https://en.wikipedia.org/wiki/Gahnite"],
      },
    },
    {
      originalName: "Cassiterite",
      outputName: "Cassiterite",
      decimalPlaces: 3,
      information: {
        wikipediaLinks: ["https://en.wikipedia.org/wiki/Cassiterite"],
      },
    },
    {
      originalName: "Corundum",
      outputName: "Corundum",
      decimalPlaces: 3,
      information: {
        description: "Corundum; may include other Al oxides",
        wikipediaLinks: ["https://en.wikipedia.org/wiki/Corundum"],
      },
    },
    {
      originalName: "Baddeleyite",
      outputName: "Baddeleyite",
      decimalPlaces: 3,
      information: {
        wikipediaLinks: ["https://en.wikipedia.org/wiki/Baddeleyite"],
      },
    },
    {
      originalName: "Perovskite",
      outputName: "Perovskite",
      decimalPlaces: 3,
      information: {
        wikipediaLinks: ["https://en.wikipedia.org/wiki/Perovskite"],
      },
    },
    {
      originalName: "HollanditeCoronadite",
      outputName: "Hollandite/Coronadite",
      decimalPlaces: 3,
      information: {
        description:
          "Hollandite and/or coronadite, including solid solution members",
        wikipediaLinks: [
          "https://en.wikipedia.org/wiki/Hollandite",
          "https://en.wikipedia.org/wiki/Coronadite",
        ],
      },
    },
    {
      originalName: "Monazite",
      outputName: "Monazite main",
      decimalPlaces: 3,
      information: {
        description:
          "Monazite; may include minor other REE phosphates and boundary textures with other phases",
        wikipediaLinks: ["https://en.wikipedia.org/wiki/Monazite"],
      },
    },
    {
      originalName: "Xenotime",
      outputName: "Xenotime",
      decimalPlaces: 3,
      information: {
        description: "Xenotime; may include minor other Y-Yb phosphates",
        wikipediaLinks: ["https://en.wikipedia.org/wiki/Xenotime"],
      },
    },
    {
      originalName: "Florencite",
      outputName: "Florencite",
      decimalPlaces: 3,
      information: {
        description:
          "Florencite; may include minor other REE-Al phosphates and boundary textures with other phases",
        wikipediaLinks: ["https://en.wikipedia.org/wiki/Florencite-(Sm)"],
      },
    },
    {
      originalName: "Chalcopyrite",
      outputName: "Chalcopyrite",
      decimalPlaces: 3,
      information: {
        description: "Chalcopyrite and/or other Cu-Fe sulphides",
        wikipediaLinks: ["https://en.wikipedia.org/wiki/Chalcopyrite"],
      },
    },
    {
      originalName: "CuSulphides",
      outputName: "CuSulphides",
      decimalPlaces: 3,
      information: {
        description:
          "Chalcocite, digenite, covellite and/or other Cu sulphides",
        wikipediaLinks: ["https://en.wikipedia.org/wiki/Copper(II)_oxide"],
      },
    },
    {
      originalName: "Sphalerite",
      outputName: "Sphalerite",
      decimalPlaces: 3,
      information: {
        description:
          "Sphalerite; may include minor boundary textures with other phases",
        wikipediaLinks: ["https://en.wikipedia.org/wiki/Sphalerite"],
      },
    },
    {
      originalName: "Galena",
      outputName: "Galena",
      decimalPlaces: 3,
      information: {
        wikipediaLinks: ["https://en.wikipedia.org/wiki/Galena"],
      },
    },
    {
      originalName: "Pentlandite",
      outputName: "Pentlandite",
      decimalPlaces: 3,
      information: {
        description: "Pentlandite and/or other Ni-Fe sulphides",
        wikipediaLinks: ["https://en.wikipedia.org/wiki/Pentlandite"],
      },
    },
    {
      originalName: "Gibbsite",
      outputName: "Gibbsite",
      decimalPlaces: 3,
      information: {
        description: "Gibbsite; may include other Al oxides/hydroxides",
        wikipediaLinks: ["https://en.wikipedia.org/wiki/Gibbsite"],
      },
    },
    {
      originalName: "Fluorite",
      outputName: "Fluorite",
      decimalPlaces: 3,
      information: {
        description:
          "Fluorite; may include minor boundary textures with other phases",
        wikipediaLinks: ["https://en.wikipedia.org/wiki/Fluorite"],
      },
    },
    {
      originalName: "Scheelite",
      outputName: "Scheelite",
      decimalPlaces: 3,
      information: {
        wikipediaLinks: ["https://en.wikipedia.org/wiki/Scheelite"],
      },
    },
    {
      originalName: "Jarosite",
      outputName: "Jarosite",
      decimalPlaces: 3,
      information: {
        wikipediaLinks: ["https://en.wikipedia.org/wiki/Jarosite"],
      },
    },
    {
      originalName: "Halotrichite",
      outputName: "Halotrichite",
      decimalPlaces: 3,
      information: {
        wikipediaLinks: ["https://en.wikipedia.org/wiki/Halotrichite"],
      },
    },
    {
      originalName: "Schwertmannite",
      outputName: "Schwertmannite",
      decimalPlaces: 3,
      information: {
        description: "Schwertmannite; may include minor boundary textures",
        wikipediaLinks: ["https://en.wikipedia.org/wiki/Schwertmannite"],
      },
    },
    {
      originalName: "Aluminium",
      outputName: "Aluminium",
      decimalPlaces: 3,
      information: {
        description: "Aluminium metal",
        wikipediaLinks: ["https://en.wikipedia.org/wiki/Aluminium"],
      },
    },
    {
      originalName: "Copper",
      outputName: "Copper",
      decimalPlaces: 3,
      information: {
        description: "Copper metal",
        wikipediaLinks: ["https://en.wikipedia.org/wiki/Copper"],
      },
    },
    {
      originalName: "Steel",
      outputName: "Steel",
      decimalPlaces: 3,
      information: {
        description: "Fe-Cr bearing steel",
        wikipediaLinks: ["https://en.wikipedia.org/wiki/Steel"],
      },
    },
    {
      originalName: "OtherPhasesBLS",
      outputName: "OtherPhasesBLS",
      information: {
        description:
          "All other phases not grouped separately; includes boundary textures",
      },
    },
  ],
};

const QemScanMineralsCategory: CategoryData = {
  unit: "%",
  elements: [
    {
      originalName: "Quartz",
      outputName: "Quartz",
      information: {
        description:
          "Includes minor boundary textures with other phases; may include minor other silica-rich phases",
        wikipediaLinks: ["https://en.wikipedia.org/wiki/Quartz"],
      },
    },
    {
      originalName: "Albite",
      outputName: "Albite",
      information: {
        description:
          "Albite plagioclase; may include minor boundary textures with other phases; may include minor other Na-Al silicates (e.g. jadeite, zeo lites)",
        wikipediaLinks: ["https://en.wikipedia.org/wiki/Albite"],
      },
    },
    {
      originalName: "Oligoclase",
      outputName: "Oligoclase",
      information: {
        description:
          "Oligoclase plagioclase; may include minor other Na-Ca-Al silicates (e.g. zeolites)",
        wikipediaLinks: ["https://en.wikipedia.org/wiki/Oligoclase"],
      },
    },
    {
      originalName: "Glauconite",
      outputName: "Glauconite",
      information: {
        description: "Glauconite; may include minor other K-Fe-Al silicates",
        wikipediaLinks: ["https://en.wikipedia.org/wiki/Glauconite"],
      },
    },
    {
      originalName: "Kaolinite",
      outputName: "Kaolinite",
      information: {
        description:
          "Kaolinite and/or any of its polymorphs (e.g. dickite, halloysite); may include Fe/Mg-bearing kaolinite; minor boundary textures with other phases; may include minor other Al silicates",
        wikipediaLinks: ["https://en.wikipedia.org/wiki/Kaolinite"],
      },
    },
    {
      originalName: "QuartzClayMix",
      outputName: "QuartzClayMix",
      information: {
        description:
          "Fine-grained intergrowths and boundary textures of quartz and clays; may include high-silica clays, chert and microquartz overgrowths; minor boundary textures between quartz and other Al-bearing silicates",
      },
    },
    {
      originalName: "Zircon",
      outputName: "Zircon",
      information: {
        description:
          "Zircon; may include minor boundary textures with other phases",
        wikipediaLinks: ["https://en.wikipedia.org/wiki/Zircon"],
      },
    },
    {
      originalName: "Rutile_Anatase",
      outputName: "Rutile Anatase",
      information: {
        description:
          "Rutile and/or any of its polymorphs (e.g. anatase, brookite); minor boundary textures with other phases; may include minor other Ti-bearing phases",
        wikipediaLinks: ["https://en.wikipedia.org/wiki/Rutile"],
      },
    },
    {
      originalName: "Apatite",
      outputName: "Apatite",
      information: {
        description:
          "Apatite; may include (Ca)phosphate cements and minor other phosphates; minor boundary textures with other phases",
        wikipediaLinks: ["https://en.wikipedia.org/wiki/Apatite"],
      },
    },
    {
      originalName: "Calcite",
      outputName: "Calcite",
      information: {
        description:
          "Calcite and/or aragonite; may include minor boundary textures with other phases",
        wikipediaLinks: ["https://en.wikipedia.org/wiki/Calcite"],
      },
    },
    {
      originalName: "Dolomite",
      outputName: "Dolomite",
      information: {
        description:
          "Dolomite; may include minor boundary textures with other phases",
        wikipediaLinks: ["https://en.wikipedia.org/wiki/Dolomite"],
      },
    },
    {
      originalName: "FeDolomite",
      outputName: "FeDolomite",
      information: {
        description: "Fe-bearing dolomite",
        wikipediaLinks: ["https://en.wikipedia.org/wiki/Dolomite"],
      },
    },
    {
      originalName: "Marl",
      outputName: "Marl",
      information: {
        description:
          "Fine-grained intergrowths and boundary textures between Ca-bearing carbonates and quartz/clays; may include minor Ca-bearing silicates (e.g. CPX, amphiboles, zeolites)",
        wikipediaLinks: ["https://en.wikipedia.org/wiki/Marl"],
      },
    },
    {
      originalName: "Pyrite",
      outputName: "Pyrite",
      information: {
        description:
          "Pyrite and/or marcasite; may include other Fe-sulphides and minor boundary textures with other phases",
        wikipediaLinks: ["https://en.wikipedia.org/wiki/Pyrite"],
      },
    },
    {
      originalName: "Anhydrite",
      outputName: "Anhydrite",
      information: {
        description:
          "Anhydrite and/or gypsum; may include minor boundary textures with other phases",
        wikipediaLinks: ["https://en.wikipedia.org/wiki/Anhydrite"],
      },
    },
  ],
  groups: [
    {
      originalName: "KFeldspar",
      outputName: "KFeldspar",
      unit: "%",
      information: {
        description:
          "K-feldspar (any of i ts polymorphs): may include hyalophane and minor other K-AI silicates; minor boundary textures with other phases",
        wikipediaLinks: ["https://en.wikipedia.org/wiki/Feldspar"],
      },
      elements: [
        {
          originalName: "Anorthoclase",
          outputName: "Anorthoclase",
          information: {
            description:
              "Anorthoclase; may include minor other Na-K-Al silicates",
            wikipediaLinks: ["https://en.wikipedia.org/wiki/Anorthoclase"],
          },
        },
        {
          originalName: "KFeldspar_main",
          outputName: "K-feldspar main",
          information: {
            description:
              "K-feldspar (any of its polymorphs); may include hyalophane and minor other K-Al silicates; minor boundary textures with other phases",
            wikipediaLinks: ["https://en.wikipedia.org/wiki/Feldspar"],
          },
        },
      ],
    },
    {
      originalName: "AndesineAnorthite",
      outputName: "Andesine/Anorthite",
      unit: "%",
      information: {
        description:
          "Andesine, labradorite, bytownite and/or anorthite plagioclase; may include minor other Ca(Na)Al silicates (e.g. zeolites) or marl (Ca-carbonate and silicate textures)",
        wikipediaLinks: [
          "https://en.wikipedia.org/wiki/Andesine",
          "https://en.wikipedia.org/wiki/Anorthite",
        ],
      },
      elements: [
        {
          originalName: "Andesine",
          outputName: "Andesine",
          information: {
            description:
              "Andesine plagioclase; may include minor other Ca-Na-Al silicates (e.g. zeolites) or marl (Ca-carbonate and silicate textures)",
            wikipediaLinks: ["https://en.wikipedia.org/wiki/Andesine"],
          },
        },
        {
          originalName: "Labradorite",
          outputName: "Labradorite",
          information: {
            description:
              "Labradorite plagioclase; may include minor other Ca-Na-Al silicates (e.g. zeolites) or marl (Ca-carbonate and silicate textures)",
            wikipediaLinks: ["https://en.wikipedia.org/wiki/Labradorite"],
          },
        },
        {
          originalName: "Bytownite",
          outputName: "Bytownite",
          information: {
            description:
              "Bytownite plagioclase; may include minor other Ca(Na)Al silicates (e.g. zeolites) or marl (Ca-carbonate and silicate textures)",
            wikipediaLinks: ["https://en.wikipedia.org/wiki/Bytownite"],
          },
        },
        {
          originalName: "Anorthite",
          outputName: "Anorthite",
          information: {
            description:
              "Anorthite plagioclase; may include minor other Ca-Al silicates (e.g. zeolites) or marl (Ca-carbonate and silicate textures)",
            wikipediaLinks: ["https://en.wikipedia.org/wiki/Anorthite"],
          },
        },
      ],
    },
    {
      originalName: "Biotite",
      outputName: "Biotite",
      unit: "%",
      information: {
        description:
          "Biotite (phlogopite-annite mica); may include minor other K-Mg-Fe-Al silicates and boundary textures with other phases",
        wikipediaLinks: ["https://en.wikipedia.org/wiki/Biotite"],
      },
      elements: [
        {
          originalName: "Phlogopite",
          outputName: "Phlogopite",
          decimalPlaces: 3,
          information: {
            description:
              "Phlogopite mica; may include minor other K-Mg-Al silicates and boundary textures with other phases",
            wikipediaLinks: ["https://en.wikipedia.org/wiki/Phlogopite"],
          },
        },
        {
          originalName: "Biotite_main",
          outputName: "Biotite main",
          information: {
            description:
              "Biotite mica; may include minor other K-Mg-Fe-Al silicates and boundary textures with other phases",
            wikipediaLinks: ["https://en.wikipedia.org/wiki/Biotite"],
          },
        },
        {
          originalName: "Annite",
          outputName: "Annite",
          decimalPlaces: 3,
          information: {
            description:
              "Annite mica; may include minor other K-Fe-Al silicates and boundary textures with other phases",
            wikipediaLinks: ["https://en.wikipedia.org/wiki/Annite"],
          },
        },
      ],
    },
    {
      originalName: "Muscovite",
      outputName: "Muscovite",
      unit: "%",
      information: {
        description:
          "Muscovite; may include Fe-bearing muscovite and paragonite; minor boundary textures with other phases; may include minor other K-Al silicates",
        wikipediaLinks: ["https://en.wikipedia.org/wiki/Muscovite"],
      },
      elements: [
        {
          originalName: "Muscovite_main",
          outputName: "Muscovite main",
          information: {
            description:
              "Muscovite; minor boundary textures with other phases; may include minor other K-Al silicates",
            wikipediaLinks: ["https://en.wikipedia.org/wiki/Muscovite"],
          },
        },
        {
          originalName: "FeMuscovite",
          outputName: "FeMuscovite",
          information: {
            description:
              "Fe-bearing muscovite; may include minor boundary textures with other phases; may include minor other K-Fe-Al silicates",
            wikipediaLinks: ["https://en.wikipedia.org/wiki/Muscovite"],
          },
        },
        {
          originalName: "Paragonite",
          outputName: "Paragonite",
          decimalPlaces: 3,
          information: {
            description: "Paragonite; may include minor other Na-Al silicates",
            wikipediaLinks: ["https://en.wikipedia.org/wiki/Paragonite"],
          },
        },
      ],
    },
    {
      originalName: "Illite",
      outputName: "Illite",
      unit: "%",

      information: {
        description:
          "Illite; may include mixed layer illite-muscovite; may include minor boundary textures with other phases; may include minor other K-Al silicates",
        wikipediaLinks: ["https://en.wikipedia.org/wiki/Illite"],
      },
      elements: [
        {
          originalName: "MuscoviteIllite",
          outputName: "MuscoviteIllite",
          decimalPlaces: 3,
          information: {
            description:
              "Mixed layer illite-muscovite; may include minor boundary textures with other phases; may include minor other K-Al silicates",
            wikipediaLinks: ["https://en.wikipedia.org/wiki/Muscovite"],
          },
        },
        {
          originalName: "Illite_main",
          outputName: "Illite main",
          information: {
            description:
              "Illite; may include minor boundary textures with other phases; may include minor other K-Al silicates",
            wikipediaLinks: ["https://en.wikipedia.org/wiki/Illite"],
          },
        },
      ],
    },
    {
      originalName: "Chlorite",
      outputName: "Chlorite",
      unit: "%",
      information: {
        description:
          "Chlorite group minerals (chamosite-clinochlore series); may include berthierine and minor other Fe-Mg-Al silicates and minor boundary textures with other phases",
        wikipediaLinks: ["https://en.wikipedia.org/wiki/Chlorite_group"],
      },
      elements: [
        {
          originalName: "Clinochlore",
          outputName: "Clinochlore",
          decimalPlaces: 3,
          information: {
            description:
              "Clinochlore chlorite; may include minor other Mg-Fe-Al silicates and minor boundary textures with other phases",
            wikipediaLinks: ["https://en.wikipedia.org/wiki/Chlorite_group"],
          },
        },
        {
          originalName: "Chlorite_main",
          outputName: "Chlorite main",
          information: {
            description:
              "Fe-Mg-bearing chlorite (intermediate composition); may include minor other Fe-Mg-Al silicates and minor boundary textures with other phases",
            wikipediaLinks: ["https://en.wikipedia.org/wiki/Chlorite_group"],
          },
        },
        {
          originalName: "Chamosite",
          outputName: "Chamosite",
          decimalPlaces: 3,
          information: {
            description:
              "Chamosite chlorite; may include minor other Fe-Mg-Al silicates and minor boundary textures with other phases",
            wikipediaLinks: ["https://en.wikipedia.org/wiki/Chamosite"],
          },
        },
        {
          originalName: "Berthierine",
          outputName: "Berthierine",
          decimalPlaces: 3,
          information: {
            description:
              "Berthierine; may include minor chlorite, other Fe-Mg-Al silicates and minor boundary textures with other phases",
            wikipediaLinks: [
              "https://en.wikipedia.org/wiki/Serpentine_subgroup",
            ],
          },
        },
      ],
    },
    {
      originalName: "Smectite",
      outputName: "Smectite",
      unit: "%",
      information: {
        description:
          "Smectite group clays (incl. montmorillonite, nontronite, saponite, beidellite, stevensite), includes mixed layer illite-smectite; may include other clays and clay intergrowth textures",
        wikipediaLinks: ["https://en.wikipedia.org/wiki/Clay_mineral"],
      },
      elements: [
        {
          originalName: "IlliteSmectite",
          outputName: "Illite-Smectite",
          decimalPlaces: 3,
          information: {
            description:
              "Mixed layer illite-smectite; minor other K(Na-Ca-Fe)Mg-Al silicates, other clays and clay intergrowth textures",
            wikipediaLinks: ["https://en.wikipedia.org/wiki/Clay_mineral"],
          },
        },
        {
          originalName: "Montmorillonite",
          outputName: "Montmorillonite",
          decimalPlaces: 3,
          information: {
            description:
              "Montmorillonite; may include minor other (Na-Ca-Mg-Fe)Al silicates, other clays and clay intergrowth textures",
            wikipediaLinks: ["https://en.wikipedia.org/wiki/Montmorillonite"],
          },
        },
        {
          originalName: "Nontronite",
          outputName: "Nontronite",
          decimalPlaces: 3,
          information: {
            description:
              "Nontronite; may include minor other (Na)Fe-Al silicates, other clays and clay intergrowth textures",
            wikipediaLinks: ["https://en.wikipedia.org/wiki/Montmorillonite"],
          },
        },
        {
          originalName: "Saponite",
          outputName: "Saponite",
          decimalPlaces: 3,
          information: {
            description:
              "Saponite; may include minor other (Ca-Na)Mg(Fe-Al) silicates, other clays and clay intergrowth textures",
            wikipediaLinks: ["https://en.wikipedia.org/wiki/Montmorillonite"],
          },
        },
        {
          originalName: "Beidellite",
          outputName: "Beidellite",
          decimalPlaces: 3,
          information: {
            description:
              "Beidellite; may include minor other (Na)Al silicates, other clays and clay intergrowth textures",
            wikipediaLinks: ["https://en.wikipedia.org/wiki/Montmorillonite"],
          },
        },
        {
          originalName: "Stevensite",
          outputName: "Stevensite",
          decimalPlaces: 3,
          information: {
            description:
              "Stevensite; may include minor other Mg(Fe) silicates, other clays and clay intergrowth textures",
          },
        },
      ],
    },
    {
      originalName: "OtherClays",
      outputName: "OtherClays",
      unit: "%",
      information: {
        description:
          "All other clays and clay-boundary textures, including fine-grained intergrowths of multiple clays; may include minor other K-Al silicates",
      },
      elements: [
        {
          originalName: "Vermiculite",
          outputName: "Vermiculite",
          decimalPlaces: 3,
          information: {
            description:
              "Vermiculite; may include minor other Mg-Fe-Al silicates and clay intergrowths",
            wikipediaLinks: ["https://en.wikipedia.org/wiki/Vermiculite"],
          },
        },
        {
          originalName: "Palygorskite",
          outputName: "Palygorskite",
          decimalPlaces: 3,
          information: {
            description:
              "Palygorskite; may include minor other Mg-Al silicates and clay intergrowths",
            wikipediaLinks: ["https://en.wikipedia.org/wiki/Palygorskite"],
          },
        },
        {
          originalName: "Sepiolite",
          outputName: "Sepiolite",
          decimalPlaces: 3,
          information: {
            description:
              "Sepiolite; may include minor other Mg silicates and clay intergrowths",
            wikipediaLinks: ["https://en.wikipedia.org/wiki/Sepiolite"],
          },
        },
        {
          originalName: "OtherClaysBLS",
          outputName: "OtherClaysBLS",
          decimalPlaces: 3,
          information: {
            description:
              "All other clays and clay-boundary textures, including fine-grained intergrowths of multiple clays; may include minor other K-Al silicates",
          },
        },
      ],
    },
    {
      originalName: "Ankerite",
      outputName: "Ankerite",
      unit: "%",

      information: {
        description:
          "Ankerite; may include kutnohorite and minor boundary textures with other phases",
        wikipediaLinks: ["https://en.wikipedia.org/wiki/Ankerite"],
      },
      elements: [
        {
          originalName: "Ankerite_main",
          outputName: "Ankerite main",
          decimalPlaces: 3,
          information: {
            description:
              "Ankerite; may include minor boundary textures with other phases",
            wikipediaLinks: ["https://en.wikipedia.org/wiki/Ankerite"],
          },
        },
        {
          originalName: "Kutnohorite",
          outputName: "Kutnohorite",
          decimalPlaces: 3,
          information: {
            wikipediaLinks: ["https://en.wikipedia.org/wiki/Kutnohorite"],
          },
        },
      ],
    },
    {
      originalName: "Siderite",
      outputName: "Siderite",
      unit: "%",
      information: {
        description:
          "Siderite; may include Mg-bearing siderite, magnesite and/or rhodochrosite and minor Fe-oxides/hydroxides; may include minor boundary textures with other phases",
        wikipediaLinks: ["https://en.wikipedia.org/wiki/Siderite"],
      },
      elements: [
        {
          originalName: "Siderite_main",
          outputName: "Siderite main",
          information: {
            description:
              "Siderite; may also include Mn-bearing and/or Mg-bearing siderite and minor Fe-oxides/hydroxides; may include minor boundary textures with other phases",
            wikipediaLinks: ["https://en.wikipedia.org/wiki/Siderite"],
          },
        },
        {
          originalName: "SideriteMagnesite",
          outputName: "Siderite/Magnesite",
          decimalPlaces: 3,
          information: {
            description:
              "Carbonates with intermediate siderite-magnesite composition",
            wikipediaLinks: [
              "https://en.wikipedia.org/wiki/Siderite",
              "https://en.wikipedia.org/wiki/Magnesite",
            ],
          },
        },
        {
          originalName: "Magnesite",
          outputName: "Magnesite",
          decimalPlaces: 3,
          information: {
            description:
              "Magnesite and Fe-bearing magnesite; may include other Mg carbonates or Mg oxides",
            wikipediaLinks: ["https://en.wikipedia.org/wiki/Magnesite"],
          },
        },
        {
          originalName: "Rhodochrosite",
          outputName: "Rhodochrosite",
          decimalPlaces: 3,
          information: {
            description:
              "Rhodochrosite; may include other Mn carbonates or Mn oxides",
            wikipediaLinks: ["https://en.wikipedia.org/wiki/Rhodochrosite"],
          },
        },
      ],
    },
    {
      originalName: "FeOxides",
      outputName: "FeOxides",
      unit: "%",
      information: {
        description:
          "Fe oxides (incl. magnetite, hematite, goethite) and/or ilmenite (may include other Ti-Fe oxides); may include minor boundary textures with other phases",
        wikipediaLinks: ["https://en.wikipedia.org/wiki/Iron(III)_oxide"],
      },
      elements: [
        {
          originalName: "FeOxides_main",
          outputName: "FeOxides main",
          information: {
            description:
              "Magnetite and/or hematite; may include minor goethite and siderite and minor boundary textures with other phases",
            wikipediaLinks: ["https://en.wikipedia.org/wiki/Iron(III)_oxide"],
          },
        },
        {
          originalName: "FeTiOxides",
          outputName: "FeTiOxides",
          decimalPlaces: 3,
          information: {
            description: "Ti-bearing Fe oxides",
            wikipediaLinks: ["https://en.wikipedia.org/wiki/Titanium_dioxide"],
          },
        },
        {
          originalName: "Ilmenite",
          outputName: "Ilmenite",
          information: {
            description:
              "Ilmenite and other Ti-Fe oxides; may include minor boundary textures with other phases",
            wikipediaLinks: [
              "Ilmenite and other Ti-Fe oxides; may include minor boundary textures with other phases",
            ],
          },
        },
        {
          originalName: "Ulvospinel",
          outputName: "Ulvospinel",
          decimalPlaces: 3,
          information: {
            description: "Ulvospinel; may include other Ti-Fe oxides",
            wikipediaLinks: ["https://en.wikipedia.org/wiki/Ulv%C3%B6spinel"],
          },
        },
        {
          originalName: "Goethite",
          outputName: "Goethite",
          decimalPlaces: 3,
          information: {
            description:
              "Goethite; may include other Fe hydroxides and/or Fe oxides; may include minor siderite",
            wikipediaLinks: ["https://en.wikipedia.org/wiki/Goethite"],
          },
        },
      ],
    },
    {
      originalName: "Barite",
      outputName: "Barite",
      unit: "%",

      information: {
        description:
          "Barite; may include Sr-bearing barite and celestine; may include boundary textures with other phases; may be derived from drilling mud additives",
        wikipediaLinks: ["https://en.wikipedia.org/wiki/Baryte"],
      },
      elements: [
        {
          originalName: "Barite_main",
          outputName: "Barite main",
          information: {
            description:
              "Barite; may include boundary textures with other phases; may be derived from drilling mud additives",
            wikipediaLinks: ["https://en.wikipedia.org/wiki/Baryte"],
          },
        },
        {
          originalName: "BariteCelestine",
          outputName: "Barite/Celestine",
          information: {
            description:
              "Sr-bearing barite; may include boundary textures with other phases; may be derived from drilling mud additives",
            wikipediaLinks: ["https://en.wikipedia.org/wiki/Baryte"],
          },
        },
        {
          originalName: "Celestine",
          outputName: "Celestine",
          decimalPlaces: 3,
          information: {
            wikipediaLinks: [
              "https://en.wikipedia.org/wiki/Celestine_(mineral)",
            ],
          },
        },
      ],
    },
    {
      originalName: "Halite",
      outputName: "Halite",
      unit: "%",

      information: {
        description:
          "Halite and/or sylvite; may include boundary textures with other phases; may have dissolved and recrystallised during sample handling",
        wikipediaLinks: ["https://en.wikipedia.org/wiki/Halite"],
      },
      elements: [
        {
          originalName: "Halite_main",
          outputName: "Halite main",
          decimalPlaces: 3,
          information: {
            description:
              "Halite; may include boundary textures with other phases; may have dissolved and recrystallised during sample handling",
            wikipediaLinks: ["https://en.wikipedia.org/wiki/Halite"],
          },
        },
        {
          originalName: "Sylvite",
          outputName: "Sylvite",
          decimalPlaces: 3,
          information: {
            description:
              "Sylvite; may include boundary textures with other phases; may have dissolved and recrystallised during sample handling",
            wikipediaLinks: ["https://en.wikipedia.org/wiki/Sylvite"],
          },
        },
        {
          originalName: "Dravite",
          outputName: "Dravite",
          decimalPlaces: 3,
          information: {
            description:
              "Dravite tourmaline; may include other (Na)Mg-Al silicates and clay intergrowths",
            wikipediaLinks: ["https://en.wikipedia.org/wiki/Tourmaline"],
          },
        },
      ],
    },
    otherPhasesGroup,
    {
      originalName: "Unclassified",
      outputName: "Unclassified",
      unit: "%",
      information: {
        description:
          "All unclassified measurement spectra; may include minor areas that charged under the electron beam",
      },
      elements: [
        {
          originalName: "LowConfidence",
          outputName: "LowConfidence",
          information: {
            description:
              "Areas that charged under the electron beam and any unclassified measurement spectra of poor quality or containing elements not identified by the Rocktype SIP",
          },
        },
        {
          originalName: "Unclassified_main",
          outputName: "Unclassified main",
          information: {
            description: "All unclassified measurement spectra",
          },
        },
      ],
    },
  ],
};

const AverageMineralGrainSizeCategory: CategoryData = {
  unit: "micron",
  elements: [
    {
      originalName: "Quartz_gs",
      outputName: "Quartz gs",
      decimalPlaces: 0,
      information: {
        description: "Average grain size quartz",
      },
    },
    {
      originalName: "KFeldspar_gs",
      outputName: "Kfeldspar gs",
      decimalPlaces: 0,
      information: {
        description: "Average grain size potassium feldspar",
      },
    },
    {
      originalName: "Albite_gs",
      outputName: "Albite gs",
      decimalPlaces: 0,
      information: {
        description: "Average grain size Albite",
      },
    },
    {
      originalName: "Oligoclase_gs",
      outputName: "Oligoclase gs",
      decimalPlaces: 0,
      information: {
        description: "Average grain size Oligoclase",
      },
    },
    {
      originalName: "AndesineAnorthite_gs",
      outputName: "AndesineAnorthite gs",
      decimalPlaces: 0,
      information: {
        description: "Average grain size AndesineAnorthite",
      },
    },
    {
      originalName: "Biotite_gs",
      outputName: "Biotite gs",
      decimalPlaces: 0,
      information: {
        description: "Average grain size Biotite",
      },
    },
    {
      originalName: "Muscovite_gs",
      outputName: "Muscovite gs",
      decimalPlaces: 0,
      information: {
        description: "Average grain size Muscovite",
      },
    },
    {
      originalName: "Glauconite_gs",
      outputName: "Glauconite gs",
      decimalPlaces: 0,
      information: {
        description: "Average grain size Glauconite",
      },
    },
    {
      originalName: "Illite_gs",
      outputName: "Illite gs",
      decimalPlaces: 0,
      information: {
        description: "Average grain size Illite",
      },
    },
    {
      originalName: "Chlorite_gs",
      outputName: "Chlorite gs",
      decimalPlaces: 0,
      information: {
        description: "Average grain size Chlorite",
      },
    },
    {
      originalName: "Kaolinite_gs",
      outputName: "Kaolinite gs",
      decimalPlaces: 0,
      information: {
        description: "Average grain size Kaolinite",
      },
    },
    {
      originalName: "Smectite_gs",
      outputName: "Smectite gs",
      decimalPlaces: 0,
      information: {
        description: "Average grain size  Smectite",
      },
    },
    {
      originalName: "OtherClays_gs",
      outputName: "OtherClays gs",
      decimalPlaces: 0,
      information: {
        description: "Average grain size OtherClays",
      },
    },
    {
      originalName: "QuartzClayMix_gs",
      outputName: "QuartzClayMix gs",
      decimalPlaces: 0,
      information: {
        description: "Average grain size QuartzClayMix",
      },
    },
    {
      originalName: "Zircon_gs",
      outputName: "Zircon gs",
      decimalPlaces: 0,
      information: {
        description: "Average grain size Zircon",
      },
    },
    {
      originalName: "Rutile_Anatase_gs",
      outputName: "Rutile Anatase gs",
      decimalPlaces: 0,
      information: {
        description: "Average grain size  Anatase",
      },
    },
    {
      originalName: "Apatite_gs",
      outputName: "Apatite gs",
      decimalPlaces: 0,
      information: {
        description: "Average grain size Apatite",
      },
    },
    {
      originalName: "Calcite_gs",
      outputName: "Calcite gs",
      decimalPlaces: 0,
      information: {
        description: "Average grain size Calcite",
      },
    },
    {
      originalName: "Dolomite_gs",
      outputName: "Dolomite gs",
      decimalPlaces: 0,
      information: {
        description: "Average grain size Dolomite",
      },
    },
    {
      originalName: "FeDolomite_gs",
      outputName: "FeDolomite gs",
      decimalPlaces: 0,
      information: {
        description: "Average grain size FeDolomite",
      },
    },
    {
      originalName: "Ankerite_gs",
      outputName: "Ankerite gs",
      decimalPlaces: 0,
      information: {
        description: "Average grain size Ankerite",
      },
    },
    {
      originalName: "Marl_gs",
      outputName: "Marl gs",
      decimalPlaces: 0,
      information: {
        description: "Average grain size Marl",
      },
    },
    {
      originalName: "Siderite_gs",
      outputName: "Siderite gs",
      decimalPlaces: 0,
      information: {
        description: "Average grain size Siderite",
      },
    },
    {
      originalName: "FeOxides_gs",
      outputName: "FeOxides gs",
      decimalPlaces: 0,
      information: {
        description: "Average grain size FeOxides",
      },
    },
    {
      originalName: "Pyrite_gs",
      outputName: "Pyrite gs",
      decimalPlaces: 0,
      information: {
        description: "Average grain size Pyrite",
      },
    },
    {
      originalName: "Barite_gs",
      outputName: "Barite gs",
      decimalPlaces: 0,
      information: {
        description: "Average grain size Barite",
      },
    },
    {
      originalName: "Anhydrite_gs",
      outputName: "Anhydrite gs",
      decimalPlaces: 0,
      information: {
        description: "Average grain size Anhydrite",
      },
    },
    {
      originalName: "Halite_gs",
      outputName: "Halite gs",
      decimalPlaces: 0,
      information: {
        description: "Average grain size Halite",
      },
    },
    {
      originalName: "OtherPhases_gs",
      outputName: "OtherPhases gs",
      decimalPlaces: 0,
      information: {
        description: "Average grain size OtherPhases",
      },
    },
    {
      originalName: "Unclassified_gs",
      outputName: "Unclassified gs",
      decimalPlaces: 0,
      information: {
        description: "Average grain size Unclassified",
      },
    },
  ],
};

const CalculatedLogValuesCategory: CategoryData = {
  elements: [
    {
      originalName: "GR",
      outputName: "Calculated Gamma Ray",
      unit: "GAPI",
      decimalPlaces: 1,
      information: {
        description: "QEMSCAN calculated Gamma Ray",
      },
    },
    {
      originalName: "VCLAY",
      outputName: "Volume of Clay phases",
      unit: "%",
      decimalPlaces: 1,
      information: {
        description:
          "QEMSCAN calculated VCLAY. Volume % of clay phases: Glauconite, Illite, Chlorite, Kaolinite, Smectite and OtherClays (Grouped List)",
      },
    },
    {
      originalName: "GDEN",
      outputName: "Calculated Density",
      unit: "g/cm3",
      decimalPlaces: 1,
      information: {
        description: "QEMSCAN calculated grain density",
      },
    },
    {
      originalName: "GNEUT",
      outputName: "Calculated Neutron porosity",
      unit: "%",
      decimalPlaces: 1,
      information: {
        description: "QEMSCAN calculated neutron porosity within grains only",
      },
    },
    { originalName: "D_unit", outputName: "D_unit", hide: true },
    { originalName: "RtID", outputName: "RtID", hide: true },
    { originalName: "Cut_N_pct", outputName: "Cut_N_pct", hide: true },
    { originalName: "Cut_size", outputName: "Cut_size", hide: true },
    { originalName: "Cut_V_pct", outputName: "Cut_V_pct", hide: true },
    { originalName: "Ltype", outputName: "Lithotypes", hide: true },
    {
      originalName: "Cut_N",
      outputName: "No of cuttings analysed per sample",
      hide: true,
    },
  ],
};

export const qemscanConfig: ElementsConfig = {
  Minerals: QemScanMineralsCategory,
  "Average mineral grain size": AverageMineralGrainSizeCategory,
  "Calculated log values": CalculatedLogValuesCategory,
};

export const qemscanSampleInfoConfig: ElementsConfig = {
  "Sample Information": {
    elements: [
      { originalName: "Ltype", outputName: "Lithotypes" },
      {
        originalName: "Cut_N",
        outputName: "No of cuttings analysed per sample",
      },
    ],
  },
};
