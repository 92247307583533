import { WellboreContent } from "./types/types";

export enum Breakpoint {
  S = "@media (max-width: 1365px)",
  S_M = "@media (max-width: 1919px)",
  M = "@media (min-width: 1366px) and (max-width: 1919px)",
  M_L = "@media (min-width: 1366px)",
  L = "@media(min-width: 1920px)",
}

export enum Colors {
  primary = "#61acbb",
  lighterAlt = "#d4e9ed",
  lighter = "#b7d9e1",
  light = "#8dc3ce",
  tertiary = "#3d808f",
  quaternary = "#e1dfdd",
  darkAlt = "#377481",
  dark = "#2b5a64",
  darker = "#254e56",
  red = "#A4262C",
  white = "#FFF",

  errorBackground = "#fde7e9",
  successBackground = "#dff6dd",
  severeWarningBackground = "#fed9cc",
  warningBackground = "#fff4ce",
  error = "#a80000",
  success = "#107c10",
  severeWarning = "#d83b01",
  warning = "#797775",

  primaryBlack = "#323130",

  wellboreGreen = "#9ACD33",
  wellboreRed = "#800101",
}

export enum DropdownName {
  SELECT1 = "imageSelect1",
  ADJUST1 = "imageAdjust1",
  SELECT2 = "imageSelect2",
  ADJUST2 = "imageAdjust2",
  BACKDROP = "backdrop",
  WELL_LOG = "well-log",
  WELL_TOPS = "well-tops",
  WELL_DATA_SYMBOLS = "well-data-symbols",
  NAVIGATION = "navigation",
  WELLBORE_FILTER = "wellbore-filter",
  MAP_LAYERS = "map-layers",
  VIEWER_TOOLS = "viewer-tools",
  MULTIPLE_WELLBORES = "multiple-wellbores",
  SIFT_COLUMN_ALIGNMENT = "sift-column-alignment",
}

export enum ViewMode {
  FIT_TO_WIDTH = "FIT_TO_WIDTH",
  FIT_TO_PAGE = "FIT_TO_PAGE",
  KEEP_VIEW = "KEEP_VIEW",
}

export enum AnalyticsName {
  XRF = "xrf",
  SPEC_CAM = "speccam",
  XRD = "xrd",
  TOC = "toc",
  QEM_SCAN = "qemscan",
}

export enum ChartType {
  STACKED_BAR = "stacked-bar",
  SCATTER = "scatter",
  STACKED_AREA = "stacked-area",
  NONE = "none",
}

export enum WellTopsName {
  NPD = "npd",
}

export enum MapLayerName {
  RWI = "rwi wellbores",
  NON_RWI = "non-rwi-wellbores",
  BLOCKS_AND_QUADS = "blocks-and-quads",
}

export enum PromiseStatus {
  IDLE,
  LOADING,
  SUCCESS,
  ERROR,
}

export enum TooltipType {
  ANALYTICS_SELECTION = "ANALYTICS_SELECTION",
  WELLBORE_TOPS = "WELLBORE_TOPS",
  WELLBORE_SYMBOL = "WELLBORE_SYMBOL",
  MAP_WELLBORE_INFO = "MAP_WELLBORE_INFO",
  HITS_TRACK = "HITS_TRACK",
}

export const wellboreContentIconReferences: Record<WellboreContent, string> = {
  GAS: "/files/wellbore_content_icons/gas.svg",
  OIL: "/files/wellbore_content_icons/oil.svg",
  DRY: "/files/wellbore_content_icons/dry.svg",
  "OIL SHOWS": "/files/wellbore_content_icons/oil_shows.svg",
  "OIL/GAS": "/files/wellbore_content_icons/oil_gas.svg",
  "OIL/GAS SHOWS": "/files/wellbore_content_icons/oil_gas_shows.svg",
  "GAS SHOWS": "/files/wellbore_content_icons/gas_shows.svg",
  "GAS/CONDENSATE": "/files/wellbore_content_icons/gas_condensate.svg",
  "NOT APPLICABLE": "/files/wellbore_content_icons/not_applicable.svg",
  SHOWS: "/files/wellbore_content_icons/gas_shows.svg",
  "OIL/GAS/CONDENSATE": "/files/wellbore_content_icons/gas_condensate.svg",
};
