import {
  ContextualMenu,
  IButtonStyles,
  IconButton,
  IDragOptions,
  IIconProps,
  IModalStyleProps,
  IModalStyles,
  IStyleFunctionOrObject,
  Modal,
} from "@fluentui/react";
import { NeutralColors } from "@fluentui/theme";
import React, { FC } from "react";
import { PinnableTooltipData } from "../../../types/types";

interface PinnedTooltipModalProps {
  data: PinnableTooltipData;
  onClose: () => void;
  hide: boolean;
}

const modalDragOptions: IDragOptions = {
  moveMenuItemText: "Move",
  closeMenuItemText: "Close",
  menu: ContextualMenu,
  keepInBounds: false,
};

const getModalStyles = (
  data: PinnableTooltipData,
  hide: boolean
): IStyleFunctionOrObject<IModalStyleProps, IModalStyles> => ({
  root: {
    display: hide ? "none" : "flex",
    alignItems: "start",
    justifyContent: "left",
  },
  main: {
    minHeight: 0,
    minWidth: 0,
    overflowY: "initial",
    top: data.tooltipAttributes.top,
    left: data.tooltipAttributes.left,
  },
});

const cancelIcon: IIconProps = { iconName: "Cancel" };

const cancelIconStyles: IButtonStyles = {
  root: {
    width: 15,
    height: 15,
    marginLeft: "auto",
    display: "none",
    backgroundColor: NeutralColors.gray10,
    color: NeutralColors.gray130,
  },
  rootHovered: {
    background: NeutralColors.gray40,
    color: NeutralColors.gray130,
  },
};

export const PinnedTooltipModal: FC<PinnedTooltipModalProps> = ({
  data,
  onClose,
  hide,
}) => {
  return (
    <Modal
      isOpen={true}
      dragOptions={modalDragOptions}
      isModeless={true}
      styles={getModalStyles(data, hide)}
    >
      <div className="pinned-tooltip" data-tooltip-id={data.tooltipElementId}>
        {data.tooltipData.stratigraphy && (
          <div className="pinned-tooltip__stratigraphy">
            <div className="pinned-tooltip__stratigraphy__item">
              <span
                style={{
                  background: data.tooltipData.stratigraphy.group.color,
                }}
              ></span>
              <span>{data.tooltipData.stratigraphy.group.name}</span>
            </div>
            <div className="pinned-tooltip__stratigraphy__item">
              <span
                style={{
                  background: data.tooltipData.stratigraphy.formation.color,
                }}
              ></span>
              <span>{data.tooltipData.stratigraphy.formation.name}</span>
            </div>
          </div>
        )}
        {data.tooltipData.groups.map((group, groupIndex) => (
          <div key={groupIndex} className="pinned-tooltip__group">
            <div className="pinned-tooltip__heading">
              <span>{group.heading}</span>
              {groupIndex === 0 && (
                <IconButton
                  className="pinned-tooltip__heading__close"
                  iconProps={cancelIcon}
                  ariaLabel="Close pinned tooltip"
                  styles={cancelIconStyles}
                  onClick={onClose}
                />
              )}
            </div>
            {group.rows.map((row, rowIndex) => (
              <div key={rowIndex} className="pinned-tooltip__row">
                {row.dotColor && (
                  <>
                    <span
                      className="color-dot"
                      style={{ background: row.dotColor }}
                    ></span>
                    &nbsp;
                  </>
                )}
                <span className="label">
                  {row.label}
                  {row.value !== "" && <>:&nbsp;</>}
                </span>
                {row.value !== "" && <span>{row.value}</span>}
              </div>
            ))}
          </div>
        ))}
        <div
          className={`pinned-tooltip__beak ${
            data.tooltipAttributes.tooltipAboveMouse
              ? ""
              : "pinned-tooltip__beak--top"
          }`}
        ></div>
      </div>
    </Modal>
  );
};
