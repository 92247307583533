import React, { FC, useCallback, useEffect } from "react";
import { useAppSprings } from "../../../context";
import { usePinnedTooltips } from "../../../context/PinnedTooltipsContext";
import { clearTooltipLines, drawTooltipLines } from "./pinned-tooltip-lines";
import { PinnedTooltipModal } from "./PinnedTooltipModal";

const TOOLTIPS_LINES_SVG_ID = "pinned-tooltips-lines-svg";

export interface PinnedTooltipsProps {
  mapViewIsOpen: boolean;
}

const PinnedTooltipsImpl: FC<PinnedTooltipsProps> = ({ mapViewIsOpen }) => {
  const { pinnedTooltips, removePinnedTooltip } = usePinnedTooltips();
  const { overlayAnalytics } = useAppSprings();

  const onCloseHandler = useCallback(
    (index: number) => () => {
      removePinnedTooltip(index);
    },
    [removePinnedTooltip]
  );

  useEffect(() => {
    let animationFrameId: number;

    const animateTooltipLines = () => {
      drawTooltipLines(pinnedTooltips);
      animationFrameId = requestAnimationFrame(animateTooltipLines);
    };
    if (pinnedTooltips.length > 0) {
      animationFrameId = requestAnimationFrame(animateTooltipLines);
    }
    return () => {
      cancelAnimationFrame(animationFrameId);
      clearTooltipLines();
    };
  }, [pinnedTooltips]);

  return (
    <>
      {pinnedTooltips.map((data, index) => (
        <PinnedTooltipModal
          data={data}
          key={data.tooltipElementId}
          onClose={onCloseHandler(index)}
          hide={mapViewIsOpen && overlayAnalytics}
        />
      ))}
      <svg
        id={TOOLTIPS_LINES_SVG_ID}
        style={{
          display: mapViewIsOpen && overlayAnalytics ? "none" : "",
        }}
      ></svg>
    </>
  );
};

export const PinnedTooltips = React.memo(PinnedTooltipsImpl);
