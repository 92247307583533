import { useEffect, useState } from "react";
import { TooltipType } from "../../constants";
import { usePinnedTooltips } from "../../context";
import { UseState } from "../../types";

export function useTooltipClearState<T>(
  pinnedTooltipType: TooltipType,
  defaultValue: T | (() => T)
): UseState<T> {
  const [state, setState] = useState(defaultValue);
  const { clearPinnedTooltipsOfType } = usePinnedTooltips();

  useEffect(() => {
    clearPinnedTooltipsOfType(pinnedTooltipType);
  }, [clearPinnedTooltipsOfType, pinnedTooltipType, state]);

  return [state, setState];
}
