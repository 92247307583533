import firebase from "firebase/compat/app";
import { startCase } from "lodash";
import { WELLBORE_DEPTH_RANGE_WARNING_THRESHOLD } from "../config/common";
import {
  MISSING_STRATIGRAPHY_COLOR,
  MISSING_STRATIGRAPHY_NAME,
} from "../config/stratigraphy";
import {
  MissingDataCollection,
  MissingStratigraphy,
  Optional,
  ProcessedTops,
  Stratigraphy,
  TopGroup,
  Wellbore,
} from "../types";
import { WellboreSiftResult } from "../types/sift/storeTypes";

export function getClasses(
  ...classes: (string | undefined | { [className: string]: boolean })[]
): string {
  return classes
    .flatMap((element) => {
      if (typeof element === "string") {
        return element;
      }
      if (element === undefined) {
        return "";
      }
      return Object.entries(element).reduce<string[]>(
        (acc, [cls, condition]) => {
          if (condition) {
            acc.push(cls);
          }
          return acc;
        },
        []
      );
    })
    .join(" ");
}

export function getGroupAndFormation(
  topGroups: Optional<TopGroup[]>,
  depth: number
): Stratigraphy {
  let group, formation;
  if (topGroups) {
    const lastGroupIndex = topGroups.length - 1;
    group = topGroups.find((topGroup, i) => {
      const isLastGroup = i === lastGroupIndex;
      if (!isLastGroup) {
        return topGroup.top <= depth && depth < topGroup.bottom;
      }
      return topGroup.top <= depth && depth <= topGroup.bottom;
    });
    if (group) {
      const lastFormationIndex = group.formations.length - 1;
      formation = group.formations.find((formation, i) => {
        const isLastFormation = i === lastFormationIndex;
        if (!isLastFormation) {
          return formation.top <= depth && depth < formation.bottom;
        }
        return formation.top <= depth && depth <= formation.bottom;
      });
    }
  }
  return {
    group: {
      name: getFormattedStratigraphyName(
        group?.name ?? MISSING_STRATIGRAPHY_NAME
      ),
      top: group?.top ?? 0,
      color: group?.color ?? MISSING_STRATIGRAPHY_COLOR,
    },
    formation: {
      name: getFormattedStratigraphyName(
        formation?.name ?? MISSING_STRATIGRAPHY_NAME
      ),
      top: formation?.top ?? 0,
      color: formation?.color ?? MISSING_STRATIGRAPHY_COLOR,
    },
  };
}

export function isStratigraphyInWellbore(
  wellboreResult: WellboreSiftResult,
  stratigraphyName: string,
  stratigraphyDataForWellbore: TopGroup[]
) {
  return stratigraphyDataForWellbore.some(
    (group) =>
      (group.name.toLowerCase() === stratigraphyName.toLowerCase() &&
        stratigraphyWithinCuttingsRange(group, wellboreResult)) ||
      group.formations.some(
        (fm) =>
          fm.name.toLowerCase() === stratigraphyName.toLowerCase() &&
          stratigraphyWithinCuttingsRange(fm, wellboreResult)
      )
  );
}

export const isTWellbore = (wellboreName: string): boolean =>
  /t/gi.test(wellboreName);

type ClickHandler = (e: PointerEvent) => void;
export class DoubleClickDetector {
  clickHandler?: ClickHandler;
  doubleClickHandler?: ClickHandler;
  private _DOUBLE_CLICK_TIMEOUT = 300;
  private _timeOfLastClick?: number;
  private _targetOfLastClick?: EventTarget | null;
  private _clickHandlerTimeout?: number;

  detect(e: PointerEvent): boolean {
    const timeOfClick = Date.now();

    if (
      this._targetOfLastClick !== undefined &&
      this._timeOfLastClick !== undefined &&
      this._targetOfLastClick === e.target &&
      timeOfClick - this._timeOfLastClick <= this._DOUBLE_CLICK_TIMEOUT
    ) {
      window.clearTimeout(this._clickHandlerTimeout);
      this.doubleClickHandler?.(e);
      return true;
    }

    this._timeOfLastClick = timeOfClick;
    this._targetOfLastClick = e.target;
    this._clickHandlerTimeout = window.setTimeout(
      () => this.clickHandler?.(e),
      this._DOUBLE_CLICK_TIMEOUT + 10
    );
    return false;
  }

  onClick(handler: ClickHandler): this {
    this.clickHandler = handler;
    return this;
  }
  onDoubleClick(handler: ClickHandler): this {
    this.doubleClickHandler = handler;
    return this;
  }
}

export function getFormattedStratigraphyName(stratigraphyName: string): string {
  return startCase(stratigraphyName.toLowerCase())
    .replace("Gp", "GP")
    .replace("Fm", "FM");
}

export function distanceBetweenGeoPoints(
  pointA: firebase.firestore.GeoPoint,
  pointB: firebase.firestore.GeoPoint
): number {
  if (
    pointA.latitude === pointB.latitude &&
    pointA.longitude === pointB.longitude
  ) {
    return 0;
  } else {
    const radlat1 = (Math.PI * pointA.latitude) / 180;
    const radlat2 = (Math.PI * pointB.latitude) / 180;
    const theta = pointA.longitude - pointB.longitude;
    const radtheta = (Math.PI * theta) / 180;
    let dist =
      Math.sin(radlat1) * Math.sin(radlat2) +
      Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
      dist = 1;
    }
    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515;
    return dist;
  }
}

export function getDistanceBetweenWellbores(
  wellboreA: Wellbore,
  wellboreB: Wellbore
): Optional<number> {
  return wellboreA.coordinates && wellboreB.coordinates
    ? distanceBetweenGeoPoints(wellboreA.coordinates, wellboreB.coordinates)
    : undefined;
}

export function getMissingDataWarnings(
  cuttingDepths: number[],
  stratigrapyData: Optional<ProcessedTops>,
  wellboreName: string
) {
  const sortedCuttingDepths = cuttingDepths.slice().sort((a, b) => a - b);
  return sortedCuttingDepths
    .slice(1)
    .reduce<MissingDataCollection[]>((missingData, cuttingDepth, index) => {
      const previusCuttingDepth = sortedCuttingDepths[index];

      const missingDepthInterval = getMissingDepthInterval(
        cuttingDepth,
        previusCuttingDepth
      );

      const missingStratigraphies = getMissingStratigraphies(
        stratigrapyData,
        wellboreName,
        previusCuttingDepth,
        cuttingDepth
      );

      if (missingDepthInterval || missingStratigraphies.length !== 0) {
        missingData.push({
          startDepth: previusCuttingDepth,
          endDepth: cuttingDepth,
          startDepthIndex: index,
          missingStratigraphies: missingStratigraphies,
          missingDepthInterval: missingDepthInterval,
        });
      }
      return missingData;
    }, []);
}
function getMissingStratigraphies(
  stratigrapyData: Optional<ProcessedTops>,
  wellboreName: string,
  previusCuttingDepth: number,
  cuttingDepth: number
): MissingStratigraphy[] {
  const missingStratigraphies: MissingStratigraphy[] = [];

  if (stratigrapyData && stratigrapyData[wellboreName] !== undefined) {
    for (const topGroup of stratigrapyData[wellboreName]) {
      if (
        previusCuttingDepth < topGroup.top &&
        topGroup.bottom < cuttingDepth
      ) {
        missingStratigraphies.push({
          ...topGroup,
        });
      }
      for (const topFormation of topGroup.formations) {
        if (
          previusCuttingDepth < topFormation.top &&
          topFormation.bottom <= cuttingDepth
        ) {
          missingStratigraphies.push({
            ...topFormation,
          });
        }
      }
    }
  }
  return missingStratigraphies;
}

function getMissingDepthInterval(
  cuttingDepth: number,
  previusCuttingDepth: number
) {
  return cuttingDepth - previusCuttingDepth >
    WELLBORE_DEPTH_RANGE_WARNING_THRESHOLD
    ? true
    : false;
}

function stratigraphyWithinCuttingsRange(
  formation: Pick<TopGroup, "bottom" | "top">,
  wellboreResult: WellboreSiftResult
) {
  return wellboreResult.wellbore.cuttingDepths.some(
    (depth) => formation.top < depth && depth < formation.bottom
  );
}
