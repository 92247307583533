import React, { FC } from "react";
import { animated } from "react-spring";
import { useAppSprings } from "../../../context";
import { getClasses } from "../../../utils";
import { WIDTH_OF_DATA_WARNING_TRACK } from "../dataWarningTrack/DataWarningTrack";
import { WIDTH_OF_SIDEBAR_MENU } from "../sidebarMenu/SidebarMenu";
import { TRACK_CONTAINER_CLASS_NAME, TRACK_MARGIN } from "../track";
import { WIDTH_OF_WELL_TOPS } from "../wellboreTops/WellboreTops";

export const WellTopsFrame: FC<React.HTMLAttributes<HTMLDivElement>> = ({
  children,
  className,
  ...restProps
}) => {
  const { showWellTops } = useAppSprings();

  return (
    <div
      {...restProps}
      className={getClasses(TRACK_CONTAINER_CLASS_NAME, className)}
      style={{
        display: showWellTops ? "flex" : "none",
        width: `${WIDTH_OF_WELL_TOPS}px`,
        right: `${WIDTH_OF_SIDEBAR_MENU + TRACK_MARGIN}px`,
      }}
    >
      {children}
    </div>
  );
};

export const RangeFinderFrame: FC<React.HTMLAttributes<HTMLDivElement>> = ({
  children,
  className,
  ...restProps
}) => {
  const { showWellTops, rangeFinderSpring } = useAppSprings();

  return (
    <animated.div
      {...restProps}
      className={getClasses(TRACK_CONTAINER_CLASS_NAME, className)}
      style={{
        ...rangeFinderSpring,
        right:
          WIDTH_OF_SIDEBAR_MENU +
          (showWellTops ? TRACK_MARGIN + WIDTH_OF_WELL_TOPS : 0) +
          TRACK_MARGIN +
          "px",
      }}
    >
      {children}
    </animated.div>
  );
};

export const WellDataSymbolsFrame: FC<React.HTMLAttributes<HTMLDivElement>> = ({
  children,
  className,
  ...restProps
}) => {
  const { widthOfWellDataSymbols, showWellTops, rangeFinderSpring } =
    useAppSprings();

  return (
    <animated.div
      {...restProps}
      className={getClasses(TRACK_CONTAINER_CLASS_NAME, className)}
      style={{
        display: widthOfWellDataSymbols > 0 ? "flex" : "none",
        width: `${widthOfWellDataSymbols}px`,
        right: rangeFinderSpring?.width?.interpolate(
          (rangeFinderWidth: number) => {
            return `${
              WIDTH_OF_SIDEBAR_MENU +
              TRACK_MARGIN +
              rangeFinderWidth +
              (showWellTops ? TRACK_MARGIN + WIDTH_OF_WELL_TOPS : 0) +
              TRACK_MARGIN
            }px`;
          }
        ),
      }}
    >
      {children}
    </animated.div>
  );
};

export const ZoomAreaFrame: FC<React.HTMLAttributes<HTMLDivElement>> = ({
  className,
  children,
  ...restProps
}) => {
  const {
    widthOfWellDataSymbols,
    showWellTops,
    rangeFinderSpring,
    showZoomAreaTrack,
    zoomAreaSpring,
    dataWarningTrackVisible,
  } = useAppSprings();

  return (
    <animated.div
      {...restProps}
      className={getClasses(TRACK_CONTAINER_CLASS_NAME, className)}
      style={{
        ...zoomAreaSpring,
        display: showZoomAreaTrack ? "flex" : "none",
        right: rangeFinderSpring?.width?.interpolate(
          (rangeFinderWidth: number) => {
            return `${
              WIDTH_OF_SIDEBAR_MENU +
              TRACK_MARGIN +
              rangeFinderWidth +
              (showWellTops ? TRACK_MARGIN + WIDTH_OF_WELL_TOPS : 0) +
              (widthOfWellDataSymbols
                ? TRACK_MARGIN + widthOfWellDataSymbols
                : 0) +
              (dataWarningTrackVisible
                ? TRACK_MARGIN + WIDTH_OF_DATA_WARNING_TRACK
                : 0) +
              TRACK_MARGIN
            }px`;
          }
        ),
      }}
    >
      {children}
    </animated.div>
  );
};

export const DataWarningFrame: FC<React.HTMLAttributes<HTMLDivElement>> = ({
  children,
  className,
  ...restProps
}) => {
  const {
    dataWarningTrackVisible,
    showWellTops,
    rangeFinderSpring,
    widthOfWellDataSymbols,
  } = useAppSprings();

  return (
    <animated.div
      {...restProps}
      className={getClasses(TRACK_CONTAINER_CLASS_NAME, className)}
      style={{
        display: dataWarningTrackVisible ? "flex" : "none",
        width: `${WIDTH_OF_DATA_WARNING_TRACK}px`,
        right: rangeFinderSpring?.width?.interpolate(
          (rangeFinderWidth: number) => {
            return `${
              WIDTH_OF_SIDEBAR_MENU +
              TRACK_MARGIN +
              rangeFinderWidth +
              (showWellTops ? TRACK_MARGIN + WIDTH_OF_WELL_TOPS : 0) +
              (widthOfWellDataSymbols
                ? TRACK_MARGIN + widthOfWellDataSymbols
                : 0) +
              TRACK_MARGIN
            }px`;
          }
        ),
      }}
    >
      {children}
    </animated.div>
  );
};
