import {
  IButtonProps,
  IButtonStyles,
  IconButton,
  IIconStyles,
  mergeStyleSets,
} from "@fluentui/react";
import { NeutralColors } from "@fluentui/theme";
import React, { FC } from "react";
import { Breakpoint, Colors } from "../../../constants";

export const toolbarIconStyles: IIconStyles = {
  root: {
    color: NeutralColors.gray160,
    [Breakpoint.S]: {
      fontSize: "16px",
    },
    [Breakpoint.M]: {
      fontSize: "20px",
    },
    [Breakpoint.L]: {
      fontSize: "24px",
    },
  },
};

const activeState = {
  background: Colors.primary,
  selectors: {
    "*": {
      color: NeutralColors.white,
    },
  },
};

const baseButtonStyles: IButtonStyles = {
  root: {
    background: NeutralColors.gray30,
    borderRadius: "0",
    selectors: {
      "&:focus::after": {
        outline: `1px solid ${Colors.primary} !important`,
      },
      "*": {
        color: NeutralColors.gray160,
      },
    },
  },
  icon: toolbarIconStyles.root,
  rootHovered: {
    background: NeutralColors.gray40,
  },
  rootPressed: {
    ...activeState,
  },
  iconPressed: {
    color: NeutralColors.white,
  },
  rootDisabled: {
    background: NeutralColors.gray30,
  },
  iconDisabled: {
    color: `${NeutralColors.gray90} !important`,
  },
  rootChecked: {
    ...activeState,
  },
  rootCheckedHovered: {
    ...activeState,
  },
};

const buttonDimensionStyles = {
  root: {
    [Breakpoint.S]: {
      width: "64px",
      height: "64px",
    },
    [Breakpoint.M]: {
      width: "80px",
      height: "80px",
    },
    [Breakpoint.L]: {
      width: "96px",
      height: "96px",
    },
  },
};

export const ToolbarIconButton: FC<
  IButtonProps & { defaultDimensions?: boolean }
> = ({ defaultDimensions, styles, ...restProps }) => {
  const dimensionStyles = !defaultDimensions ? buttonDimensionStyles : {};
  return (
    <IconButton
      styles={mergeStyleSets(baseButtonStyles, dimensionStyles, styles)}
      {...restProps}
    />
  );
};
