import { ITag } from "@fluentui/react";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { mapValues } from "lodash";
import { AppDispatch, setWellbores, StoreState } from ".";
import { STRATIGRAPHY_NAMES } from "../config/stratigraphy";
import {
  mapSiftResponseToResultModel,
  requestSiftSearch,
} from "../services/siftSearchService";
import { getWellbores } from "../services/wellboreService";
import {
  AccessRights,
  ErrorDetails,
  NumberRange,
  Optional,
  ProcessedTops,
} from "../types";
import {
  AnalyticsSiftGroup,
  AnalyticsValue,
  NumberRangeDetails,
  SiftElementDetail,
  SiftParameterDetail,
  SiftParameters,
  SiftResults,
  WellboreSiftResult,
} from "../types/sift/storeTypes";
import {
  getNewDefaultAnalyticsGroup,
  isStratigraphyInWellbore,
  removeDuplicates,
  siftParametersHasErrors,
} from "../utils";
import { blocks } from "./../config/analytics/blocks";
import { GroupType } from "./../types/sift/storeTypes";
import {
  initialSiftRealDepthModeActiveFromLocalStorage,
  initialSiftSelectedBackdropFromLocalStorage,
  initialSiftShowDataWarningsFromLocalStorage,
  initialSiftShowStratigraphyFromLocalStorage,
} from "./initialStateFromLocalStorage";
import {
  initialAnalyticsParameters,
  initialUrlWellboreName,
} from "./initialStateFromUrl";

export type SiftSliceState = {
  pinnedWellboreName: string;
  siftParameters: SiftParameters;
  searchInitiated: boolean;
  siftResults: Optional<SiftResults>;
  stratigraphyNames: Optional<string[]>;
  siftError: Optional<ErrorDetails>;
  selectedBackdrop: string;
  showStratigraphy: boolean;
  showMap: boolean;
  alignByStratigraphy: Optional<string>;
  showDataWarnings: boolean;
  realDepthModeActive: boolean;
  showWellSymbols: boolean;
};

const getBlocksFromUrl = (wellBoreName?: string) => {
  if (!wellBoreName) return [];
  const quadrantName = wellBoreName.match(/[\d]*[^/]/);
  return blocks.filter((el) => el.startsWith(`${quadrantName}/`));
};

const siftSlice = createSlice({
  name: "sift",
  initialState: {
    pinnedWellboreName: initialUrlWellboreName,
    siftParameters: {
      selectedAnalyticsParameters: initialAnalyticsParameters,
      selectedDepthInterval: undefined,
      defaultDepthInterval: undefined,
      selectedStratigraphyTags: [],
      includeWellboresWithoutStratigraphy: true,
      showAllErrors: false,
      selectedAreaOfInterestBlocks: getBlocksFromUrl(initialUrlWellboreName),
    },
    stratigraphyNames: STRATIGRAPHY_NAMES,
    searchInitiated: false,
    siftResults: undefined,
    siftError: undefined,
    selectedBackdrop: initialSiftSelectedBackdropFromLocalStorage,
    showStratigraphy: initialSiftShowStratigraphyFromLocalStorage,
    showMap: false,
    alignByStratigraphy: undefined,
    showDataWarnings: initialSiftShowDataWarningsFromLocalStorage,
    realDepthModeActive: initialSiftRealDepthModeActiveFromLocalStorage,
    showWellSymbols: false,
  } as SiftSliceState,
  reducers: {
    setSearchInitiated: (state) => {
      state.searchInitiated = true;
      state.siftResults = undefined;
      state.siftParameters.showAllErrors = false;
    },
    setShowAllErrors: (state: SiftSliceState) => {
      state.siftParameters.selectedAnalyticsParameters =
        state.siftParameters.selectedAnalyticsParameters.map((group) => ({
          ...group,
          analyticsParameters: mapValues(group.analyticsParameters, (values) =>
            values.map(mapValueWithError())
          ),
        }));

      state.siftParameters.showAllErrors = true;
    },
    setSiftResults: (
      state,
      action: PayloadAction<{
        siftResults: WellboreSiftResult[];
        pinnedWellboreResult: WellboreSiftResult;
        selectedStratigraphies: string[];
      }>
    ) => {
      state.siftResults = {
        wellboreResults: action.payload.siftResults,
        pinnedWellboreResult: action.payload.pinnedWellboreResult,
        selectedStratigraphies: action.payload.selectedStratigraphies,
      };
      state.alignByStratigraphy = undefined;

      const siftResultHasSelectedBackdrop = action.payload.siftResults
        .flatMap((result) => result.wellbore.backdrops)
        .map((backdrop) => backdrop?.type)
        .some((backdropType) => backdropType === state.selectedBackdrop);

      const pinnedResultHasSelectedBackdrop =
        action.payload.pinnedWellboreResult.wellbore.backdrops
          ?.map((backdrop) => backdrop.type)
          ?.some((backdropType) => backdropType === state.selectedBackdrop) ??
        false;

      if (!siftResultHasSelectedBackdrop && !pinnedResultHasSelectedBackdrop) {
        state.selectedBackdrop = "W_1";
      }
    },
    addSelectedAnalyticsParametersGroup: (
      state,
      action: PayloadAction<{ previousRelation: Optional<GroupType> }>
    ) => {
      state.siftParameters.selectedAnalyticsParameters.push(
        getNewDefaultAnalyticsGroup(action.payload.previousRelation)
      );
    },
    removeSelectedAnalyticsParametersGroup: (
      state,
      action: PayloadAction<string>
    ) => {
      const newGroupsArray =
        state.siftParameters.selectedAnalyticsParameters.filter(
          (group) => action.payload !== group.id
        );
      if (newGroupsArray.length !== 0) {
        newGroupsArray[0].previusRelation = undefined;
      }
      state.siftParameters.selectedAnalyticsParameters = newGroupsArray;
    },
    resetSelectedAnalyticsParameters: (state) => {
      state.siftParameters.selectedAnalyticsParameters = [
        getNewDefaultAnalyticsGroup(),
      ];
    },
    removeSelectedAnalyticsParameters: (
      state,
      action: PayloadAction<SiftParameterDetail>
    ) => {
      const parameterAnalyticsType = action.payload.analyticsName;
      const parameterIndex = action.payload.parameterIndex;
      const groupIndex = action.payload.groupIndex;

      state.siftParameters.selectedAnalyticsParameters[
        groupIndex
      ].analyticsParameters[parameterAnalyticsType].splice(parameterIndex, 1);
    },
    addSelectedAnalyticsParameters: (
      state,
      action: PayloadAction<SiftElementDetail>
    ) => {
      const groupIndex = action.payload.groupIndex;
      const analyticsName = action.payload.element.analyticsName;

      state.siftParameters.selectedAnalyticsParameters[
        groupIndex
      ].analyticsParameters[analyticsName].push({
        ...action.payload.element,
        lowerValue: "",
        higherValue: "",
        lowerError: false,
        higherError: false,
        errorMessage: undefined,
        isInitialValue: false,
      });
    },
    updateSelectedAnalyticsGroup: (
      state,
      action: PayloadAction<AnalyticsSiftGroup>
    ) => {
      const indexOfGroup =
        state.siftParameters.selectedAnalyticsParameters.findIndex(
          (el) => el.id === action.payload.id
        );
      if (indexOfGroup !== -1) {
        state.siftParameters.selectedAnalyticsParameters[indexOfGroup] =
          action.payload;
      }
    },
    setSelectedDepthInterval: (state, action: PayloadAction<NumberRange>) => {
      state.siftParameters.selectedDepthInterval = action.payload;
    },
    resetSelectedDepthInterval: (state) => {
      state.siftParameters.selectedDepthInterval =
        state.siftParameters.defaultDepthInterval;
    },
    resetSelectedSiftParameters: (state) => {
      state.siftParameters.selectedAnalyticsParameters = [
        getNewDefaultAnalyticsGroup(),
      ];
      state.siftParameters.selectedDepthInterval =
        state.siftParameters.defaultDepthInterval;
      state.siftParameters.selectedStratigraphyTags = [];
      state.siftParameters.includeWellboresWithoutStratigraphy = true;
      state.siftParameters.selectedAreaOfInterestBlocks = [];
    },
    setDefaultDepthInterval: (state, action: PayloadAction<NumberRange>) => {
      state.siftParameters.defaultDepthInterval = action.payload;
      state.siftParameters.selectedDepthInterval = action.payload;
    },
    setStratigraphyNames: (
      state,
      action: PayloadAction<Optional<string[]>>
    ) => {
      state.stratigraphyNames = action.payload;
    },
    setSelectedStratigraphyTags: (state, action: PayloadAction<ITag[]>) => {
      state.siftParameters.selectedStratigraphyTags = action.payload;
    },
    setIncludeWellboresWithoutStratigraphy: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.siftParameters.includeWellboresWithoutStratigraphy = action.payload;
    },
    resetSelectedStratigraphyOptions: (state) => {
      state.siftParameters.selectedStratigraphyTags = [];
      state.siftParameters.includeWellboresWithoutStratigraphy = true;
    },
    updateSelectedAnalyticsParametersNumberRange: (
      state,
      action: PayloadAction<NumberRangeDetails>
    ) => {
      const group =
        state.siftParameters.selectedAnalyticsParameters[
          action.payload.groupIndex
        ];

      group.analyticsParameters[action.payload.analyticsValue.analyticsName][
        action.payload.parameterIndex
      ] = action.payload.analyticsValue;
    },
    addSelectedAreaOfInterestBlocks: (
      state,
      action: PayloadAction<string[]>
    ) => {
      state.siftParameters.selectedAreaOfInterestBlocks = removeDuplicates([
        ...state.siftParameters.selectedAreaOfInterestBlocks,
        ...action.payload,
      ]);
    },
    updateSelectedAreaOfInterestBlocks: (
      state,
      action: PayloadAction<ITag[]>
    ) => {
      state.siftParameters.selectedAreaOfInterestBlocks = action.payload.map(
        (el) => el.name
      );
    },
    resetSelectedAreaOfInterestBlocks: (state) => {
      state.siftParameters.selectedAreaOfInterestBlocks = [];
    },
    setSiftError: (state, action: PayloadAction<ErrorDetails>) => {
      state.searchInitiated = false;
      state.siftError = action.payload;
    },
    clearSiftError: (state) => {
      state.siftError = undefined;
    },
    setSelectedBackdrop: (state, action: PayloadAction<string>) => {
      state.selectedBackdrop = action.payload;
    },
    toggleShowStratigraphy: (state) => {
      state.showStratigraphy = !state.showStratigraphy;
    },
    toggleShowMap: (state) => {
      state.showMap = !state.showMap;
    },
    toggleHideWellboreResult: (state, action: PayloadAction<string>) => {
      const wellboreResult = state.siftResults?.wellboreResults.find(
        (result) => result.wellbore.name === action.payload
      );
      if (wellboreResult) {
        wellboreResult.hidden = !wellboreResult.hidden;
      }
    },
    toggleWellSymbols: (state) => {
      state.showWellSymbols = !state.showWellSymbols;
    },
    setAlignByStratigraphy: (
      state,
      action: PayloadAction<{
        stratigraphyName: string;
        wellTopsData: ProcessedTops;
      }>
    ) => {
      if (state.siftResults) {
        const stratigraphyFilterActive =
          state.alignByStratigraphy !== action.payload.stratigraphyName;

        state.alignByStratigraphy = stratigraphyFilterActive
          ? action.payload.stratigraphyName
          : undefined;

        state.siftResults.wellboreResults =
          state.siftResults.wellboreResults.map((result) => {
            const stratigraphyDataForWellbore =
              action.payload.wellTopsData[result.wellbore.name];

            if (stratigraphyDataForWellbore === undefined) {
              return {
                ...result,
                filtered: stratigraphyFilterActive,
              };
            }

            return {
              ...result,
              filtered:
                stratigraphyFilterActive &&
                !isStratigraphyInWellbore(
                  result,
                  action.payload.stratigraphyName,
                  stratigraphyDataForWellbore
                ),
            };
          });
      }
    },
    toggleShowDataWarnings: (state) => {
      state.showDataWarnings = !state.showDataWarnings;
    },
    toggleSiftRealDepthModeActive: (state) => {
      state.realDepthModeActive = !state.realDepthModeActive;
    },
  },
});

export const siftReducer = siftSlice.reducer;

export const {
  setSearchInitiated,
  setShowAllErrors,
  setSiftResults,
  addSelectedAnalyticsParametersGroup,
  resetSelectedAnalyticsParameters,
  removeSelectedAnalyticsParametersGroup,
  addSelectedAnalyticsParameters,
  removeSelectedAnalyticsParameters,
  updateSelectedAnalyticsGroup,
  setSelectedDepthInterval,
  resetSelectedDepthInterval,
  resetSelectedSiftParameters,
  setDefaultDepthInterval,
  setSelectedStratigraphyTags,
  setIncludeWellboresWithoutStratigraphy,
  resetSelectedStratigraphyOptions,
  updateSelectedAnalyticsParametersNumberRange,
  addSelectedAreaOfInterestBlocks,
  updateSelectedAreaOfInterestBlocks,
  resetSelectedAreaOfInterestBlocks,
  setSiftError,
  clearSiftError,
  setSelectedBackdrop,
  toggleShowStratigraphy,
  toggleShowMap,
  toggleHideWellboreResult,
  setAlignByStratigraphy,
  toggleShowDataWarnings,
  toggleSiftRealDepthModeActive,
  toggleWellSymbols,
} = siftSlice.actions;

function mapValueWithError() {
  return (value: AnalyticsValue) => {
    if (value.higherValue === "" || value.lowerValue === "") {
      return {
        ...value,
        lowerError: value.lowerValue === "",
        higherError: value.higherValue === "",
        errorMessage: "Fields are required",
      };
    }
    return value;
  };
}

export function loadWellboresAndDefaultDepthInterval(
  accessRights: AccessRights
) {
  return async function (dispatch: AppDispatch): Promise<void> {
    try {
      const wellbores = await getWellbores(accessRights);
      dispatch(setWellbores(wellbores));

      const [minDepth, maxDepth] = wellbores.reduce(
        (acc, wb) => [
          Math.min(...wb.cuttingDepths, acc[0]),
          Math.max(...wb.cuttingDepths, acc[1]),
        ],
        [Number.MAX_VALUE, Number.MIN_VALUE]
      );

      const defaultInterval: NumberRange = {
        low: minDepth,
        high: maxDepth,
      };

      dispatch(setDefaultDepthInterval(defaultInterval));
    } catch (err) {
      console.error(
        "Failed to load wellbores, pleace try again by reloading the application. Error: ",
        err
      );
    }
  };
}

export function performSiftSearch() {
  return async function (
    dispatch: AppDispatch,
    getState: () => StoreState
  ): Promise<void> {
    const wellbores = getState().cuttingsInsight.wellbores;

    const pinnedWellbore = wellbores.find(
      (wb) => wb.name === getState().sift.pinnedWellboreName
    );

    if (!pinnedWellbore) {
      throw new Error("Could not retreive pinned Wellbore data");
    }
    const siftParameters = getState().sift.siftParameters;

    if (siftParametersHasErrors(siftParameters)) {
      dispatch(setShowAllErrors());
      return;
    }

    dispatch(setSearchInitiated());

    try {
      const siftResponse = await requestSiftSearch(siftParameters);

      const analyticsOrderMap = getAnalyticsOrderMap(
        siftParameters.selectedAnalyticsParameters
      );

      const results = mapSiftResponseToResultModel(
        siftResponse,
        wellbores,
        pinnedWellbore,
        analyticsOrderMap,
        getState().cuttingsInsight.wellTopsData
      );

      dispatch(
        setSiftResults({
          siftResults: results.siftResult,
          pinnedWellboreResult: results.pinnedWellboreResult,
          selectedStratigraphies: siftParameters.selectedStratigraphyTags.map(
            (tag) => tag.name
          ),
        })
      );
    } catch (error) {
      let message = "Sift search failed.";
      if (error instanceof Error) {
        message = `Sift search failed: ${error.message}`;
      }

      dispatch(
        setSiftError({
          message: message,
          closable: true,
        })
      );
    }
  };
}
function getAnalyticsOrderMap(analyticsSiftGroups: AnalyticsSiftGroup[]) {
  return analyticsSiftGroups
    .map((group) => Object.values(group.analyticsParameters))
    .flat(2)
    .reduce<Record<string, number>>(
      (acc, value, index) =>
        acc[value.outputName] === undefined
          ? { ...acc, [value.outputName]: index }
          : acc,
      {}
    );
}
