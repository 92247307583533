import React, { FC, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { SIDEBAR_MENU_DROPDOWNS, useDropdowns } from "../../../context";
import { useFetchJson, useResizeObserver } from "../../../hooks";
import {
  selectActiveWellbore,
  selectCuttings,
  selectRealDepthModeActive,
} from "../../../store";
import {
  ProcessedWellSymbolsData,
  RenderingConstraints,
  WellDataSymbolsPlotData,
} from "../../../types/types";
import { CasingShoe } from "../../common/icons";
import { WellDataSymbolsFrame } from "../frames/track-frames";
import { TrackContent, TrackFooter, TrackHeader } from "../track";
import { getCasingShoePlotData } from "./getPlotData";
import { WellDataSymbolsChart } from "./WellDataSymbolsChart";

export enum WellDataSymbol {
  CASING_SHOE = "Casing shoe",
}

export const WELL_DATA_SYMBOLS = [WellDataSymbol.CASING_SHOE];

export const WIDTH_OF_WELL_SYMBOLS_TRACK = 30;

interface WellDataSymbolsProps {
  selectedWellDataSymbols: WellDataSymbol[];
  renderingConstraints: RenderingConstraints;
}

export const WellDataSymbolsImpl: FC<WellDataSymbolsProps> = ({
  selectedWellDataSymbols,
  renderingConstraints,
}) => {
  const cuttings = useSelector(selectCuttings);
  const activeWellbore = useSelector(selectActiveWellbore);
  const realDepthModeActive = useSelector(selectRealDepthModeActive);

  const { closeDropdown } = useDropdowns();

  const [size, contentRef] = useResizeObserver<HTMLDivElement>();

  const [chartData, setChartData] = useState<WellDataSymbolsPlotData[]>();

  const yDomain = useMemo(() => {
    if (realDepthModeActive && cuttings && cuttings.length > 0) {
      return {
        min: cuttings[0].depth,
        max: cuttings[cuttings.length - 1].depth,
      };
    }
    return { min: 0, max: cuttings ? cuttings.length - 1 : 0 };
  }, [cuttings, realDepthModeActive]);

  const wellSymbolsData = useFetchJson<ProcessedWellSymbolsData>(
    "/files/wellbore-casing-shoes.json"
  );

  useEffect(() => {
    if (wellSymbolsData && activeWellbore && cuttings) {
      const wellSymbolsDataForWellbore = wellSymbolsData[activeWellbore.name];
      if (wellSymbolsDataForWellbore) {
        const plotData = getCasingShoePlotData(
          wellSymbolsDataForWellbore,
          cuttings
        );
        setChartData(plotData);
      } else {
        setChartData(undefined);
      }
    } else {
      setChartData(undefined);
    }
  }, [activeWellbore, cuttings, wellSymbolsData]);

  return (
    <WellDataSymbolsFrame
      className={"wellbore-symbols"}
      onClick={() => closeDropdown(SIDEBAR_MENU_DROPDOWNS)}
    >
      <TrackHeader>
        <CasingShoe />
      </TrackHeader>
      <TrackContent className="wellbore-symbols__chart-root" ref={contentRef}>
        {size && (
          <WellDataSymbolsChart
            xDomain={selectedWellDataSymbols}
            yDomain={yDomain}
            data={chartData}
            size={size}
            renderingConstraints={renderingConstraints}
            realDepthModeActive={realDepthModeActive}
          />
        )}
      </TrackContent>
      <TrackFooter />
    </WellDataSymbolsFrame>
  );
};

export const WellDataSymbols = React.memo(WellDataSymbolsImpl);
