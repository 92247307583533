import { Options } from "../../../dependencies/openseadragon";
import { Margin } from "../../../types/types";

export const SCALE_BAR_MARGIN: Margin = {
  top: 21,
  bottom: 21,
  left: 20,
  right: 0,
};

export const VIEWER_MARGIN: Margin = {
  top: 20,
  bottom: 20,
  left: 20,
  right: 10,
};

export const OSD_SETTINGS: Options = {
  crossOriginPolicy: "Anonymous",
  constrainDuringPan: false,
  preserveImageSizeOnResize: true,
  autoResize: true,
  visibilityRatio: 0.1,
  zoomPerScroll: 1.1,
  controlsFadeDelay: 1300,
  controlsFadeLength: 700,
  showNavigationControl: false,
};

export const IMAGE_VIEWER_MIN_ZOOM = 0.3;
export const IMAGE_VIEWER_MAX_ZOOM = 10;

export const UI_SPRING_CONFIG = {
  duration: 150,
  clamp: true,
};
