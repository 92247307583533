import { NeutralColors } from "@fluentui/react";

export const MISSING_STRATIGRAPHY_COLOR = NeutralColors.gray50;
export const MISSING_STRATIGRAPHY_NAME = "Undefined";

export const STRATIGRAPHY_NAMES = [
  "NORDLAND GP",
  "HORDALAND GP",
  "BALDER FM",
  "ROGALAND GP",
  "FORTIES FM",
  "LISTA FM",
  "MAUREEN FM",
  "SHETLAND GP",
  "EKOFISK FM",
  "TOR FM",
  "SELE FM",
  "VIDAR FM",
  "VÅLE FM",
  "HOD FM",
  "BLODØKS FM",
  "HIDRA FM",
  "CROMER KNOLL GP",
  "RØDBY FM",
  "SOLA FM",
  "ZECHSTEIN GP",
  "MANDAL FM",
  "TYNE GP",
  "FARSUND FM",
  "VESTLAND GP",
  "ULA FM",
  "NO FORMAL NAME",
  "BRYNE FM",
  "NO GROUP DEFINED",
  "SMITH BANK FM",
  "ÅSGARD FM",
  "ROTLIEGEND GP",
  "TUXEN FM",
  "VADE FM",
  "ANDREW FM",
  "HAUGESUND FM",
  "SKAGERRAK FM",
  "ELDFISK FM",
  "NO DATA",
  "UNDIFFERENTIATED",
  "KUPFERSCHIEFER FM",
  "RAN SANDSTONE UNITS",
  "SANDNES FM",
  "HEGRE GP",
  "FISKEBANK FM",
  "UNDEFINED GP",
  "UTSIRA FM",
  "BASEMENT",
  "GASSUM FM",
  "NAUST FM",
  "TY FM",
  "VIKING GP",
  "DRAUPNE FM",
  "INTRA DRAUPNE FM SS",
  "FLEKKEFJORD FM",
  "BOKNFJORD GP",
  "SAUDA FM",
  "FJERRITSLEV FM",
  "TAU FM",
  "EGERSUND FM",
  "BØRGLUM UNIT",
  "SKADE FM",
  "GRID FM",
  "INTRA BALDER FM SS",
  "HEIMDAL FM",
  "HEATHER FM",
  "HUGIN FM",
  "SLEIPNER FM",
  "HERMOD FM",
  "JORSALFARE FM",
  "KYRRE FM",
  "SVARTE FM",
  "INTRA HEATHER FM SS",
  "TRYGGVASON FM",
  "FRIGG FM",
  "STATFJORD GP",
  "DUNLIN GP",
  "COOK FM",
  "FLADEN GP",
  "RATTRAY FM",
  "MIME FM",
  "MEILE MBR (INFORMAL)",
  "INTRA ÅSGARD FM SS",
  "EIRIKSSON FM",
  "AMUNDSEN FM",
  "HARDRÅDE FM",
  "BRENT GP",
  "DRAKE FM",
  "BURTON FM",
  "NANSEN FM",
  "TARBERT FM",
  "NESS FM",
  "ETIVE FM",
  "RANNOCH FM",
  "BROOM FM",
  "LUNDE FM",
  "OSEBERG FM",
  "RAUDE FM",
  "LOMVI FM",
  "TEIST FM",
  "SOGNEFJORD FM",
  "FENSFJORD FM",
  "KROSSFJORD FM",
  "JOHANSEN FM",
  "ALKE FM",
  "AGAT FM",
  "EGGA FM (INFORMAL)",
  "GREY BEDS (INFORMAL)",
  "SPEKK FM",
  "KAI FM",
  "BRYGGE FM",
  "TARE FM",
  "TANG FM",
  "SPRINGAR FM",
  "NISE FM",
  "KVITNOS FM",
  "LYSING FM",
  "LANGE FM",
  "GARN FM",
  "FANGST GP",
  "RED BEDS (INFORMAL)",
  "ROGN FM",
  "INTRA MELKE FM SS",
  "LYR FM",
  "MOLO FM",
  "MELKE FM",
  "ILE FM",
  "BÅT GP",
  "ROR FM",
  "TOFTE FM",
  "TILJE FM",
  "ÅRE FM",
  "NOT FM",
  "DELFIN FM (INFORMAL)",
  "SOTBAKKEN GP",
  "TORSK FM",
  "NYGRUNNEN GP",
  "KVEITE FM",
  "KOLMULE FM",
  "ADVENTDALEN GP",
  "KNURR FM",
  "HEKKINGEN FM",
  "FUGLEN FM",
  "KAPP TOSCANA GP",
  "STØ FM",
  "NORDMELA FM",
  "TUBÅEN FM",
  "KOLJE FM",
  "FRUHOLMEN FM",
  "KRILL MBR",
  "ALGE MBR",
  "SNADD FM",
  "SASSENDALEN GP",
  "KOBBE FM",
  "KLAPPMYSS FM",
  "HAVERT FM",
  "ØRRET FM",
  "TEMPELFJORDEN GP",
  "RØYE FM",
  "BJARMELAND GP",
  "ULV FM",
  "ØRN FM",
  "GIPSDALEN GP",
  "FALK FM",
  "UGLE FM",
  "BILLEFJORDEN GP",
  "KVITING FM",
  "KRABBE MBR",
  "REKE MBR",
  "AKKAR MBR",
  "ISBJØRN FM",
  "POLARREV FM",
  "TETTEGRAS FM",
  "SOLDOGG FM",
  "BLÆREROT FM",
  "STEINKOBBE FM",
  "KLIPPFISK FM",
];
