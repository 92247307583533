import React, { FC, ReactNode } from "react";
import { Navigate } from "react-router-dom";

export interface PrivateRouteProps {
  authenticated: boolean;
  component: ReactNode;
}

export const PrivateRoute: FC<PrivateRouteProps> = ({
  authenticated,
  component,
}) => {
  return authenticated ? <>{component} </> : <Navigate to="/login" />;
};
