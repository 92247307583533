import React, { FC, Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { LoadingSpinner, PrivateRoute, PublicRoute } from "./components/common";
import useFirebaseAuthentication from "./services/useFirebaseAuthentication";
import useFirebaseRoleListener from "./services/useFirebaseRoleListener";

const HomeWrapper = React.lazy(() => import("./pages/home/Home"));
const Login = React.lazy(() => import("./pages/login/Login"));
const TOS = React.lazy(() => import("./pages/tos/TOS"));
const SiftWrapper = React.lazy(() => import("./pages/sift/Sift"));
const PrivacyPolicy = React.lazy(() => import("./pages/privacy/Privacy"));

const App: FC = () => {
  const { user, loading } = useFirebaseAuthentication();
  const role = useFirebaseRoleListener(user);

  if (user && role !== "data-consumer") {
    return (
      <Suspense
        fallback={
          <LoadingSpinner loading={true} label="Loading application..." />
        }
      >
        <Login checkAuthorization={true} />
      </Suspense>
    );
  } else {
    return (
      <Suspense
        fallback={
          <LoadingSpinner loading={true} label="Loading application..." />
        }
      >
        <LoadingSpinner loading={loading} label="Loading application...">
          <BrowserRouter>
            <Routes>
              <Route
                path="/"
                element={
                  <PrivateRoute
                    authenticated={!!user}
                    component={<HomeWrapper />}
                  />
                }
              />
              <Route
                path="/login"
                element={
                  <PublicRoute authenticated={!!user} component={<Login />} />
                }
              />
              <Route path="/tos" element={<TOS />} />
              <Route path="/privacy" element={<PrivacyPolicy />} />
              <Route
                path="/sift"
                element={
                  <PrivateRoute
                    authenticated={!!user}
                    component={<SiftWrapper />}
                  />
                }
              />
            </Routes>
          </BrowserRouter>
        </LoadingSpinner>
      </Suspense>
    );
  }
};

export default App;
