import {
  DirectionalHint,
  IButtonStyles,
  Icon,
  mergeStyleSets,
  TooltipHost,
} from "@fluentui/react";
import { NeutralColors } from "@fluentui/theme";
import React, { FC } from "react";
import { Breakpoint } from "../../../constants";
import { NavButton } from "../../common/styledFluentComponents";
import { NpdIconButton } from "../../cuttings-insight/wellboreNavigation/NpdIconButton";
// eslint-disable-next-line @typescript-eslint/no-redeclare
import { Icon as Iconify } from "@iconify/react";

const dataNavButtonStyles: IButtonStyles = {
  root: {
    position: "relative",
    height: "72px",
    width: "368px",
    border: "0",
    fontSize: "20px",
    color: NeutralColors.gray160,
    [Breakpoint.S]: {
      width: "224px",
      height: "40px",
      fontSize: "14px",
    },
    [Breakpoint.M]: {
      width: "256px",
      height: "56px",
      fontSize: "16px",
    },
    [Breakpoint.L]: {
      width: "368px",
      height: "72px",
      fontSize: "20px",
    },
  },
  rootChecked: {
    borderRadius: "2px 2px 0px 0px",
    color: "white",
  },
  flexContainer: { justifyContent: "left" },
};

interface WellBoreDropdownProps {
  elementRef: React.RefObject<HTMLButtonElement> | null;
  onClick: () => void;
  checked: boolean;
  title: string;
  showNpdButton: boolean;
  privateIcon?: boolean;
}
const styles = mergeStyleSets({
  arrowIcon: {
    fontSize: 15,
    marginLeft: "auto",
  },
  privateIcon: {
    fontSize: 18,
    marginTop: "10px",
    marginLeft: "9px",
  },
  npdIcon: {
    marginLeft: 16,
  },
});
const WellBoreDropdown: FC<WellBoreDropdownProps> = ({
  elementRef,
  onClick,
  checked,
  title,
  showNpdButton,
  privateIcon,
}) => {
  return (
    <NavButton
      elementRef={elementRef}
      styles={dataNavButtonStyles}
      checked={checked}
      onClick={onClick}
    >
      <span>{title}</span>
      {privateIcon && (
        <TooltipHost
          content={"Private wellbore"}
          directionalHint={DirectionalHint.rightCenter}
        >
          <Iconify
            className={styles.privateIcon}
            icon="fluent:tag-lock-24-regular"
          />
        </TooltipHost>
      )}
      <Icon iconName="ChevronDown" className={styles.arrowIcon} />
      {showNpdButton && (
        <div className={styles.npdIcon}>
          <NpdIconButton />
        </div>
      )}
    </NavButton>
  );
};

export { WellBoreDropdown };
