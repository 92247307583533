export function getValue(
  value: string | undefined,
  fallback: string,
  parseToNumber = true,
  decimalPlaces = 2
): string {
  if (value) {
    if (!parseToNumber) {
      return value;
    }
    value = value.replace(",", ".");
    const number = toFloat(value, fallback);
    if (typeof number === "number") {
      return number.toFixed(decimalPlaces);
    }
  }
  return fallback;
}

export function getRawValue(
  value: string | undefined,
  fallback: string | null
): number | string | null {
  if (value) {
    value = value.replace(",", ".");
    if (isNaN(+value)) {
      return value;
    }
    return toFloat(value, fallback);
  }
  return fallback;
}

export function toFloat<T>(
  s: string,
  fallback: T,
  invalidValues?: number[]
): number | T {
  let result: number | T;
  try {
    result = parseFloat(s);
    if (invalidValues?.includes(result) || isNaN(result)) {
      result = fallback;
    }
  } catch (e) {
    result = fallback;
  }
  return result;
}
