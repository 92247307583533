import {
  ISliderProps,
  ISliderStyles,
  mergeStyleSets,
  Slider as FluentSlider,
} from "@fluentui/react";
import { NeutralColors } from "@fluentui/theme";
import React, { FC, useMemo } from "react";
import { Colors } from "../../../constants";

const slidesStyles: Partial<ISliderStyles> = {
  root: {
    selectors: {
      "&.ms-Slider-disabled": {
        ".ms-Slider-inactive": {
          background: NeutralColors.gray40,
        },
        ".ms-Slider-active": {
          background: NeutralColors.gray90,
        },
        ".ms-Slider-thumb": {
          border: `2px solid ${NeutralColors.gray90}`,
        },
      },
    },
  },
  slideBox: {
    selectors: {
      "&:hover .ms-Slider-active": {
        background: Colors.primary,
      },
      "&:hover .ms-Slider-inactive": {
        background: Colors.lighter,
      },
      "&:hover .ms-Slider-thumb": {
        borderColor: Colors.dark,
      },
      "&:focus .ms-Slider-active": {
        background: Colors.primary,
      },
      "&:focus .ms-Slider-inactive": {
        background: Colors.lighter,
      },
      "&:focus .ms-Slider-thumb": {
        borderColor: Colors.dark,
      },
      "&:focus::after": {
        outline: `1px solid ${Colors.primary} !important`,
      },
    },
  },
  titleLabel: {
    fontSize: "14px",
    color: NeutralColors.gray130,
  },
};

export const Slider: FC<ISliderProps> = ({ styles, ...restProps }) => {
  const mergedStyles = useMemo(
    () => mergeStyleSets(slidesStyles, styles),
    [styles]
  );
  return <FluentSlider styles={mergedStyles} {...restProps} />;
};
