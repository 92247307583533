import React, { FC, useCallback } from "react";
import { useSelector } from "react-redux";
import {
  isDefaultZoomArea,
  selectCuttings,
  selectSelectedWellbore,
  selectSelectedZoomArea,
  setActiveZoomAreaWithPadding,
  setSelectedCutting,
  useAppDispatch,
} from "../../../store";
import { WellTopsPlotData } from "../../../types/types";
import { StratigraphyChart } from "../../common/stratigraphyChart/StratigraphyChart";
import { TrackContentProps } from "../track";

interface WellboreTopsChartProps extends TrackContentProps {
  data: WellTopsPlotData[] | undefined;
  realDepthModeActive: boolean;
  minDepth: number;
  maxDepth: number;
}

export const WellboreTopsChart: FC<WellboreTopsChartProps> = ({
  size,
  renderingConstraints,
  data,
  realDepthModeActive,
  minDepth,
  maxDepth,
}) => {
  const dispatch = useAppDispatch();
  const cuttings = useSelector(selectCuttings);
  const selectedWellbore = useSelector(selectSelectedWellbore);
  const selectedZoomArea = useSelector(selectSelectedZoomArea);

  /**
   * Set cutting based on the cutting having the nearest depth after the input
   */
  const setNearestCuttingByDepth = useCallback(
    (data: WellTopsPlotData) => {
      if (cuttings && isDefaultZoomArea(selectedZoomArea, cuttings.length)) {
        const closestCutting = cuttings.reduce((nearestCutting, cutting) => {
          const isCuttingWithinLowerBound = data.top <= cutting.depth;
          const isCuttingCloser =
            Math.abs(data.top - cutting.depth) <
            Math.abs(data.top - nearestCutting.depth);
          if (isCuttingWithinLowerBound && isCuttingCloser) {
            return cutting;
          }
          return nearestCutting;
        });
        dispatch(setSelectedCutting(closestCutting));
      }
    },
    [cuttings, dispatch, selectedZoomArea]
  );

  /**
   * Set zoom area
   */
  const setZoomArea = useCallback(
    (data: WellTopsPlotData) => {
      dispatch(
        setActiveZoomAreaWithPadding({
          topIdx: data.numberOfCuttingsAbove,
          bottomIdx:
            data.numberOfCuttingsAbove + data.numberOfCuttingsInFormation - 1,
        })
      );
    },
    [dispatch]
  );

  return (
    <StratigraphyChart
      size={size}
      data={data}
      wellboreName={selectedWellbore?.name ?? ""}
      onClick={setNearestCuttingByDepth}
      onDoubleClick={setZoomArea}
      outerTrackMargin={renderingConstraints.outerTrackMargin}
      minDepth={minDepth}
      maxDepth={maxDepth}
      useLinearChart={realDepthModeActive}
    />
  );
};
