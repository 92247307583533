import { ElementsConfig } from "../../types";

export const xrfSampleInfoConfig: ElementsConfig = {
  "Sample Information": {
    elements: [
      { originalName: "weight", outputName: "Dry weight (g)" },
      { originalName: "bagQuality", outputName: "Sample bag quality (1,2,3)" },
      { originalName: "boxQuality", outputName: "Sample box quality (1,2,3)" },
      { originalName: "recovery", outputName: "Sample recovery (1,2,3)" },
    ],
  },
};

export const xrfConfig: ElementsConfig = {
  "Major Elements Oxides": {
    unit: "wt.%",
    elements: [
      {
        originalName: "MgO",
        outputName: "MgO (Magnesium oxide)",
        information: {
          wikipediaLinks: ["https://en.wikipedia.org/wiki/Magnesium_oxide"],
        },
      },
      {
        originalName: "Al2O3",
        outputName: "Al2O3 (Aluminium oxide)",
        information: {
          wikipediaLinks: ["https://en.wikipedia.org/wiki/Aluminium_oxide"],
        },
      },
      {
        originalName: "SiO2",
        outputName: "SiO2 (Silicon dioxide)",
        information: {
          wikipediaLinks: ["https://en.wikipedia.org/wiki/Silicon_dioxide"],
        },
      },
      {
        originalName: "K2O",
        outputName: "K2O (Potassium oxide)",
        information: {
          wikipediaLinks: ["https://en.wikipedia.org/wiki/Potassium_oxide"],
        },
      },
      {
        originalName: "CaO",
        outputName: "CaO (Calcium oxide)",
        information: {
          wikipediaLinks: ["https://en.wikipedia.org/wiki/Calcium_oxide"],
        },
      },
      {
        originalName: "TiO2",
        outputName: "TiO2 (Titanium dioxide)",
        information: {
          wikipediaLinks: ["https://en.wikipedia.org/wiki/Titanium_dioxide"],
        },
      },
      {
        originalName: "Fe2O3",
        outputName: "Fe2O3 (Iron(III) oxide)",
        information: {
          wikipediaLinks: ["https://en.wikipedia.org/wiki/Iron(III)_oxide"],
        },
      },
    ],
  },
  "Major Elements": {
    unit: "ppm",
    elements: [
      {
        originalName: "Fe",
        outputName: "Fe (Iron)",
        information: { wikipediaLinks: ["https://en.wikipedia.org/wiki/Iron"] },
      },
      {
        originalName: "Ti",
        outputName: "Ti (Titanium)",
        information: {
          wikipediaLinks: ["https://en.wikipedia.org/wiki/Titanium"],
        },
      },
      {
        originalName: "Ca",
        outputName: "Ca (Calcium)",
        information: {
          wikipediaLinks: ["https://en.wikipedia.org/wiki/Calcium"],
        },
      },
      {
        originalName: "K",
        outputName: "K (Potassium)",
        information: {
          wikipediaLinks: ["https://en.wikipedia.org/wiki/Potassium"],
        },
      },
      {
        originalName: "Al",
        outputName: "Al (Aliminium)",
        information: {
          wikipediaLinks: ["https://en.wikipedia.org/wiki/Aluminium"],
        },
      },
      {
        originalName: "Si",
        outputName: "Si (Silicon)",
        information: {
          wikipediaLinks: ["https://en.wikipedia.org/wiki/Silicon"],
        },
      },
      {
        originalName: "Mg",
        outputName: "Mg (Magnesium)",
        information: {
          wikipediaLinks: ["https://en.wikipedia.org/wiki/Magnesium"],
        },
      },
    ],
  },
  Balance: {
    unit: "ppm",
    elements: [
      {
        originalName: "Balance",
        outputName: "Balance",
      },
    ],
  },
  "Trace and Rare Earth Elements": {
    unit: "ppm",
    elements: [
      {
        originalName: "Sb",
        outputName: "Sb (Antimony)",
        information: {
          wikipediaLinks: ["https://en.wikipedia.org/wiki/Antimony"],
        },
      },
      {
        originalName: "Sn",
        outputName: "Sn (Tin)",
        information: { wikipediaLinks: ["https://en.wikipedia.org/wiki/Tin"] },
      },
      {
        originalName: "Cd",
        outputName: "Cd (Cadmium)",
        information: {
          wikipediaLinks: ["https://en.wikipedia.org/wiki/Cadmium"],
        },
      },
      {
        originalName: "Pd",
        outputName: "Pd (Palladium)",
        information: {
          wikipediaLinks: ["https://en.wikipedia.org/wiki/Palladium"],
        },
      },
      {
        originalName: "Ag",
        outputName: "Ag (Silver)",
        information: {
          wikipediaLinks: ["https://en.wikipedia.org/wiki/Silver"],
        },
      },
      {
        originalName: "Mo",
        outputName: "Mo (Molybdenum)",
        information: {
          wikipediaLinks: ["https://en.wikipedia.org/wiki/Molybdenum"],
        },
      },
      {
        originalName: "Nb",
        outputName: "Nb (Niobium)",
        information: {
          wikipediaLinks: ["https://en.wikipedia.org/wiki/Niobium"],
        },
      },
      {
        originalName: "Th",
        outputName: "Th (Thorium)",
        information: {
          wikipediaLinks: ["https://en.wikipedia.org/wiki/Thorium"],
        },
      },
      {
        originalName: "Zr",
        outputName: "Zr (Zirconium)",
        information: {
          wikipediaLinks: ["https://en.wikipedia.org/wiki/Zirconium"],
        },
      },
      {
        originalName: "Sr",
        outputName: "Sr (Strontium)",
        information: {
          wikipediaLinks: ["https://en.wikipedia.org/wiki/Strontium"],
        },
      },
      {
        originalName: "U",
        outputName: "U (Uranium)",
        information: {
          wikipediaLinks: ["https://en.wikipedia.org/wiki/Uranium"],
        },
      },
      {
        originalName: "Rb",
        outputName: "Rb (Rubidium)",
        information: {
          wikipediaLinks: ["https://en.wikipedia.org/wiki/Rubidium"],
        },
      },
      {
        originalName: "As",
        outputName: "As (Arsenic)",
        information: {
          wikipediaLinks: ["https://en.wikipedia.org/wiki/Arsenic"],
        },
      },
      {
        originalName: "Se",
        outputName: "Se (Selenium)",
        information: {
          wikipediaLinks: ["https://en.wikipedia.org/wiki/Selenium"],
        },
      },
      {
        originalName: "Au",
        outputName: "Au (Gold)",
        information: { wikipediaLinks: ["https://en.wikipedia.org/wiki/Gold"] },
      },
      {
        originalName: "Pb",
        outputName: "Pb (Lead)",
        information: { wikipediaLinks: ["https://en.wikipedia.org/wiki/Lead"] },
      },
      {
        originalName: "W",
        outputName: "W (Wolfram)",
        information: {
          wikipediaLinks: ["https://en.wikipedia.org/wiki/Tungsten"],
        },
      },
      {
        originalName: "Zn",
        outputName: "Zn (Zinc)",
        information: { wikipediaLinks: ["https://en.wikipedia.org/wiki/Zinc"] },
      },
      {
        originalName: "Cu",
        outputName: "Cu (Copper)",
        information: {
          wikipediaLinks: ["https://en.wikipedia.org/wiki/Copper"],
        },
      },
      {
        originalName: "Ni",
        outputName: "Ni (Nickel)",
        information: {
          wikipediaLinks: ["https://en.wikipedia.org/wiki/Nickel"],
        },
      },
      {
        originalName: "Co",
        outputName: "Co (Cobalt)",
        information: {
          wikipediaLinks: ["https://en.wikipedia.org/wiki/Cobalt"],
        },
      },
      {
        originalName: "Cr",
        outputName: "Cr (Chromium)",
        information: {
          wikipediaLinks: ["https://en.wikipedia.org/wiki/Chromium"],
        },
      },
      {
        originalName: "V",
        outputName: "V (Vanadium)",
        information: {
          wikipediaLinks: ["https://en.wikipedia.org/wiki/Vanadium"],
        },
      },
      {
        originalName: "Ba",
        outputName: "Ba (Barium)",
        information: {
          wikipediaLinks: ["https://en.wikipedia.org/wiki/Barium"],
        },
      },
      {
        originalName: "Cl",
        outputName: "Cl (Chlorine)",
        information: {
          wikipediaLinks: ["https://en.wikipedia.org/wiki/Chlorine"],
        },
      },
      {
        originalName: "Mn",
        outputName: "Mn (Manganese)",
        information: {
          wikipediaLinks: ["https://en.wikipedia.org/wiki/Manganese"],
        },
      },
      {
        originalName: "S",
        outputName: "S (Sulfur)",
        information: {
          wikipediaLinks: ["https://en.wikipedia.org/wiki/Sulfur"],
        },
      },
      {
        originalName: "P",
        outputName: "P (Phosphorus)",
        information: {
          wikipediaLinks: ["https://en.wikipedia.org/wiki/Phosphorus"],
        },
      },
    ],
  },
};
