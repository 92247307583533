import {
  DetailsList as FluentDetailsList,
  IDetailsListProps,
  IDetailsListStyleProps,
  IDetailsListStyles,
  IStyleFunctionOrObject,
  mergeStyleSets,
} from "@fluentui/react";
import { NeutralColors } from "@fluentui/theme";
import React, { FC, useMemo } from "react";

const detailsListStyles: IStyleFunctionOrObject<
  IDetailsListStyleProps,
  IDetailsListStyles
> = {
  root: {
    selectors: {
      ".ms-GroupSpacer": {
        display: "none !important",
      },
      ".ms-DetailsRow": {
        background: NeutralColors.gray30,
      },
      ".ms-DetailsRow:hover": {
        background: NeutralColors.gray40,
      },
      ".ms-DetailsRow.is-selected": {
        background: `${NeutralColors.gray40} !important`,
      },
      ".ms-DetailsRow:active": {
        background: `${NeutralColors.gray50} !important`,
      },
      ".ms-GroupHeader:hover": {
        background: NeutralColors.gray40,
      },
      ".ms-GroupHeader.is-selected": {
        background: `${NeutralColors.gray40} !important`,
        fontWeight: 600,
      },
      '.ms-GroupHeader[aria-expanded="true"]': {
        fontWeight: 600,
      },
      ".ms-GroupHeader:active": {
        background: `${NeutralColors.gray50} !important`,
      },
      ".ms-GroupHeader-expand:hover": {
        background: NeutralColors.gray50,
      },
      ".ms-Check::before": {
        background: NeutralColors.gray30,
      },
      ".ms-Check-circle": {
        color: NeutralColors.gray160,
      },
      ".ms-Check-check": {
        color: NeutralColors.gray160,
      },
      ".ms-GroupHeader-check": {
        opacity: "1 !important",
      },
      ".ms-List-cell": {
        minHeight: "0 !important",
      },
    },
  },
};

export const DetailsList: FC<IDetailsListProps> = ({
  styles,
  ...restProps
}) => {
  const mergedStyles = useMemo(
    () => mergeStyleSets(detailsListStyles, styles),
    [styles]
  );
  return <FluentDetailsList styles={mergedStyles} {...restProps} />;
};
