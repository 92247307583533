import {
  ActionButton as FluentActionButton,
  DefaultButton as FluentDefaultButton,
  DirectionalHint,
  IButtonProps,
  IButtonStyles,
  mergeStyleSets,
  PrimaryButton,
  TooltipHost,
} from "@fluentui/react";
import { useId } from "@fluentui/react-hooks";
import { Depths, NeutralColors } from "@fluentui/theme";
import React, { FC, useMemo } from "react";
import { Colors } from "../../../constants";

const activeState = {
  background: Colors.primary,
  color: NeutralColors.white,
};

const grayButtonStyles: Partial<IButtonStyles> = {
  root: {
    background: NeutralColors.gray30,
    color: NeutralColors.gray160,
  },
  rootHovered: {
    background: NeutralColors.gray40,
    color: NeutralColors.gray160,
  },
  rootPressed: {
    background: NeutralColors.gray50,
    color: NeutralColors.gray160,
  },
  rootDisabled: {
    background: NeutralColors.gray50,
    color: NeutralColors.gray90,
    border: `1px solid ${NeutralColors.gray50}`,
  },
  labelDisabled: {
    color: NeutralColors.gray90,
  },
};

export const navButtonStyles: Partial<IButtonStyles> = {
  root: {
    background: NeutralColors.gray30,
    color: NeutralColors.gray190,
    boxShadow: Depths.depth16,
  },
  rootHovered: {
    background: NeutralColors.gray40,
    color: NeutralColors.gray160,
  },
  rootPressed: {
    ...activeState,
  },
  rootExpanded: {
    ...activeState,
  },
  rootChecked: {
    ...activeState,
  },
  rootCheckedHovered: {
    ...activeState,
  },
  flexContainer: {
    position: "relative",
  },
};
export const actionButtonStyles: Partial<IButtonStyles> = {
  root: {
    background: NeutralColors.gray30,
    color: Colors.primaryBlack,
    border: `1px solid rgba(138,136,132,1)`,
    paddingLeft: 16,
    paddingRight: 16,
    fontWeight: 600,
    height: 32,
  },
  icon: {
    color: Colors.primaryBlack,
  },
  iconHovered: {
    color: Colors.primaryBlack,
  },
  iconPressed: {
    color: NeutralColors.white,
  },
  rootHovered: {
    background: NeutralColors.gray40,
    color: Colors.primaryBlack,
  },
  rootPressed: {
    ...activeState,
  },
  rootExpanded: {
    ...activeState,
  },
  rootChecked: {
    ...activeState,
  },
  rootCheckedHovered: {
    ...activeState,
  },
  flexContainer: {
    position: "relative",
  },
  rootDisabled: {
    background: NeutralColors.gray50,
    color: NeutralColors.gray90,
    border: `1px solid ${NeutralColors.gray50}`,
  },
  labelDisabled: {
    color: NeutralColors.gray90,
  },
};

export const GrayButton: FC<IButtonProps> = ({ styles, ...restProps }) => {
  const mergedStyles = useMemo(
    () => mergeStyleSets(grayButtonStyles, styles),
    [styles]
  );
  return <FluentDefaultButton styles={mergedStyles} {...restProps} />;
};

export const NavButton: FC<IButtonProps> = ({ styles, ...restProps }) => {
  const mergedStyles = useMemo(
    () => mergeStyleSets(navButtonStyles, styles),
    [styles]
  );
  return <FluentDefaultButton styles={mergedStyles} {...restProps} />;
};

export const ActionButton: FC<IButtonProps> = ({
  styles,
  children,
  ...restProps
}) => {
  const mergedStyles = useMemo(
    () => mergeStyleSets(actionButtonStyles, styles),
    [styles]
  );
  return (
    <FluentActionButton styles={mergedStyles} {...restProps}>
      {children}
    </FluentActionButton>
  );
};

export const TooltippedPrimaryButton: FC<
  IButtonProps & { toolTipContent: string | JSX.Element | JSX.Element[] }
> = ({ toolTipContent, ...restProps }) => {
  // Use useId() to ensure that the ID is unique on the page.
  const tooltipId = useId("tooltip");

  return (
    <TooltipHost
      content={toolTipContent}
      id={tooltipId}
      directionalHint={DirectionalHint.rightCenter}
    >
      <PrimaryButton aria-describedby={tooltipId} {...restProps} />
    </TooltipHost>
  );
};
