export const pick =
  <O extends object, K extends keyof O = keyof O, R = Pick<O, K>>(
    ...keys: K[]
  ) =>
  (obj: O): R => {
    const result: Partial<Pick<O, K>> = {};
    for (const key of keys) {
      result[key] = obj[key];
    }
    return result as R;
  };

export const pluck =
  <O extends object, K extends keyof O = keyof O>(key: K) =>
  (obj: O): O[K] => {
    return obj[key];
  };
