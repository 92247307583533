import { useCallback, useState } from "react";
import debounce from "lodash/debounce";

export function useDebouncedState<T>(
  value: T,
  delay: number
): [T, (value: T) => void] {
  const [val, set] = useState<T>(value);

  // eslint-disable-next-line
  const throttledSetter = useCallback(
    debounce((newValue: T) => {
      set(newValue);
    }, delay),
    []
  );

  return [val, throttledSetter];
}
