import {
  Optional,
  ScatterPlotData,
  StackedPlotData,
  StructuredCategory,
  StructuredData,
} from "../../../../types";
import { toFloat } from "../../../../utils";
import { valueAscendingSort } from "../../../../utils/sorting";

const VALUE_OUTSIDE_VISIBLE_AREA = -1000;

export function getScatterPlotData(
  data: Optional<StructuredData>,
  selectedElements: string[]
): ScatterPlotData[] {
  const _plotData: ScatterPlotData[] = [];

  if (selectedElements.length && data) {
    data.forEach((dataForDepth, depth) => {
      const flattendElements = getFlattendElements(dataForDepth);

      /**
       * Build plot data points and sort data points such that points with higher value is plotted on top of points with lower value.
       */
      const plotDataForDepth: ScatterPlotData[] = selectedElements
        .map((element) => ({
          depth: depth,
          key: element,
          value: toFloat(flattendElements[element], VALUE_OUTSIDE_VISIBLE_AREA),
          stratigraphy: dataForDepth[0]?.stratigraphy,
        }))
        .sort((a, b) => valueAscendingSort(a.value, b.value));

      _plotData.push(...plotDataForDepth);
    });
  }

  return _plotData;
}

export function getStackedBarPlotData(
  data: Optional<StructuredData>,
  selectedElements: string[]
): StackedPlotData[] {
  const _plotData: StackedPlotData[] = [];

  if (selectedElements.length && data) {
    data.forEach((dataForDepth, depth) => {
      const flattendElements = getFlattendElements(dataForDepth);
      const elements: Partial<StackedPlotData> = {};
      selectedElements.forEach((element) => {
        elements[element] = toFloat(flattendElements[element], 0);
      });

      _plotData.push({
        depth: depth,
        stratigraphy: dataForDepth[0]?.stratigraphy,
        ...elements,
      });
    });
  }
  return _plotData;
}

export function getFlattendElements(
  structuredCategories: StructuredCategory[]
): Record<string, string> {
  let result: Record<string, string> = {};

  structuredCategories.forEach((structuredCategory) => {
    const categoryElementValues = getElementsKeyValuePair(
      structuredCategory.elements
    );
    const categoryGroupValues = getElementsKeyValuePair(
      structuredCategory.groups
    );
    result = { ...result, ...categoryElementValues, ...categoryGroupValues };
    structuredCategory.groups.forEach((group) => {
      const groupElementValues = getElementsKeyValuePair(group.elements);
      result = { ...result, ...groupElementValues };
    });
  });

  return result;
}

function getElementsKeyValuePair(elements: { key: string; value: string }[]) {
  return elements.reduce<Record<string, string>>((acc, element) => {
    acc[element.key] = element.value;
    return acc;
  }, {});
}
