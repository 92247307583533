import { DependencyList, useEffect, useState } from "react";
import { PromiseStatus } from "../../constants";

export function usePromise<T>(
  resolve: () => Promise<T>,
  ...deps: DependencyList
): [T | undefined, PromiseStatus] {
  const [status, setStatus] = useState(PromiseStatus.IDLE);
  const [result, setResult] = useState<T>();

  useEffect(() => {
    (async function () {
      try {
        setStatus(PromiseStatus.LOADING);
        const res = await resolve();
        setResult(res);
        setStatus(PromiseStatus.SUCCESS);
      } catch (e) {
        setStatus(PromiseStatus.ERROR);
      }
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...deps, resolve]);

  return [result, status];
}
