import firebase from "firebase/compat/app";
import "firebase/compat/functions";
import { groupBy } from "lodash";
import {
  analyticsConfigs,
  ANALYTICS_ELEMENT_BY_ORIGINAL_NAME,
  getElementsInGroup,
  UNITS,
} from "../config/analytics";
import { FIREBASE_FUNCTION_REGION } from "../config/common";
import { AnalyticsName } from "../constants";
import {
  ELEMENT_QUERY_PARAM_SUFFIX,
  GROUP_QUERY_PARAM_SUFFIX,
  TAB_QUERY_PARAM_NAME,
} from "../store/initialStateFromUrl";
import { JsonWithoutNull, Optional } from "../types";
import {
  AnalyticsQueryGroup,
  SiftRequest,
  SiftSearchResponse,
} from "../types/sift/searchRequestTypes";
import {
  AnalyticsSiftGroup,
  GroupType,
  SiftParameters,
  WellboreSiftResult,
} from "../types/sift/storeTypes";
import {
  encodeUrlParam,
  getDistanceBetweenWellbores,
  getMissingDataWarnings,
  removeDuplicates,
  toFloat,
} from "../utils";
import { ChartType } from "./../constants";
import { PLOTS_QUERY_PARAM_NAME } from "./../store/initialStateFromUrl";
import { ElementResult } from "./../types/sift/storeTypes";
import { ProcessedTops, StoredPlot, Wellbore } from "./../types/types";

const SIFT_SEARCH_FUNTION_NAME = "siftSearch";

export async function requestSiftSearch(
  siftParameters: SiftParameters
): Promise<Optional<SiftSearchResponse[]>> {
  const siftRequest = mapSiftParametersToRequest(siftParameters);

  if (siftRequest) {
    const functions = firebase.app().functions(FIREBASE_FUNCTION_REGION);

    const siftSearch = functions.httpsCallable(SIFT_SEARCH_FUNTION_NAME);

    const response = await siftSearch(siftRequest);

    const data = response.data as SiftSearchResponse[];

    return data;
  }
}

export const mapSiftResponseToResultModel = (
  response: Optional<SiftSearchResponse[]>,
  wellbores: Wellbore[],
  pinnedWellbore: Wellbore,
  analyticsOrderMap: Record<string, number>,
  stratigraphyData: Optional<ProcessedTops>
): {
  siftResult: WellboreSiftResult[];
  pinnedWellboreResult: WellboreSiftResult;
} => {
  const defaultPinnedWellboreResult: WellboreSiftResult = {
    wellbore: pinnedWellbore,
    hits: 0,
    elementResults: [],
    minDepth: Math.min(...pinnedWellbore.cuttingDepths),
    maxDepth: Math.max(...pinnedWellbore.cuttingDepths),
    hidden: false,
    cuttingsInsightUrl: getCuttingsInsightUrl([], pinnedWellbore),
    filtered: false,
    missingDataWarnings: getMissingDataWarnings(
      pinnedWellbore.cuttingDepths,
      stratigraphyData,
      pinnedWellbore.name
    ),
  };

  if (!response || response.length === 0) {
    return {
      siftResult: [],
      pinnedWellboreResult: defaultPinnedWellboreResult,
    };
  }

  let pinnedWellboreResult: Optional<WellboreSiftResult> = undefined;

  const wellboreResults = response.reduce<WellboreSiftResult[]>(
    (acc, wellboreResponse) => {
      const resultWellbore = wellbores.find(
        (wb) => wb.name === wellboreResponse.wellbore
      );

      if (!resultWellbore) {
        return acc;
      }

      // Map to elementResult and sort by original search order
      const elementResults = getElementResultWithMineralOutputName(
        wellboreResponse
      ).sort((a, b) => analyticsOrderMap[a.name] - analyticsOrderMap[b.name]);

      const cuttingsInsightUrl = getCuttingsInsightUrl(
        elementResults,
        resultWellbore
      );

      const wellboreSiftResult: WellboreSiftResult = {
        wellbore: resultWellbore,
        elementResults: elementResults,
        hits: getNumberOfHits(wellboreResponse),
        minDepth: Math.min(...resultWellbore.cuttingDepths),
        maxDepth: Math.max(...resultWellbore.cuttingDepths),
        hidden: false,
        cuttingsInsightUrl: cuttingsInsightUrl,
        filtered: false,
        missingDataWarnings: getMissingDataWarnings(
          resultWellbore.cuttingDepths,
          stratigraphyData,
          resultWellbore.name
        ),
      };

      if (resultWellbore.name === pinnedWellbore.name) {
        pinnedWellboreResult = wellboreSiftResult;
        return acc;
      }

      acc.push({
        ...wellboreSiftResult,
        distanceFromPinnedWellbore: getDistanceBetweenWellbores(
          resultWellbore,
          pinnedWellbore
        ),
      });

      return acc;
    },
    []
  );

  return {
    siftResult: wellboreResults,
    pinnedWellboreResult: pinnedWellboreResult ?? defaultPinnedWellboreResult,
  };
};

const toAnalyticsQueryGroup = (
  group: AnalyticsSiftGroup
): AnalyticsQueryGroup => {
  const queryParameters = Object.entries(group.analyticsParameters).flatMap(
    ([analyticsType, analyticsValues]) =>
      analyticsValues.map((parameter) => ({
        parameter: parameter.originalName,
        min: toFloat(parameter.lowerValue ?? "", 0),
        max: toFloat(parameter.higherValue ?? "", 0),
      }))
  );
  return {
    parameterQueries: queryParameters,
    type: group.type === GroupType.AND ? "AND" : "OR",
    previousRelation: group.previusRelation
      ? group.previusRelation === GroupType.AND
        ? "AND"
        : "OR"
      : undefined,
  };
};

const mapSiftParametersToRequest = (
  siftParameters: SiftParameters
): Optional<SiftRequest> => {
  if (
    !siftParameters.selectedDepthInterval ||
    !siftParameters.selectedAnalyticsParameters
  ) {
    return;
  }

  const siftRequest: SiftRequest = {
    depthRange: {
      top: siftParameters.selectedDepthInterval.low,
      bottom: siftParameters.selectedDepthInterval.high,
    },
    stratigraphies: siftParameters.selectedStratigraphyTags.map(
      (tag) => tag.key as string
    ),
    blocks: siftParameters.selectedAreaOfInterestBlocks,
    analyticsGroups: siftParameters.selectedAnalyticsParameters.map(
      toAnalyticsQueryGroup
    ),
    includeWellboresWithoutStratigraphy:
      siftParameters.includeWellboresWithoutStratigraphy,
  };
  return siftRequest;
};

function getNumberOfHits(searchResponse: SiftSearchResponse) {
  const uniqueDepths = removeDuplicates(
    searchResponse.elementResult.flatMap((result) =>
      result.depthResults.map((result) => result.depth)
    )
  );
  const numberOfHits = uniqueDepths.length;
  return numberOfHits;
}

function getElementResultWithMineralOutputName(
  searchResponse: SiftSearchResponse
) {
  return searchResponse.elementResult
    .map((elementResult): ElementResult => {
      const mappedOutputName =
        ANALYTICS_ELEMENT_BY_ORIGINAL_NAME[elementResult.name]?.outputName;
      const unit = UNITS[elementResult.type][mappedOutputName];
      return {
        ...elementResult,
        name: mappedOutputName ?? elementResult.name,
        unit: unit,
      };
    })
    .sort((a, b) => a.name.localeCompare(b.name));
}

function getCuttingsInsightUrl(
  elementResults: ElementResult[],
  wellbore: Wellbore
) {
  let cuttingsInsightUrl = `${window.location.origin}/?well=${wellbore.name}`;

  const elementResultWithMostHits = elementResults.reduce<
    Optional<ElementResult>
  >(
    (max, current) =>
      max && max.depthResults.length > current.depthResults.length
        ? max
        : current,
    undefined
  );

  const elementsByType = groupBy(elementResults, (result) => result.type);

  for (const [type, elements] of Object.entries(elementsByType)) {
    const analyticsType = type as AnalyticsName;
    const elementNames: string[] = [];
    const groupNames: string[] = [];

    for (const element of elements) {
      // Determine if element is group element or child element
      const elementsInGroup = getElementsInGroup(
        analyticsConfigs[analyticsType],
        element.name
      );

      elementsInGroup.length > 0
        ? groupNames.push(element.name)
        : elementNames.push(element.name);
    }

    if (elementNames.length > 0) {
      const elementParamName = `${analyticsType}${ELEMENT_QUERY_PARAM_SUFFIX}`;
      const encodedElements = encodeUrlParam(elementNames);
      cuttingsInsightUrl += `&${elementParamName}=${encodedElements}`;
    }

    if (groupNames.length > 0) {
      const groupParamName = `${analyticsType}${GROUP_QUERY_PARAM_SUFFIX}`;
      const encodedGroups = encodeUrlParam(groupNames);
      cuttingsInsightUrl += `&${groupParamName}=${encodedGroups}`;
    }

    if (elementResultWithMostHits?.type === analyticsType) {
      const MockedStoredPlots: StoredPlot[] = [
        {
          t: analyticsType,
          c: ChartType.STACKED_BAR,
          e: elementNames,
          g: groupNames,
        },
      ];
      const encodedPlotsdata = encodeUrlParam(
        MockedStoredPlots as unknown as JsonWithoutNull,
        true
      );

      cuttingsInsightUrl += `&${TAB_QUERY_PARAM_NAME}=${analyticsType}&${PLOTS_QUERY_PARAM_NAME}=${encodedPlotsdata}`;
    }
  }

  return cuttingsInsightUrl;
}
