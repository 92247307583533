import { configureStore } from "@reduxjs/toolkit";
import { enableMapSet } from "immer";
import { useDispatch } from "react-redux";
import { Observable } from "rxjs";
import { analyticsReducer } from "../components/cuttings-insight/dataAnalyticsPane/analyticsSlice";
import { cuttingsInsightReducer } from "./cuttingsInsightSlice";
import { siftReducer } from "./siftSlice";

enableMapSet();

export const store = configureStore({
  reducer: {
    analytics: analyticsReducer,
    cuttingsInsight: cuttingsInsightReducer,
    sift: siftReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      //TODO: Fix the serializable issue at some point
      serializableCheck: false,
    }),
});

type Store = typeof store;
export type StoreState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();

export const storeState$ = getState$(store);

function getState$(store: Store) {
  return new Observable<StoreState>((observer) => {
    // emit the current state as first value:
    observer.next(store.getState());
    const unsubscribe = store.subscribe(function () {
      // emit on every new state changes
      observer.next(store.getState());
    });
    return unsubscribe;
  });
}
