import React, { useCallback, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { TooltipType } from "../constants";
import { selectSelectedWellbore } from "../store";
import { PinnableTooltipData } from "../types/types";

type PinnedTooltipsContextType = {
  pinnedTooltips: PinnableTooltipData[];
  setActiveTooltip: (tooltipData: PinnableTooltipData | undefined) => void;
  removePinnedTooltip: (index: number) => void;
  clearPinnedTooltipsOfType: (type: TooltipType) => void;
};

type PinnedTooltipsProviderProps = {
  children: React.ReactNode;
};

const PinnedTooltipsContext = React.createContext<PinnedTooltipsContextType>({
  pinnedTooltips: [],
  setActiveTooltip: () => null,
  removePinnedTooltip: () => null,
  clearPinnedTooltipsOfType: () => null,
});

export const PinnedTooltipsProvider: React.ComponentType<
  PinnedTooltipsProviderProps
> = ({ children }: PinnedTooltipsProviderProps) => {
  const [pinnedTooltips, setPinnedTooltips] = React.useState<
    PinnableTooltipData[]
  >([]);
  const activeTooltip = useRef<PinnableTooltipData | undefined>();
  const selectedWellbore = useSelector(selectSelectedWellbore);

  const removePinnedTooltip = (index: number) => {
    setPinnedTooltips((prevState) => [
      ...prevState.slice(0, index),
      ...prevState.slice(index + 1),
    ]);
  };

  const clearPinnedTooltipsOfType = useCallback((type: TooltipType) => {
    setPinnedTooltips((prevTooltips) =>
      prevTooltips.filter((tooltip) => tooltip.tooltipType !== type)
    );
  }, []);

  const setActiveTooltip = useCallback(
    (tooltipData: PinnableTooltipData | undefined) => {
      activeTooltip.current = tooltipData;
    },
    []
  );

  useEffect(() => {
    setPinnedTooltips([]);
  }, [selectedWellbore]);

  useEffect(() => {
    const keydownHandler = (event: KeyboardEvent) => {
      if (event.key === "p" && activeTooltip.current) {
        setPinnedTooltips((prevState) => {
          if (!activeTooltip.current) {
            return prevState;
          }
          const pinnedTooltipData: PinnableTooltipData = {
            ...activeTooltip.current,
            tooltipElementId: uuidv4(),
          };

          return [...prevState, pinnedTooltipData];
        });
      } else if (event.key === "c") {
        setPinnedTooltips([]);
      }
    };

    document.addEventListener("keydown", keydownHandler, { capture: true });

    return () => {
      document.removeEventListener("keydown", keydownHandler);
    };
  }, []);

  return (
    <PinnedTooltipsContext.Provider
      value={{
        pinnedTooltips,
        setActiveTooltip,
        removePinnedTooltip,
        clearPinnedTooltipsOfType,
      }}
    >
      {children}
    </PinnedTooltipsContext.Provider>
  );
};

export const usePinnedTooltips = (): PinnedTooltipsContextType =>
  React.useContext(PinnedTooltipsContext);
