import "firebase/compat/firestore";

import firebase from "firebase/compat/app";
import { DEFAULT_WELLBORE_NAME } from "../config/common";
import { Optional } from "../types";
import { AccessRights, Wellbore } from "../types/types";

export async function getWellbores(
  accessRights: AccessRights
): Promise<Wellbore[]> {
  const company: string = accessRights.company ?? "";
  const wellboreGroup: string[] = accessRights.wellboreGroup ?? [];

  if (!accessRights.company) return await fetchWellbores("public", []);

  const [publicWellbores, privateWellbores] = [
    await fetchWellbores("public", []),
    await fetchWellbores(company, wellboreGroup),
  ];
  return [...privateWellbores, ...publicWellbores];
}

export async function fetchWellbores(
  company: string,
  wellboreGroup: string[]
): Promise<Wellbore[]> {
  const wellboresSnapshot = await firebase
    .firestore()
    .collection("wellbores")
    .where(`company.${company}`, "==", true)
    .where("wellboreGroup", "in", ["", ...wellboreGroup])
    .get();

  const wellbores = wellboresSnapshot.docs.map(
    (doc) =>
      ({
        id: doc.id,
        ...doc.data(),
      } as Wellbore)
  );

  return wellbores;
}

export async function getDefaultWellbore(
  wellboreName: Optional<string>,
  userCompany: string,
  wellboreGroup: string[]
): Promise<Wellbore> {
  const querySnapshotPublic = await firebase
    .firestore()
    .collection("wellbores")
    .where("name", "==", wellboreName ?? DEFAULT_WELLBORE_NAME)
    .where("company.public", "==", true)
    .where("wellboreGroup", "in", [""])
    .limit(1)
    .get();

  let defaultDocument = querySnapshotPublic.docs[0];

  if (!defaultDocument?.exists) {
    const querySnapshotPrivate = await firebase
      .firestore()
      .collection("wellbores")
      .where("name", "==", wellboreName)
      .where(`company.${userCompany}`, "==", true)
      .where("wellboreGroup", "in", ["", ...wellboreGroup])
      .limit(1)
      .get();

    defaultDocument = querySnapshotPrivate.docs[0];
  }

  if (!defaultDocument?.exists) {
    const alternativeSnapshot = await firebase
      .firestore()
      .collection("wellbores")
      .where("company.public", "==", true)
      .where("wellboreGroup", "in", [""])
      .limit(1)
      .get();
    defaultDocument = alternativeSnapshot.docs[0];
  }
  const wellWithDocId = {
    id: defaultDocument.id,
    ...defaultDocument.data(),
  } as Wellbore;

  return wellWithDocId;
}
