import {
  ILinkProps,
  ILinkStyles,
  Link as FluentLink,
  mergeStyleSets,
} from "@fluentui/react";
import { NeutralColors } from "@fluentui/theme";
import React, { FC, useMemo } from "react";
import { Colors } from "../../../constants";

const linkStyles: Partial<ILinkStyles> = {
  root: {
    color: Colors.primary,
    selectors: {
      "&:hover": {
        color: Colors.dark,
      },
      "&:focus": {
        color: Colors.dark,
        outline: `1px solid ${Colors.primary} !important`,
        boxShadow: "none !important",
        textDecoration: "underline",
      },
      "&.is-disabled": {
        color: NeutralColors.gray50,
      },
    },
  },
};

export const Link: FC<ILinkProps> = ({ styles, ...restProps }) => {
  const mergedStyles = useMemo(
    () => mergeStyleSets(linkStyles, styles),
    [styles]
  );
  return <FluentLink styles={mergedStyles} {...restProps} />;
};
