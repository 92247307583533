import React, { FC } from "react";
import { RenderingConstraints, Size } from "../../../types/types";

export const TRACK_MARGIN = 5;
export const HEAD_FOOT_HEIGHT = 27;
export const THUMB_HEIGHT = 16;

export interface TrackContentProps {
  renderingConstraints: RenderingConstraints;
  size: Size;
}

export const TRACK_CONTAINER_CLASS_NAME = "track";

export const TrackHeader: FC = ({ children }) => (
  <div className="track__header">{children}</div>
);
export const TrackFooter: FC = ({ children }) => (
  <div className="track__footer">{children}</div>
);

export const TrackContent = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ children, className, ...restProps }, ref) => (
  <div
    {...restProps}
    className={`${className ? className : ""} track__content`}
    ref={ref}
  >
    {children}
  </div>
));
