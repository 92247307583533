import {
  Cutting,
  ProcessedCasingShoes,
  WellDataSymbolsPlotData,
} from "../../../types/types";

const MAX_DISTANCE_BETWEEN_CASING_SHOE_AND_CUTTING = 10;

export function getCasingShoePlotData(
  wellSymbolsData: ProcessedCasingShoes[],
  cuttings: Pick<Cutting, "depth">[]
): WellDataSymbolsPlotData[] {
  return wellSymbolsData
    .map((casingShoeData) => {
      const indexOfClosestCutting = cuttings.reduce(
        (indexOfClosest, c, index) => {
          const distanceToCasingShoe = Math.abs(c.depth - casingShoeData.depth);
          if (
            distanceToCasingShoe <= MAX_DISTANCE_BETWEEN_CASING_SHOE_AND_CUTTING
          ) {
            if (
              indexOfClosest > -1 &&
              distanceToCasingShoe <
                Math.abs(cuttings[indexOfClosest].depth - casingShoeData.depth)
            ) {
              return index;
            } else if (indexOfClosest === -1) {
              return index;
            }
          }
          return indexOfClosest;
        },
        -1
      );

      return {
        type: casingShoeData.type,
        diameter: casingShoeData.diameter,
        depth: casingShoeData.depth,
        holeDiameter: casingShoeData.holeDiameter,
        cuttingIndex: indexOfClosestCutting,
        cuttingDepth:
          indexOfClosestCutting > -1
            ? cuttings[indexOfClosestCutting].depth
            : undefined,
      };
    })
    .filter((plotData) => plotData.cuttingIndex > -1);
}
