import { ANALYTICS_ORIGINAL_NAMES } from ".";
import { AnalyticsName } from "../../constants";
import {
  ElementsConfig,
  ElementsGroup,
  SimpleAnalyticsElement,
} from "../../types";

export function getUnits(
  elementsConfig: ElementsConfig
): Record<string, string> {
  const result: Record<string, string> = {};

  Object.entries(elementsConfig).forEach(([category, categoryData]) => {
    categoryData.elements.forEach((element) => {
      result[element.outputName] = categoryData.unit || element.unit || "";
    });
    categoryData?.groups?.forEach((group) => {
      result[group.outputName] = categoryData.unit || group.unit || "";
      group.elements.forEach((element) => {
        result[element.outputName] =
          categoryData.unit || group.unit || element.unit || "";
      });
    });
  });

  return result;
}

export const getParameterOriginalName = (
  element: string,
  activeTab: AnalyticsName
): string => {
  const tabSpecificOriginalName = ANALYTICS_ORIGINAL_NAMES[activeTab][element];
  return tabSpecificOriginalName;
};

export function getOriginalNames(
  elementsConfig: ElementsConfig
): Record<string, string> {
  const result: Record<string, string> = {};

  Object.entries(elementsConfig).forEach(([category, categoryData]) => {
    categoryData.elements.forEach((element) => {
      result[element.outputName] = element.originalName;
    });
    categoryData?.groups?.forEach((group) => {
      result[group.outputName] = group.originalName;
      group.elements.forEach((element) => {
        result[element.outputName] = element.originalName;
      });
    });
  });

  return result;
}

export function getSelections(
  elementsConfig: ElementsConfig,
  analyticsName: AnalyticsName
): SimpleAnalyticsElement[] {
  const selections: SimpleAnalyticsElement[] = [];

  Object.entries(elementsConfig).forEach(([category, categoryData]) => {
    categoryData.elements.forEach((element) => {
      if (!element.hide) {
        selections.push({
          originalName: element.originalName,
          outputName: element.outputName,
          unit: categoryData.unit || element.unit,
          analyticsName,
        });
      }
    });
    categoryData?.groups?.forEach((group) => {
      selections.push({
        originalName: group.originalName,
        outputName: group.outputName,
        unit: categoryData.unit || group.unit,
        analyticsName,
      });
      group.elements.forEach((element) => {
        if (!element.hide) {
          selections.push({
            originalName: element.originalName,
            outputName: element.outputName,
            unit: categoryData.unit || group.unit || element.unit,
            analyticsName,
          });
        }
      });
    });
  });

  return selections;
}

export function getElementsInGroups(
  config: ElementsConfig,
  groupKeys: string[]
): string[] {
  const selectedGroups: ElementsGroup[] = [];
  for (const category of Object.values(config)) {
    const _selectedGroups = category.groups?.filter((g) =>
      groupKeys.includes(g.outputName)
    );
    if (_selectedGroups) selectedGroups.push(..._selectedGroups);
  }
  return selectedGroups.flatMap((g) => g.elements).map((e) => e.outputName);
}

export function getElementsInGroup(
  config: ElementsConfig,
  groupKey: string
): string[] {
  let group: ElementsGroup | undefined = undefined;

  for (const category of Object.values(config)) {
    if (category.groups) {
      for (const g of category.groups) {
        if (g.outputName === groupKey) {
          group = g;
          break;
        }
      }
    }
    if (group) {
      break;
    }
  }

  return group ? group.elements.map((e) => e.outputName) : [];
}

export function getGroupOfElement(
  config: ElementsConfig,
  elementKey: string
): string | undefined {
  for (const category of Object.values(config)) {
    if (category.groups) {
      for (const group of category.groups) {
        if (group.elements.map((e) => e.outputName).includes(elementKey)) {
          return group.outputName;
        }
      }
    }
  }
}
