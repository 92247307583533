import { ImageAdjustments } from "../types";

export const MINIMUM_ZOOM_AREA_SIZE = 10;

export const WELLBORE_DEPTH_RANGE_WARNING_THRESHOLD = 20;

export const DEFAULT_IMAGE_TYPES = ["W_1_ACR_WBC", "W_1"];

export const DEFAULT_WELLBORE_NAME = "34/6-4";

export const DEFAULT_ADJUSTMENTS: ImageAdjustments = {
  brightness: 0,
  contrast: 0,
  invert: false,
  exposure: 0,
  saturation: 0,
};

export const DEV_ORIGIN = "https://dev.wellify.ai";

export const FIREBASE_FUNCTION_REGION = "europe-west1";
