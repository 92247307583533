import ls from "localstorage-slim";
import { useEffect } from "react";
import { distinctUntilChanged, map } from "rxjs/operators";
import {
  REAL_DEPTH_MODE_ACTIVE_LOCAL_STORAGE_KEY,
  SELECTED_SIFT_BACKDROP_LOCAL_STORAGE_KEY,
  SELECTED_WELLBORE_LOCAL_STORAGE_KEY,
  SELECTED_WELLTOPS_LOCAL_STORAGE_KEY,
  SHOW_DATA_WARNING_TRACK_LOCAL_STORAGE_KEY,
  SHOW_SIFT_DATA_WARNINGS_LOCAL_STORAGE_KEY,
  SHOW_SIFT_STRATIGRAPHY_LOCAL_STORAGE_KEY,
  SIFT_REAL_DEPTH_MODE_ACTIVE_LOCAL_STORAGE_KEY,
} from "./initialStateFromLocalStorage";
import { storeState$ } from "./store";

/**
 * Setup subscriptions to update localStorage
 */
export function useSyncStateToLocalStorage(): void {
  useEffect(() => {
    const wellboreSubscription = storeState$
      .pipe(
        map((state) => state.cuttingsInsight.selectedWellbore),
        distinctUntilChanged()
      )
      .subscribe((selectedWellbore) => {
        ls.set(
          SELECTED_WELLBORE_LOCAL_STORAGE_KEY,
          selectedWellbore?.name ?? ""
        );
      });

    const wellTopsSubscription = storeState$
      .pipe(
        map((state) => state.cuttingsInsight.selectedWellTops),
        distinctUntilChanged()
      )
      .subscribe((selectedWellTops) => {
        ls.set(SELECTED_WELLTOPS_LOCAL_STORAGE_KEY, selectedWellTops ?? "");
      });

    const siftBackdropSubscription = storeState$
      .pipe(
        map((state) => state.sift.selectedBackdrop),
        distinctUntilChanged()
      )
      .subscribe((selectedbackdrop) => {
        ls.set(SELECTED_SIFT_BACKDROP_LOCAL_STORAGE_KEY, selectedbackdrop);
      });

    const realDepthModeSubscription = storeState$
      .pipe(
        map((state) => state.cuttingsInsight.realDepthModeActive),
        distinctUntilChanged()
      )
      .subscribe((realDepthModeActive) => {
        ls.set(REAL_DEPTH_MODE_ACTIVE_LOCAL_STORAGE_KEY, realDepthModeActive);
      });

    const dataWarningSubscription = storeState$
      .pipe(
        map((state) => state.cuttingsInsight.showDataWarningTrack),
        distinctUntilChanged()
      )
      .subscribe((showDataWarningTrack) => {
        ls.set(SHOW_DATA_WARNING_TRACK_LOCAL_STORAGE_KEY, showDataWarningTrack);
      });

    return () => {
      wellboreSubscription.unsubscribe();
      wellTopsSubscription.unsubscribe();
      siftBackdropSubscription.unsubscribe();
      realDepthModeSubscription.unsubscribe();
      dataWarningSubscription.unsubscribe();
    };
  }, []);
}

export function useSyncSiftStateToLocalStorage(): void {
  useEffect(() => {
    const siftBackdropSubscription = storeState$
      .pipe(
        map((state) => state.sift.selectedBackdrop),
        distinctUntilChanged()
      )
      .subscribe((selectedbackdrop) => {
        ls.set(SELECTED_SIFT_BACKDROP_LOCAL_STORAGE_KEY, selectedbackdrop);
      });

    const siftStratigraphySubscription = storeState$
      .pipe(
        map((state) => state.sift.showStratigraphy),
        distinctUntilChanged()
      )
      .subscribe((showStratigraphy) => {
        ls.set(SHOW_SIFT_STRATIGRAPHY_LOCAL_STORAGE_KEY, showStratigraphy);
      });

    const siftDataWarningSubscription = storeState$
      .pipe(
        map((state) => state.sift.showDataWarnings),
        distinctUntilChanged()
      )
      .subscribe((showDataWarnings) => {
        ls.set(SHOW_SIFT_DATA_WARNINGS_LOCAL_STORAGE_KEY, showDataWarnings);
      });

    const siftRealDepthModeSubscription = storeState$
      .pipe(
        map((state) => state.sift.realDepthModeActive),
        distinctUntilChanged()
      )
      .subscribe((realDepthModeActive) => {
        ls.set(
          SIFT_REAL_DEPTH_MODE_ACTIVE_LOCAL_STORAGE_KEY,
          realDepthModeActive
        );
      });

    return () => {
      siftBackdropSubscription.unsubscribe();
      siftStratigraphySubscription.unsubscribe();
      siftDataWarningSubscription.unsubscribe();
      siftRealDepthModeSubscription.unsubscribe();
    };
  }, []);
}
