import React, { FC, ReactNode } from "react";
import { Navigate, RouteProps } from "react-router-dom";

export interface PublicRouteProps extends RouteProps {
  authenticated: boolean;
  component: ReactNode;
}

export const PublicRoute: FC<PublicRouteProps> = ({
  authenticated,
  component,
}) => {
  return authenticated ? <Navigate to="/" replace /> : <>{component} </>;
};
