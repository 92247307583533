import { createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";
import { clamp, debounce } from "lodash";
import isEqual from "lodash/isEqual";
import {
  DEFAULT_IMAGE_TYPES,
  DEFAULT_WELLBORE_NAME,
  MINIMUM_ZOOM_AREA_SIZE,
} from "../config/common";
import { WellTopsName } from "../constants";
import { WELL_TOPS_URL } from "../environment";
import { getCuttingsForWellbore } from "../services/cuttingsService";
import { getDefaultWellbore, getWellbores } from "../services/wellboreService";
import {
  AccessRights,
  Cutting,
  MissingDataCollection,
  Optional,
  ProcessedTops,
  Wellbore,
  ZoomArea,
} from "../types";
import { getMissingDataWarnings } from "../utils";
import {
  initialLocalStorageSelectedWelltops,
  initialLocalStorageWellboreName,
  initialRealDepthModeActiveFromLocalStorage,
  initialShowDataWarningTrackFromLocalStorage,
} from "./initialStateFromLocalStorage";
import {
  initialSelectedWellTops,
  initialUrlCuttingDepth,
  initialUrlImageA,
  initialUrlWellboreName,
  initialUrlWellboreNpdId,
} from "./initialStateFromUrl";
import { AppDispatch, StoreState } from "./store";

type DepthToIndex = { [depth: number]: number };

export type CuttingsInsightSliceState = {
  // Wellbores and cuttings data
  wellbores: Wellbore[];
  selectedWellbore: Optional<Wellbore>;
  activeWellbore: Optional<Wellbore>;
  cuttings: Optional<Cutting[]>;
  activeCuttings: Optional<Cutting[]>;
  depths: number[];
  depthIndexes: DepthToIndex;
  selectedCutting: Optional<Cutting>;
  selectedImageType: Optional<string>;
  selectedImageType2: Optional<string>;
  // Sync selected cutting across tabs
  syncSelectedCutting: boolean;
  refIndex: Optional<number>;
  indexDiff: Optional<number>;
  // Well tops
  wellTopsData: Optional<ProcessedTops>;
  selectedWellTops: Optional<WellTopsName>;
  // Zoom area functionality
  selectedZoomArea: Optional<ZoomArea>;
  activeZoomArea: Optional<ZoomArea>;

  // warning track functionality
  wellboreDataWarnings: MissingDataCollection[];
  showDataWarningTrack: boolean;
  realDepthModeActive: boolean;
};

const cuttingsInsightSlice = createSlice({
  name: "cuttingsInsight",
  initialState: {
    wellbores: [],
    activeWellbore: undefined,
    selectedWellbore: undefined,
    cuttings: undefined,
    activeCuttings: undefined,
    depths: [],
    depthIndexes: {},
    selectedCutting: undefined,
    selectedImageType: undefined,
    selectedImageType2: undefined,
    syncSelectedCutting: false,
    refIndex: undefined,
    indexDiff: undefined,
    wellTopsData: undefined,
    selectedWellTops:
      initialLocalStorageSelectedWelltops ?? initialSelectedWellTops,
    selectedZoomArea: undefined,
    activeZoomArea: undefined,
    wellboreDataWarnings: [],
    showDataWarningTrack: initialShowDataWarningTrackFromLocalStorage,
    realDepthModeActive: initialRealDepthModeActiveFromLocalStorage,
  } as CuttingsInsightSliceState,
  reducers: {
    /**
     * Reducers related to: wellbores and cuttings
     */
    setWellbores: (state, action: PayloadAction<Wellbore[]>) => {
      state.wellbores = action.payload;
    },
    setSelectedWellbore: (state, action: PayloadAction<Wellbore>) => {
      state.selectedWellbore = action.payload;
    },
    setActiveWellbore: (
      state,
      action: PayloadAction<{
        wellbore: Wellbore;
        cuttings: Cutting[];
        cuttingDepth?: number;
        imageA?: string;
      }>
    ) => {
      const { wellbore, cuttings, cuttingDepth, imageA } = action.payload;

      state.selectedWellbore = wellbore;
      state.activeWellbore = wellbore;

      // setting selected wellbore also require setting new cuttings
      state.cuttings = cuttings;
      state.activeCuttings = cuttings;
      state.depths = cuttings.map((c) => c.depth);
      state.depthIndexes = cuttings.reduce<DepthToIndex>((acc, c, i) => {
        acc[c.depth] = i;
        return acc;
      }, {});

      // setting new cuttings also require setting new selected cutting
      state.selectedCutting =
        cuttingDepth === undefined
          ? cuttings[0]
          : cuttings.find((c) => c.depth === cuttingDepth) ?? cuttings[0];

      // setting new selected cutting also require setting image types
      const defaultImageType =
        imageA ?? getDefaultImageType(state.selectedCutting); // Todo: make more robust

      state.selectedImageType = defaultImageType;
      state.selectedImageType2 = defaultImageType;

      const defaultZoomArea: ZoomArea = {
        topIdx: 0,
        bottomIdx: cuttings.length - 1,
      };

      state.selectedZoomArea = defaultZoomArea;
      state.activeZoomArea = defaultZoomArea;

      // setting selected wellbore also require checking for possible missing data warnings
      const missingDataWarnings = getMissingDataWarnings(
        cuttings.map((cutting) => cutting.depth),
        state.wellTopsData,
        wellbore.name
      );

      state.wellboreDataWarnings = missingDataWarnings;
    },
    setSelectedCutting: (state, action: PayloadAction<Cutting>) => {
      setSelectedCuttingToState(state, action.payload);
    },
    setSelectedImageType: (state, action: PayloadAction<string>) => {
      if (state.selectedCutting) {
        const selectedImageTypeExistForCutting =
          state.selectedCutting.imageReferences
            .map((r) => r.type)
            .includes(action.payload);

        if (selectedImageTypeExistForCutting) {
          state.selectedImageType = action.payload;
        }
      }
    },
    setSelectedImageType2: (state, action: PayloadAction<string>) => {
      if (state.selectedCutting) {
        const selectedImageTypeExistForCutting =
          state.selectedCutting.imageReferences
            .map((r) => r.type)
            .includes(action.payload);

        if (selectedImageTypeExistForCutting) {
          state.selectedImageType2 = action.payload;
        }
      }
    },
    /**
     * Reducers related to: synching cutting updates accross windows
     */
    toggleSyncSelectedCutting: (state) => {
      state.syncSelectedCutting = !state.syncSelectedCutting;
    },
    setRefIndex: (state) => {
      if (state.selectedCutting) {
        state.refIndex = state.depthIndexes[state.selectedCutting.depth];
        state.indexDiff = 0;
      }
    },
    setIndexDiff: (state, action: PayloadAction<number>) => {
      if (
        state.syncSelectedCutting &&
        state.cuttings &&
        state.refIndex !== undefined &&
        state.indexDiff !== undefined
      ) {
        state.indexDiff = action.payload;
        state.selectedCutting =
          state.cuttings[
            clamp(
              state.refIndex + state.indexDiff,
              Math.max(0, state.selectedZoomArea?.topIdx ?? 0),
              Math.min(
                state.cuttings.length - 1,
                state.selectedZoomArea?.bottomIdx ?? state.cuttings.length - 1
              )
            )
          ];
      }
    },
    /**
     * Reducers related to: Well Tops
     */
    setWellTops: (state, action: PayloadAction<Optional<ProcessedTops>>) => {
      state.wellTopsData = action.payload;
    },
    setSelectedWellTops: (state, action: PayloadAction<WellTopsName>) => {
      state.selectedWellTops =
        state.selectedWellTops === action.payload ? undefined : action.payload;
    },

    /**
     * Reducers related to: Zoom area functionality
     */
    setSelectedZoomArea: (state, action: PayloadAction<Optional<ZoomArea>>) => {
      state.activeZoomArea = action.payload;
    },
    setActiveZoomArea: (state, action: PayloadAction<Optional<ZoomArea>>) => {
      if (action.payload) {
        updateSelectedCuttingToFitZoomArea(state, action.payload);
      }
      state.selectedZoomArea = action.payload;
      state.activeCuttings = action.payload
        ? state.cuttings?.slice(
            action.payload.topIdx,
            action.payload.bottomIdx + 1
          )
        : state.cuttings;
      if (!isEqual(state.selectedZoomArea, state.activeZoomArea)) {
        state.activeZoomArea = action.payload;
      }
    },
    resetZoomArea: (state) => {
      if (state.cuttings) {
        const defaultZoomArea: ZoomArea = {
          topIdx: 0,
          bottomIdx: state.cuttings.length - 1,
        };

        state.activeCuttings = state.cuttings;
        state.selectedZoomArea = defaultZoomArea;
        if (!isEqual(state.selectedZoomArea, state.activeZoomArea)) {
          state.activeZoomArea = defaultZoomArea;
        }
      }
    },
    setActiveZoomAreaWithPadding: (
      state,
      action: PayloadAction<Optional<ZoomArea>>
    ) => {
      let zoomArea = action.payload;
      if (zoomArea && state.cuttings) {
        const maxIndex = state.cuttings.length - 1;
        const CUTTINGS_PADDING = 2;
        zoomArea.topIdx = Math.max(0, zoomArea.topIdx - CUTTINGS_PADDING);
        zoomArea.bottomIdx = Math.min(
          zoomArea.bottomIdx + CUTTINGS_PADDING,
          maxIndex
        );
        if (!isZoomAreaWithinMinimumSize(zoomArea)) {
          zoomArea = adjustZoomAreaToFitMinSize(zoomArea, maxIndex);
        }

        updateSelectedCuttingToFitZoomArea(state, zoomArea);
      }
      state.activeCuttings = zoomArea
        ? state.cuttings?.slice(zoomArea.topIdx, zoomArea.bottomIdx + 1)
        : state.cuttings;
      state.selectedZoomArea = zoomArea;
      if (!isEqual(state.selectedZoomArea, state.activeZoomArea)) {
        state.activeZoomArea = zoomArea;
      }
    },
    toggleShowWarningTrack: (state) => {
      state.showDataWarningTrack = !state.showDataWarningTrack;
    },
    toggleRealDepthModeActive: (state) => {
      state.realDepthModeActive = !state.realDepthModeActive;
    },
  },
});

export const cuttingsInsightReducer = cuttingsInsightSlice.reducer;

export const {
  setWellbores,
  setSelectedWellbore,
  setActiveWellbore,
  setSelectedCutting,
  setSelectedImageType,
  setSelectedImageType2,
  toggleSyncSelectedCutting,
  setRefIndex,
  setIndexDiff,
  setSelectedWellTops,
  setActiveZoomArea,
  resetZoomArea,
  setActiveZoomAreaWithPadding,
  toggleShowWarningTrack,
  toggleRealDepthModeActive,
} = cuttingsInsightSlice.actions;

function updateSelectedCuttingToFitZoomArea(
  state: Draft<CuttingsInsightSliceState>,
  zoomArea: ZoomArea
) {
  if (state.selectedCutting && state.cuttings) {
    const selectedCuttingIndex =
      state.depthIndexes[state.selectedCutting.depth];
    if (!isCuttingIndexWithinZoomArea(selectedCuttingIndex, zoomArea)) {
      const selectedCutting =
        state.cuttings[
          clamp(selectedCuttingIndex, zoomArea.topIdx, zoomArea.bottomIdx)
        ];
      setSelectedCuttingToState(state, selectedCutting);
    }
  }
}

function setSelectedCuttingToState(
  state: Draft<CuttingsInsightSliceState>,
  selectedCutting: Draft<Cutting>
) {
  state.selectedCutting = selectedCutting;

  // Update indexDiff when syncSelectedCutting is true
  if (
    state.syncSelectedCutting &&
    state.refIndex !== undefined &&
    state.cuttings !== undefined
  ) {
    state.indexDiff =
      state.depthIndexes[state.selectedCutting.depth] - state.refIndex;
  }

  if (state.selectedImageType) {
    const selectedImageTypeExistForCutting = selectedCutting.imageReferences
      .map((r) => r.type)
      .includes(state.selectedImageType);

    if (!selectedImageTypeExistForCutting) {
      state.selectedImageType = getDefaultImageType(selectedCutting);
    }
  }
}

/**
 * Thunks
 */
export function loadWellbores(accessRights: AccessRights) {
  return async function (
    dispatch: AppDispatch,
    getState: () => StoreState
  ): Promise<void> {
    if (getState().cuttingsInsight.wellbores.length === 0) {
      try {
        const wellbores = await getWellbores(accessRights);
        dispatch(setWellbores(wellbores));
      } catch (err) {
        console.error(
          "Failed to load wellbores, please try again by reloading the application. Error: ",
          err
        );
      }
    }
  };
}

export function loadInitialWellbore() {
  return async function (dispatch: AppDispatch): Promise<void> {
    try {
      let userCompany = "public";
      if (localStorage.company) userCompany = JSON.parse(localStorage.company);

      let wellboreGroup = [];
      if (localStorage.wellboreGroup)
        wellboreGroup = JSON.parse(localStorage.wellboreGroup);

      const defaultWellboreName =
        getDefaultWellboreName() ?? DEFAULT_WELLBORE_NAME;

      const decodedDefaultWellboreName =
        decodeURIComponent(defaultWellboreName);

      const wellbore = await getDefaultWellbore(
        decodedDefaultWellboreName,
        userCompany,
        wellboreGroup
      );

      if (wellbore) {
        const cuttings = await getCuttingsForWellbore(wellbore.id);
        dispatch(
          setActiveWellbore({
            wellbore: wellbore,
            cuttings: cuttings,
            cuttingDepth: initialUrlCuttingDepth,
            imageA: initialUrlImageA,
          })
        );
      }
    } catch (err) {
      console.error(
        "Failed to load initial wellbore, please try again by reloading the application. Error: ",
        err
      );
    }
  };
}

function getDefaultWellboreName() {
  if (initialUrlWellboreName) {
    return initialUrlWellboreName;
  }
  if (initialUrlWellboreNpdId) {
    return initialUrlWellboreNpdId;
  }
  if (initialLocalStorageWellboreName) {
    return initialLocalStorageWellboreName;
  }
  return undefined;
}

export function loadNewWellbore(newWellbore: Wellbore) {
  return async function (dispatch: AppDispatch): Promise<void> {
    setSelectedWellbore(newWellbore);
    try {
      const cuttings = await getCuttingsForWellbore(newWellbore.id);

      dispatch(
        setActiveWellbore({
          wellbore: newWellbore,
          cuttings: cuttings,
        })
      );
    } catch (err) {
      console.error(
        "Failed to load initial wellbore, pleace try again by reloading the application. Error: ",
        err
      );
    }
  };
}

export function fetchWellTopsData() {
  return function (dispatch: AppDispatch, getState: () => StoreState) {
    if (!getState().cuttingsInsight.wellTopsData) {
      return fetch(WELL_TOPS_URL)
        .then((res) => res.json())
        .then((res) => dispatch(cuttingsInsightSlice.actions.setWellTops(res)))
        .catch((e) => {
          dispatch(cuttingsInsightSlice.actions.setWellTops(undefined));
        });
    }
  };
}

const debouncedSetActiveZoomArea = debounce(
  (dispatch: AppDispatch, zoomArea: Optional<ZoomArea>) => {
    dispatch(setActiveZoomArea(zoomArea));
  },
  300
);

const setSelectedZoomArea = cuttingsInsightSlice.actions.setSelectedZoomArea;

export function updateActiveZoomArea(zoomArea: Optional<ZoomArea>) {
  return function (dispatch: AppDispatch, getState: () => StoreState): void {
    const activeZoomArea = getState().cuttingsInsight.activeZoomArea;
    if (!isEqual(activeZoomArea, zoomArea)) {
      dispatch(setSelectedZoomArea(zoomArea));
      debouncedSetActiveZoomArea(dispatch, zoomArea);
    }
  };
}

/**
 * Helpers
 */
function getDefaultImageType(cutting: Cutting) {
  for (const imageType of DEFAULT_IMAGE_TYPES) {
    const defaultImage = cutting.imageReferences.find(
      (imageRef) => imageRef.type === imageType
    );
    if (defaultImage) {
      return defaultImage.type;
    }
  }
  // Use first image of cutting if not in default list
  return cutting.imageReferences[0].type;
}

function isZoomAreaWithinMinimumSize(zoomArea: ZoomArea) {
  const lengthOfCurrentZoomArea = zoomArea.bottomIdx - zoomArea.topIdx + 1;
  return lengthOfCurrentZoomArea >= MINIMUM_ZOOM_AREA_SIZE;
}

export function isCuttingIndexWithinZoomArea(
  index: number,
  zoomArea: ZoomArea
): boolean {
  return zoomArea.topIdx <= index && index <= zoomArea.bottomIdx;
}

function adjustZoomAreaToFitMinSize(
  zoomArea: ZoomArea,
  maxIndex: number
): ZoomArea {
  const LengthOfZoomArea = zoomArea.bottomIdx - zoomArea.topIdx + 1;
  const indexesToExpand = Math.ceil(
    (MINIMUM_ZOOM_AREA_SIZE - LengthOfZoomArea) / 2
  );
  const bottomIndexExpanded = zoomArea.bottomIdx + indexesToExpand;

  const topIndexExpanded = zoomArea.topIdx - indexesToExpand;

  let topIndex = clamp(topIndexExpanded, 0, zoomArea.topIdx);

  let bottomIndex = clamp(bottomIndexExpanded, zoomArea.bottomIdx, maxIndex);

  // If there is moves left we do not need to have equal adjustments on top / bottom, therefore reducing moves by one
  const topMovesLeft = topIndex - topIndexExpanded - 1;
  const bottomMovesLeft = bottomIndexExpanded - bottomIndex - 1;

  if (topMovesLeft > 0) {
    bottomIndex = Math.min(bottomIndex + topMovesLeft, maxIndex);
  }

  if (bottomMovesLeft > 0) {
    topIndex = Math.max(topIndex - bottomMovesLeft, 0);
  }

  return { topIdx: topIndex, bottomIdx: bottomIndex };
}
