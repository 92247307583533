export const blocks = [
  "1/2",
  "1/3",
  "1/5",
  "1/6",
  "1/9",
  "2/1",
  "2/2",
  "2/3",
  "2/4",
  "2/5",
  "2/6",
  "2/7",
  "2/8",
  "2/9",
  "2/10",
  "2/11",
  "2/12",
  "3/1",
  "3/2",
  "3/3",
  "3/4",
  "3/5",
  "3/6",
  "3/7",
  "3/8",
  "3/9",
  "4/1",
  "4/2",
  "4/3",
  "4/4",
  "4/5",
  "5/1",
  "5/2",
  "6/3",
  "7/1",
  "7/2",
  "7/3",
  "7/4",
  "7/5",
  "7/6",
  "7/7",
  "7/8",
  "7/9",
  "7/10",
  "7/11",
  "7/12",
  "8/1",
  "8/2",
  "8/3",
  "8/4",
  "8/5",
  "8/6",
  "8/7",
  "8/8",
  "8/9",
  "8/10",
  "8/11",
  "8/12",
  "9/1",
  "9/2",
  "9/3",
  "9/4",
  "9/5",
  "9/6",
  "9/7",
  "9/8",
  "9/9",
  "9/10",
  "9/11",
  "9/12",
  "10/1",
  "10/2",
  "10/3",
  "10/4",
  "10/5",
  "10/6",
  "10/7",
  "10/8",
  "10/9",
  "10/10",
  "10/11",
  "10/12",
  "11/1",
  "11/2",
  "11/3",
  "11/4",
  "11/5",
  "11/6",
  "11/7",
  "11/8",
  "11/9",
  "11/10",
  "11/11",
  "11/12",
  "12/4",
  "12/5",
  "12/6",
  "12/7",
  "12/8",
  "12/9",
  "12/10",
  "13/4",
  "13/5",
  "15/2",
  "15/3",
  "15/5",
  "15/6",
  "15/8",
  "15/9",
  "15/11",
  "15/12",
  "16/1",
  "16/2",
  "16/3",
  "16/4",
  "16/5",
  "16/6",
  "16/7",
  "16/8",
  "16/9",
  "16/10",
  "16/11",
  "16/12",
  "17/1",
  "17/2",
  "17/3",
  "17/4",
  "17/5",
  "17/6",
  "17/7",
  "17/8",
  "17/9",
  "17/10",
  "17/11",
  "17/12",
  "18/1",
  "18/2",
  "18/3",
  "18/4",
  "18/5",
  "18/6",
  "18/7",
  "18/8",
  "18/9",
  "18/10",
  "18/11",
  "18/12",
  "19/1",
  "19/2",
  "19/4",
  "19/5",
  "19/6",
  "19/7",
  "19/8",
  "19/9",
  "19/10",
  "19/11",
  "19/12",
  "20/7",
  "20/10",
  "20/11",
  "20/12",
  "24/3",
  "24/6",
  "24/9",
  "24/11",
  "24/12",
  "25/1",
  "25/2",
  "25/3",
  "25/4",
  "25/5",
  "25/6",
  "25/7",
  "25/8",
  "25/9",
  "25/10",
  "25/11",
  "25/12",
  "26/1",
  "26/2",
  "26/3",
  "26/4",
  "26/5",
  "26/6",
  "26/7",
  "26/8",
  "26/9",
  "26/10",
  "26/11",
  "26/12",
  "27/1",
  "27/2",
  "27/3",
  "27/4",
  "27/5",
  "27/6",
  "27/7",
  "27/8",
  "27/9",
  "27/10",
  "27/11",
  "27/12",
  "28/1",
  "28/4",
  "28/10",
  "28/11",
  "29/3",
  "29/6",
  "29/9",
  "30/1",
  "30/2",
  "30/3",
  "30/4",
  "30/5",
  "30/6",
  "30/7",
  "30/8",
  "30/9",
  "30/10",
  "30/11",
  "30/12",
  "31/1",
  "31/2",
  "31/3",
  "31/4",
  "31/5",
  "31/6",
  "31/7",
  "31/8",
  "31/9",
  "31/10",
  "31/11",
  "31/12",
  "32/1",
  "32/2",
  "32/4",
  "32/5",
  "32/6",
  "32/7",
  "32/8",
  "32/9",
  "32/10",
  "32/11",
  "32/12",
  "33/2",
  "33/3",
  "33/5",
  "33/6",
  "33/9",
  "33/12",
  "34/1",
  "34/2",
  "34/3",
  "34/4",
  "34/5",
  "34/6",
  "34/7",
  "34/8",
  "34/9",
  "34/10",
  "34/11",
  "34/12",
  "35/1",
  "35/2",
  "35/3",
  "35/4",
  "35/5",
  "35/6",
  "35/7",
  "35/8",
  "35/9",
  "35/10",
  "35/11",
  "35/12",
  "36/1",
  "36/2",
  "36/3",
  "36/4",
  "36/5",
  "36/7",
  "36/8",
  "36/10",
  "36/11",
  "6200/2",
  "6200/3",
  "6200/6",
  "6201/1",
  "6201/2",
  "6201/3",
  "6201/4",
  "6201/5",
  "6201/6",
  "6201/7",
  "6201/8",
  "6201/9",
  "6201/10",
  "6201/11",
  "6201/12",
  "6202/1",
  "6202/2",
  "6202/3",
  "6202/4",
  "6202/5",
  "6202/6",
  "6202/7",
  "6202/8",
  "6202/9",
  "6202/10",
  "6202/11",
  "6202/12",
  "6203/1",
  "6203/2",
  "6203/3",
  "6203/4",
  "6203/5",
  "6203/6",
  "6203/7",
  "6203/8",
  "6203/9",
  "6203/10",
  "6203/11",
  "6203/12",
  "6204/1",
  "6204/2",
  "6204/3",
  "6204/4",
  "6204/5",
  "6204/6",
  "6204/7",
  "6204/8",
  "6204/9",
  "6204/10",
  "6204/11",
  "6204/12",
  "6205/1",
  "6205/2",
  "6205/3",
  "6205/4",
  "6205/5",
  "6205/6",
  "6205/7",
  "6205/8",
  "6205/10",
  "6206/1",
  "6206/2",
  "6206/3",
  "6206/4",
  "6300/1",
  "6300/2",
  "6300/3",
  "6300/4",
  "6300/5",
  "6300/6",
  "6300/7",
  "6300/8",
  "6300/9",
  "6300/10",
  "6300/11",
  "6300/12",
  "6301/1",
  "6301/2",
  "6301/3",
  "6301/4",
  "6301/5",
  "6301/6",
  "6301/7",
  "6301/8",
  "6301/9",
  "6301/10",
  "6301/11",
  "6301/12",
  "6302/1",
  "6302/2",
  "6302/3",
  "6302/4",
  "6302/5",
  "6302/6",
  "6302/7",
  "6302/8",
  "6302/9",
  "6302/10",
  "6302/11",
  "6302/12",
  "6303/1",
  "6303/2",
  "6303/3",
  "6303/4",
  "6303/5",
  "6303/6",
  "6303/7",
  "6303/8",
  "6303/9",
  "6303/10",
  "6303/11",
  "6303/12",
  "6304/1",
  "6304/2",
  "6304/3",
  "6304/4",
  "6304/5",
  "6304/6",
  "6304/7",
  "6304/8",
  "6304/9",
  "6304/10",
  "6304/11",
  "6304/12",
  "6305/1",
  "6305/2",
  "6305/3",
  "6305/4",
  "6305/5",
  "6305/6",
  "6305/7",
  "6305/8",
  "6305/9",
  "6305/10",
  "6305/11",
  "6305/12",
  "6306/1",
  "6306/2",
  "6306/3",
  "6306/4",
  "6306/5",
  "6306/6",
  "6306/7",
  "6306/8",
  "6306/9",
  "6306/10",
  "6306/11",
  "6306/12",
  "6307/1",
  "6307/2",
  "6307/3",
  "6307/4",
  "6307/5",
  "6307/6",
  "6307/7",
  "6307/8",
  "6307/9",
  "6307/10",
  "6308/1",
  "6308/2",
  "6308/4",
  "6401/2",
  "6401/3",
  "6401/5",
  "6401/6",
  "6401/8",
  "6401/9",
  "6401/11",
  "6401/12",
  "6402/1",
  "6402/2",
  "6402/3",
  "6402/4",
  "6402/5",
  "6402/6",
  "6402/7",
  "6402/8",
  "6402/9",
  "6402/10",
  "6402/11",
  "6402/12",
  "6403/1",
  "6403/2",
  "6403/3",
  "6403/4",
  "6403/5",
  "6403/6",
  "6403/7",
  "6403/8",
  "6403/9",
  "6403/10",
  "6403/11",
  "6403/12",
  "6404/1",
  "6404/2",
  "6404/3",
  "6404/4",
  "6404/5",
  "6404/6",
  "6404/7",
  "6404/8",
  "6404/9",
  "6404/10",
  "6404/11",
  "6404/12",
  "6405/1",
  "6405/2",
  "6405/3",
  "6405/4",
  "6405/5",
  "6405/6",
  "6405/7",
  "6405/8",
  "6405/9",
  "6405/10",
  "6405/11",
  "6405/12",
  "6406/1",
  "6406/2",
  "6406/3",
  "6406/4",
  "6406/5",
  "6406/6",
  "6406/7",
  "6406/8",
  "6406/9",
  "6406/10",
  "6406/11",
  "6406/12",
  "6407/1",
  "6407/2",
  "6407/3",
  "6407/4",
  "6407/5",
  "6407/6",
  "6407/7",
  "6407/8",
  "6407/9",
  "6407/10",
  "6407/11",
  "6407/12",
  "6408/4",
  "6408/7",
  "6408/10",
  "6408/11",
  "6408/12",
  "6409/10",
  "6501/2",
  "6501/3",
  "6501/5",
  "6501/6",
  "6501/8",
  "6501/9",
  "6501/11",
  "6501/12",
  "6502/1",
  "6502/2",
  "6502/3",
  "6502/4",
  "6502/5",
  "6502/6",
  "6502/7",
  "6502/8",
  "6502/9",
  "6502/10",
  "6502/11",
  "6502/12",
  "6503/1",
  "6503/2",
  "6503/3",
  "6503/4",
  "6503/5",
  "6503/6",
  "6503/7",
  "6503/8",
  "6503/9",
  "6503/10",
  "6503/11",
  "6503/12",
  "6504/1",
  "6504/2",
  "6504/3",
  "6504/4",
  "6504/5",
  "6504/6",
  "6504/7",
  "6504/8",
  "6504/9",
  "6504/10",
  "6504/11",
  "6504/12",
  "6505/1",
  "6505/2",
  "6505/3",
  "6505/4",
  "6505/5",
  "6505/6",
  "6505/7",
  "6505/8",
  "6505/9",
  "6505/10",
  "6505/11",
  "6505/12",
  "6506/1",
  "6506/2",
  "6506/3",
  "6506/4",
  "6506/5",
  "6506/6",
  "6506/7",
  "6506/8",
  "6506/9",
  "6506/10",
  "6506/11",
  "6506/12",
  "6507/1",
  "6507/2",
  "6507/3",
  "6507/4",
  "6507/5",
  "6507/6",
  "6507/7",
  "6507/8",
  "6507/9",
  "6507/10",
  "6507/11",
  "6507/12",
  "6508/1",
  "6508/2",
  "6508/3",
  "6508/4",
  "6508/5",
  "6508/6",
  "6508/7",
  "6508/8",
  "6508/9",
  "6508/11",
  "6508/12",
  "6509/1",
  "6509/2",
  "6509/3",
  "6509/4",
  "6509/5",
  "6509/6",
  "6509/7",
  "6509/8",
  "6509/9",
  "6509/10",
  "6509/11",
  "6509/12",
  "6510/1",
  "6510/2",
  "6510/3",
  "6510/4",
  "6510/5",
  "6510/7",
  "6510/8",
  "6510/10",
  "6601/2",
  "6601/3",
  "6601/5",
  "6601/6",
  "6601/8",
  "6601/9",
  "6601/11",
  "6601/12",
  "6602/1",
  "6602/2",
  "6602/3",
  "6602/4",
  "6602/5",
  "6602/6",
  "6602/7",
  "6602/8",
  "6602/9",
  "6602/10",
  "6602/11",
  "6602/12",
  "6603/1",
  "6603/2",
  "6603/3",
  "6603/4",
  "6603/5",
  "6603/6",
  "6603/7",
  "6603/8",
  "6603/9",
  "6603/10",
  "6603/11",
  "6603/12",
  "6604/1",
  "6604/2",
  "6604/3",
  "6604/4",
  "6604/5",
  "6604/6",
  "6604/7",
  "6604/8",
  "6604/9",
  "6604/10",
  "6604/11",
  "6604/12",
  "6605/1",
  "6605/2",
  "6605/3",
  "6605/4",
  "6605/5",
  "6605/6",
  "6605/7",
  "6605/8",
  "6605/9",
  "6605/10",
  "6605/11",
  "6605/12",
  "6606/1",
  "6606/2",
  "6606/3",
  "6606/4",
  "6606/5",
  "6606/6",
  "6606/7",
  "6606/8",
  "6606/9",
  "6606/10",
  "6606/11",
  "6606/12",
  "6607/1",
  "6607/2",
  "6607/3",
  "6607/4",
  "6607/5",
  "6607/6",
  "6607/7",
  "6607/8",
  "6607/9",
  "6607/10",
  "6607/11",
  "6607/12",
  "6608/1",
  "6608/2",
  "6608/3",
  "6608/4",
  "6608/5",
  "6608/6",
  "6608/7",
  "6608/8",
  "6608/9",
  "6608/10",
  "6608/11",
  "6608/12",
  "6609/1",
  "6609/2",
  "6609/3",
  "6609/4",
  "6609/5",
  "6609/6",
  "6609/7",
  "6609/8",
  "6609/9",
  "6609/10",
  "6609/11",
  "6609/12",
  "6610/1",
  "6610/2",
  "6610/3",
  "6610/4",
  "6610/5",
  "6610/6",
  "6610/7",
  "6610/8",
  "6610/9",
  "6610/10",
  "6610/11",
  "6610/12",
  "6611/1",
  "6611/2",
  "6611/4",
  "6611/5",
  "6611/7",
  "6611/10",
  "6703/4",
  "6703/5",
  "6703/6",
  "6703/7",
  "6703/8",
  "6703/9",
  "6703/10",
  "6703/11",
  "6703/12",
  "6704/4",
  "6704/5",
  "6704/6",
  "6704/7",
  "6704/8",
  "6704/9",
  "6704/10",
  "6704/11",
  "6704/12",
  "6705/1",
  "6705/2",
  "6705/3",
  "6705/4",
  "6705/5",
  "6705/6",
  "6705/7",
  "6705/8",
  "6705/9",
  "6705/10",
  "6705/11",
  "6705/12",
  "6706/1",
  "6706/2",
  "6706/3",
  "6706/4",
  "6706/5",
  "6706/6",
  "6706/7",
  "6706/8",
  "6706/9",
  "6706/10",
  "6706/11",
  "6706/12",
  "6707/1",
  "6707/2",
  "6707/3",
  "6707/4",
  "6707/5",
  "6707/6",
  "6707/7",
  "6707/8",
  "6707/9",
  "6707/10",
  "6707/11",
  "6707/12",
  "6708/1",
  "6708/2",
  "6708/3",
  "6708/4",
  "6708/5",
  "6708/6",
  "6708/7",
  "6708/8",
  "6708/9",
  "6708/10",
  "6708/11",
  "6708/12",
  "6709/1",
  "6709/2",
  "6709/3",
  "6709/4",
  "6709/5",
  "6709/6",
  "6709/7",
  "6709/8",
  "6709/9",
  "6709/10",
  "6709/11",
  "6709/12",
  "6710/1",
  "6710/2",
  "6710/3",
  "6710/4",
  "6710/5",
  "6710/6",
  "6710/7",
  "6710/8",
  "6710/9",
  "6710/10",
  "6710/11",
  "6711/1",
  "6711/2",
  "6711/4",
  "6711/5",
  "6808/9",
  "6808/10",
  "6808/11",
  "6808/12",
  "6809/6",
  "6809/7",
  "6809/8",
  "6809/9",
  "6809/10",
  "6809/11",
  "6809/12",
  "6810/7",
  "6810/8",
  "6810/10",
  "6810/11",
  "6810/12",
  "6811/10",
  "6918/1",
  "7016/1",
  "7016/2",
  "7016/3",
  "7016/4",
  "7016/5",
  "7016/6",
  "7016/7",
  "7016/8",
  "7016/9",
  "7017/1",
  "7017/2",
  "7017/3",
  "7017/4",
  "7017/5",
  "7017/6",
  "7017/7",
  "7017/8",
  "7017/9",
  "7018/1",
  "7018/2",
  "7018/3",
  "7018/4",
  "7018/5",
  "7018/6",
  "7018/7",
  "7018/8",
  "7018/9",
  "7018/10",
  "7018/11",
  "7019/1",
  "7019/2",
  "7019/3",
  "7019/4",
  "7019/5",
  "7019/6",
  "7019/7",
  "7019/8",
  "7019/9",
  "7020/1",
  "7020/2",
  "7020/3",
  "7020/4",
  "7020/5",
  "7020/6",
  "7020/7",
  "7020/8",
  "7021/1",
  "7021/2",
  "7021/3",
  "7021/4",
  "7021/5",
  "7021/6",
  "7022/1",
  "7022/2",
  "7022/3",
  "7022/4",
  "7023/1",
  "7023/2",
  "7028/2",
  "7028/3",
  "7029/1",
  "7029/2",
  "7029/3",
  "7029/6",
  "7030/1",
  "7030/2",
  "7030/3",
  "7030/4",
  "7030/5",
  "7030/6",
  "7030/9",
  "7031/1",
  "7031/2",
  "7031/3",
  "7031/4",
  "7031/5",
  "7031/6",
  "7031/7",
  "7031/8",
  "7031/9",
  "7116/1",
  "7116/2",
  "7116/3",
  "7116/4",
  "7116/5",
  "7116/6",
  "7116/7",
  "7116/8",
  "7116/9",
  "7116/10",
  "7116/11",
  "7116/12",
  "7117/1",
  "7117/2",
  "7117/3",
  "7117/4",
  "7117/5",
  "7117/6",
  "7117/7",
  "7117/8",
  "7117/9",
  "7117/10",
  "7117/11",
  "7117/12",
  "7118/1",
  "7118/2",
  "7118/3",
  "7118/4",
  "7118/5",
  "7118/6",
  "7118/7",
  "7118/8",
  "7118/9",
  "7118/10",
  "7118/11",
  "7118/12",
  "7119/1",
  "7119/2",
  "7119/3",
  "7119/4",
  "7119/5",
  "7119/6",
  "7119/7",
  "7119/8",
  "7119/9",
  "7119/10",
  "7119/11",
  "7119/12",
  "7120/1",
  "7120/2",
  "7120/3",
  "7120/4",
  "7120/5",
  "7120/6",
  "7120/7",
  "7120/8",
  "7120/9",
  "7120/10",
  "7120/11",
  "7120/12",
  "7121/1",
  "7121/2",
  "7121/3",
  "7121/4",
  "7121/5",
  "7121/6",
  "7121/7",
  "7121/8",
  "7121/9",
  "7121/10",
  "7121/11",
  "7121/12",
  "7122/1",
  "7122/2",
  "7122/3",
  "7122/4",
  "7122/5",
  "7122/6",
  "7122/7",
  "7122/8",
  "7122/9",
  "7122/10",
  "7122/11",
  "7122/12",
  "7123/1",
  "7123/2",
  "7123/3",
  "7123/4",
  "7123/5",
  "7123/6",
  "7123/7",
  "7123/8",
  "7123/9",
  "7123/10",
  "7123/11",
  "7123/12",
  "7124/1",
  "7124/2",
  "7124/3",
  "7124/4",
  "7124/5",
  "7124/6",
  "7124/7",
  "7124/8",
  "7124/9",
  "7124/10",
  "7124/11",
  "7124/12",
  "7125/1",
  "7125/2",
  "7125/3",
  "7125/4",
  "7125/5",
  "7125/6",
  "7125/7",
  "7125/8",
  "7125/9",
  "7125/10",
  "7125/11",
  "7125/12",
  "7126/1",
  "7126/2",
  "7126/3",
  "7126/4",
  "7126/5",
  "7126/6",
  "7126/7",
  "7126/8",
  "7126/9",
  "7126/10",
  "7126/11",
  "7126/12",
  "7127/1",
  "7127/2",
  "7127/3",
  "7127/4",
  "7127/5",
  "7127/6",
  "7127/7",
  "7127/8",
  "7127/9",
  "7127/10",
  "7127/11",
  "7127/12",
  "7128/1",
  "7128/2",
  "7128/3",
  "7128/4",
  "7128/5",
  "7128/6",
  "7128/7",
  "7128/8",
  "7128/9",
  "7128/10",
  "7128/11",
  "7128/12",
  "7129/1",
  "7129/2",
  "7129/3",
  "7129/4",
  "7129/5",
  "7129/6",
  "7129/7",
  "7129/8",
  "7129/9",
  "7129/10",
  "7129/11",
  "7129/12",
  "7130/1",
  "7130/2",
  "7130/3",
  "7130/4",
  "7130/5",
  "7130/6",
  "7130/7",
  "7130/8",
  "7130/9",
  "7130/10",
  "7130/11",
  "7130/12",
  "7131/1",
  "7131/2",
  "7131/3",
  "7131/4",
  "7131/5",
  "7131/6",
  "7131/7",
  "7131/8",
  "7131/9",
  "7131/10",
  "7131/11",
  "7131/12",
  "7215/1",
  "7215/2",
  "7215/3",
  "7215/4",
  "7215/5",
  "7215/6",
  "7215/7",
  "7215/8",
  "7215/9",
  "7215/10",
  "7215/11",
  "7215/12",
  "7216/1",
  "7216/2",
  "7216/3",
  "7216/4",
  "7216/5",
  "7216/6",
  "7216/7",
  "7216/8",
  "7216/9",
  "7216/10",
  "7216/11",
  "7216/12",
  "7217/1",
  "7217/2",
  "7217/3",
  "7217/4",
  "7217/5",
  "7217/6",
  "7217/7",
  "7217/8",
  "7217/9",
  "7217/10",
  "7217/11",
  "7217/12",
  "7218/1",
  "7218/2",
  "7218/3",
  "7218/4",
  "7218/5",
  "7218/6",
  "7218/7",
  "7218/8",
  "7218/9",
  "7218/10",
  "7218/11",
  "7218/12",
  "7219/1",
  "7219/2",
  "7219/3",
  "7219/4",
  "7219/5",
  "7219/6",
  "7219/7",
  "7219/8",
  "7219/9",
  "7219/10",
  "7219/11",
  "7219/12",
  "7220/1",
  "7220/2",
  "7220/3",
  "7220/4",
  "7220/5",
  "7220/6",
  "7220/7",
  "7220/8",
  "7220/9",
  "7220/10",
  "7220/11",
  "7220/12",
  "7221/1",
  "7221/2",
  "7221/3",
  "7221/4",
  "7221/5",
  "7221/6",
  "7221/7",
  "7221/8",
  "7221/9",
  "7221/10",
  "7221/11",
  "7221/12",
  "7222/1",
  "7222/2",
  "7222/3",
  "7222/4",
  "7222/5",
  "7222/6",
  "7222/7",
  "7222/8",
  "7222/9",
  "7222/10",
  "7222/11",
  "7222/12",
  "7223/1",
  "7223/2",
  "7223/3",
  "7223/4",
  "7223/5",
  "7223/6",
  "7223/7",
  "7223/8",
  "7223/9",
  "7223/10",
  "7223/11",
  "7223/12",
  "7224/1",
  "7224/2",
  "7224/3",
  "7224/4",
  "7224/5",
  "7224/6",
  "7224/7",
  "7224/8",
  "7224/9",
  "7224/10",
  "7224/11",
  "7224/12",
  "7225/1",
  "7225/2",
  "7225/3",
  "7225/4",
  "7225/5",
  "7225/6",
  "7225/7",
  "7225/8",
  "7225/9",
  "7225/10",
  "7225/11",
  "7225/12",
  "7226/1",
  "7226/2",
  "7226/3",
  "7226/4",
  "7226/5",
  "7226/6",
  "7226/7",
  "7226/8",
  "7226/9",
  "7226/10",
  "7226/11",
  "7226/12",
  "7227/1",
  "7227/2",
  "7227/3",
  "7227/4",
  "7227/5",
  "7227/6",
  "7227/7",
  "7227/8",
  "7227/9",
  "7227/10",
  "7227/11",
  "7227/12",
  "7228/1",
  "7228/2",
  "7228/3",
  "7228/4",
  "7228/5",
  "7228/6",
  "7228/7",
  "7228/8",
  "7228/9",
  "7228/10",
  "7228/11",
  "7228/12",
  "7229/1",
  "7229/2",
  "7229/3",
  "7229/4",
  "7229/5",
  "7229/6",
  "7229/7",
  "7229/8",
  "7229/9",
  "7229/10",
  "7229/11",
  "7229/12",
  "7230/1",
  "7230/2",
  "7230/3",
  "7230/4",
  "7230/5",
  "7230/6",
  "7230/7",
  "7230/8",
  "7230/9",
  "7230/10",
  "7230/11",
  "7230/12",
  "7231/1",
  "7231/2",
  "7231/3",
  "7231/4",
  "7231/5",
  "7231/6",
  "7231/7",
  "7231/8",
  "7231/9",
  "7231/10",
  "7231/11",
  "7231/12",
  "7315/1",
  "7315/2",
  "7315/3",
  "7315/4",
  "7315/5",
  "7315/6",
  "7315/7",
  "7315/8",
  "7315/9",
  "7315/10",
  "7315/11",
  "7315/12",
  "7316/1",
  "7316/2",
  "7316/3",
  "7316/4",
  "7316/5",
  "7316/6",
  "7316/7",
  "7316/8",
  "7316/9",
  "7316/10",
  "7316/11",
  "7316/12",
  "7317/1",
  "7317/2",
  "7317/3",
  "7317/4",
  "7317/5",
  "7317/6",
  "7317/7",
  "7317/8",
  "7317/9",
  "7317/10",
  "7317/11",
  "7317/12",
  "7318/1",
  "7318/2",
  "7318/3",
  "7318/4",
  "7318/5",
  "7318/6",
  "7318/7",
  "7318/8",
  "7318/9",
  "7318/10",
  "7318/11",
  "7318/12",
  "7319/1",
  "7319/2",
  "7319/3",
  "7319/4",
  "7319/5",
  "7319/6",
  "7319/7",
  "7319/8",
  "7319/9",
  "7319/10",
  "7319/11",
  "7319/12",
  "7320/1",
  "7320/2",
  "7320/3",
  "7320/4",
  "7320/5",
  "7320/6",
  "7320/7",
  "7320/8",
  "7320/9",
  "7320/10",
  "7320/11",
  "7320/12",
  "7321/1",
  "7321/2",
  "7321/3",
  "7321/4",
  "7321/5",
  "7321/6",
  "7321/7",
  "7321/8",
  "7321/9",
  "7321/10",
  "7321/11",
  "7321/12",
  "7322/1",
  "7322/2",
  "7322/3",
  "7322/4",
  "7322/5",
  "7322/6",
  "7322/7",
  "7322/8",
  "7322/9",
  "7322/10",
  "7322/11",
  "7322/12",
  "7323/1",
  "7323/2",
  "7323/3",
  "7323/4",
  "7323/5",
  "7323/6",
  "7323/7",
  "7323/8",
  "7323/9",
  "7323/10",
  "7323/11",
  "7323/12",
  "7324/1",
  "7324/2",
  "7324/3",
  "7324/4",
  "7324/5",
  "7324/6",
  "7324/7",
  "7324/8",
  "7324/9",
  "7324/10",
  "7324/11",
  "7324/12",
  "7325/1",
  "7325/2",
  "7325/3",
  "7325/4",
  "7325/5",
  "7325/6",
  "7325/7",
  "7325/8",
  "7325/9",
  "7325/10",
  "7325/11",
  "7325/12",
  "7326/1",
  "7326/2",
  "7326/3",
  "7326/4",
  "7326/5",
  "7326/6",
  "7326/7",
  "7326/8",
  "7326/9",
  "7326/10",
  "7326/11",
  "7326/12",
  "7327/1",
  "7327/2",
  "7327/3",
  "7327/4",
  "7327/5",
  "7327/6",
  "7327/7",
  "7327/8",
  "7327/9",
  "7327/10",
  "7327/11",
  "7327/12",
  "7328/1",
  "7328/2",
  "7328/3",
  "7328/4",
  "7328/5",
  "7328/6",
  "7328/7",
  "7328/8",
  "7328/9",
  "7328/10",
  "7328/11",
  "7328/12",
  "7329/1",
  "7329/2",
  "7329/3",
  "7329/4",
  "7329/5",
  "7329/6",
  "7329/7",
  "7329/8",
  "7329/9",
  "7329/10",
  "7329/11",
  "7329/12",
  "7330/1",
  "7330/2",
  "7330/3",
  "7330/4",
  "7330/5",
  "7330/6",
  "7330/7",
  "7330/8",
  "7330/9",
  "7330/10",
  "7330/11",
  "7330/12",
  "7331/1",
  "7331/2",
  "7331/3",
  "7331/4",
  "7331/5",
  "7331/6",
  "7331/7",
  "7331/8",
  "7331/9",
  "7331/10",
  "7331/11",
  "7331/12",
  "7415/7",
  "7415/8",
  "7415/9",
  "7415/10",
  "7415/11",
  "7415/12",
  "7416/7",
  "7416/8",
  "7416/9",
  "7416/10",
  "7416/11",
  "7416/12",
  "7417/7",
  "7417/8",
  "7417/9",
  "7417/10",
  "7417/11",
  "7417/12",
  "7418/7",
  "7418/8",
  "7418/9",
  "7418/10",
  "7418/11",
  "7418/12",
  "7419/7",
  "7419/8",
  "7419/9",
  "7419/10",
  "7419/11",
  "7419/12",
  "7420/7",
  "7420/8",
  "7420/9",
  "7420/10",
  "7420/11",
  "7420/12",
  "7421/7",
  "7421/8",
  "7421/9",
  "7421/10",
  "7421/11",
  "7421/12",
  "7422/7",
  "7422/8",
  "7422/9",
  "7422/10",
  "7422/11",
  "7422/12",
  "7423/7",
  "7423/8",
  "7423/9",
  "7423/10",
  "7423/11",
  "7423/12",
  "7424/7",
  "7424/8",
  "7424/9",
  "7424/10",
  "7424/11",
  "7424/12",
  "7425/7",
  "7425/8",
  "7425/9",
  "7425/10",
  "7425/11",
  "7425/12",
  "7426/7",
  "7426/8",
  "7426/9",
  "7426/10",
  "7426/11",
  "7426/12",
  "7427/7",
  "7427/8",
  "7427/9",
  "7427/10",
  "7427/11",
  "7427/12",
  "7428/7",
  "7428/8",
  "7428/9",
  "7428/10",
  "7428/11",
  "7428/12",
  "7429/7",
  "7429/8",
  "7429/9",
  "7429/10",
  "7429/11",
  "7429/12",
  "7430/7",
  "7430/8",
  "7430/9",
  "7430/10",
  "7430/11",
  "7430/12",
  "7431/7",
  "7431/8",
  "7431/9",
  "7431/10",
  "7431/11",
  "7431/12",
  "630W/2",
  "630W/3",
  "630W/6",
  "630W/9",
  "6307/11",
  "6308/3",
  "7018/12",
  "7032/1",
  "7032/2",
  "7032/3",
  "7032/4",
  "7032/5",
  "7032/7",
  "7032/8",
  "7132/1",
  "7132/2",
  "7132/3",
  "7132/4",
  "7132/5",
  "7132/6",
  "7132/7",
  "7132/8",
  "7132/9",
  "7132/10",
  "7132/11",
  "7132/12",
  "7133/1",
  "7133/2",
  "7133/3",
  "7133/4",
  "7133/5",
  "7133/6",
  "7133/7",
  "7133/8",
  "7133/10",
  "7134/1",
  "7232/1",
  "7232/2",
  "7232/3",
  "7232/4",
  "7232/5",
  "7232/6",
  "7232/7",
  "7232/8",
  "7232/9",
  "7232/10",
  "7232/11",
  "7232/12",
  "7233/1",
  "7233/2",
  "7233/3",
  "7233/4",
  "7233/5",
  "7233/6",
  "7233/7",
  "7233/8",
  "7233/9",
  "7233/10",
  "7233/11",
  "7233/12",
  "7234/1",
  "7234/2",
  "7234/3",
  "7234/4",
  "7234/5",
  "7234/6",
  "7234/7",
  "7234/8",
  "7234/9",
  "7234/10",
  "7234/11",
  "7234/12",
  "7235/1",
  "7235/2",
  "7235/3",
  "7235/4",
  "7235/5",
  "7235/7",
  "7332/1",
  "7332/2",
  "7332/3",
  "7332/4",
  "7332/5",
  "7332/6",
  "7332/7",
  "7332/8",
  "7332/9",
  "7332/10",
  "7332/11",
  "7332/12",
  "7333/1",
  "7333/2",
  "7333/3",
  "7333/4",
  "7333/5",
  "7333/6",
  "7333/7",
  "7333/8",
  "7333/9",
  "7333/10",
  "7333/11",
  "7333/12",
  "7334/1",
  "7334/2",
  "7334/3",
  "7334/4",
  "7334/5",
  "7334/6",
  "7334/7",
  "7334/8",
  "7334/9",
  "7334/10",
  "7334/11",
  "7334/12",
  "7335/1",
  "7335/2",
  "7335/3",
  "7335/4",
  "7335/5",
  "7335/6",
  "7335/7",
  "7335/8",
  "7335/9",
  "7335/10",
  "7335/11",
  "7335/12",
  "7336/1",
  "7336/2",
  "7336/3",
  "7336/4",
  "7336/5",
  "7336/6",
  "7336/7",
  "7336/8",
  "7336/9",
  "7336/10",
  "7337/1",
  "7337/4",
  "7432/7",
  "7432/8",
  "7432/9",
  "7432/10",
  "7432/11",
  "7432/12",
  "7433/7",
  "7433/8",
  "7433/9",
  "7433/10",
  "7433/11",
  "7433/12",
  "7434/7",
  "7434/8",
  "7434/9",
  "7434/10",
  "7434/11",
  "7434/12",
  "7435/7",
  "7435/8",
  "7435/9",
  "7435/10",
  "7435/11",
  "7435/12",
  "7436/7",
  "7436/8",
  "7436/9",
  "7436/10",
  "7436/11",
  "7436/12",
  "7437/7",
  "7437/10",
];
