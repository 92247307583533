import { NeutralColors } from "@fluentui/react";
import { useId } from "@fluentui/react-hooks";
import * as d3 from "d3";
import React, { FC, useEffect, useMemo } from "react";
import { TooltipType } from "../../../constants";
import { usePinnedTooltips } from "../../../context";
import {
  MissingDataCollection,
  PinnableTooltipData,
  Size,
  VerticalMargin,
} from "../../../types/types";
import { clearPlot } from "../../../utils";
import {
  addTooltipElement,
  createTooltipAdder,
  TooltipData,
  TooltipRowGroup,
} from "../plot-tooltip/plot-tooltip";

const HEIGHT_OF_DATA_WARNING = 28;
const INTERVAL_WARNING_ICON_PATH = "/files/icons/data_interval_warning.svg";
const INTERVAL_INFO_ICON_PATH = "/files/icons/data_interval_info.svg";
const WARNING_CLASSNAME = "data-warning-track__warning";

const noDataStyles: React.CSSProperties = {
  alignmentBaseline: "middle",
  textAnchor: "middle",
  writingMode: "vertical-lr",
  fill: NeutralColors.gray130,
  fontSize: 14,
};

interface DataWarningChartProps {
  data: MissingDataCollection[];
  size: Size;
  trackMargin?: VerticalMargin;
  cuttingDepths: number[];
  realDepthModeActive: boolean;
}

export const DataWarningChart: FC<DataWarningChartProps> = ({
  data,
  size,
  trackMargin = { top: 0, bottom: 0 },
  cuttingDepths,
  realDepthModeActive,
}) => {
  const { setActiveTooltip } = usePinnedTooltips();
  const graphContainerId = useId("data-warning-track");
  const dataContainerId = `${graphContainerId}__data-container`;
  const toolTipId = `${graphContainerId}__tool-tip`;

  const yScale = useMemo(() => {
    if (realDepthModeActive) {
      return d3
        .scaleLinear()
        .range([trackMargin.top, size.height - trackMargin.bottom])
        .domain([Math.min(...cuttingDepths), Math.max(...cuttingDepths)]);
    }
    return d3
      .scaleLinear()
      .range([trackMargin.top, size.height - trackMargin.bottom])
      .domain([0, cuttingDepths.length]);
  }, [
    realDepthModeActive,
    trackMargin.top,
    trackMargin.bottom,
    size.height,
    cuttingDepths,
  ]);

  /**
   * Initialize the plot
   */
  useEffect(() => {
    const svg = d3.select(`#${graphContainerId}`);

    svg.append("g").attr("id", dataContainerId);

    const tooltip = addTooltipElement(toolTipId);

    return () => {
      svg.selectAll("*").remove();
      tooltip.remove();
    };
  }, [dataContainerId, graphContainerId, toolTipId]);

  useEffect(() => {
    if (data) {
      clearPlot(dataContainerId);
      drawDataWarnings(
        yScale,
        data,
        setActiveTooltip,
        dataContainerId,
        toolTipId,
        realDepthModeActive
      );
    } else {
      clearPlot(dataContainerId);
    }
  }, [
    data,
    size,
    yScale,
    setActiveTooltip,
    dataContainerId,
    toolTipId,
    realDepthModeActive,
  ]);

  return (
    <svg id={graphContainerId} width={size.width} height={size.height}>
      {data.length === 0 && (
        <text x={size.width / 2} y={size.height / 2} style={noDataStyles}>
          No warnings
        </text>
      )}
    </svg>
  );
};

function drawDataWarnings(
  yScale: d3.ScaleLinear<number, number, never>,
  data: MissingDataCollection[],
  setActiveTooltip: (tooltipData: PinnableTooltipData | undefined) => void,
  dataContainerId: string,
  tooltipId: string,
  realDepthModeActive: boolean
) {
  const dataContainer = d3.select(`#${dataContainerId}`);

  // eslint-disable-next-line
  const pathAttributeSetter = (selection: any) => {
    selection
      .attr("xlink:href", (d: MissingDataCollection) =>
        d.missingStratigraphies.length > 0
          ? INTERVAL_INFO_ICON_PATH
          : INTERVAL_WARNING_ICON_PATH
      )
      .attr("class", WARNING_CLASSNAME)
      .attr(
        "transform",
        (d: MissingDataCollection) =>
          `translate(1, ${
            realDepthModeActive
              ? (yScale(d.startDepth) + yScale(d.endDepth)) / 2 -
                HEIGHT_OF_DATA_WARNING / 2
              : yScale(d.startDepthIndex) - HEIGHT_OF_DATA_WARNING / 2
          })`
      );
  };

  const [addTooltip, removeTooltip] = createTooltipAdder(
    tooltipId,
    getTooltipData,
    setActiveTooltip,
    TooltipType.WELLBORE_SYMBOL
  );

  dataContainer
    .selectAll(`.${WARNING_CLASSNAME}`)
    .data(data)
    .join(
      (enter) =>
        enter
          .append("svg:image")
          .call(pathAttributeSetter)
          .on("mouseover", addTooltip)
          .on("mouseout", removeTooltip),
      (update) => update.call(pathAttributeSetter),
      (exit) => exit.remove()
    );
}

const getTooltipData = (plotData: MissingDataCollection): TooltipData => {
  const tooltipRowGroups: TooltipRowGroup[] = [];

  if (plotData.missingDepthInterval) {
    tooltipRowGroups.push({
      heading: "Irregular interval",
      rows: [
        {
          label: `${plotData.startDepth}m - ${plotData.endDepth}m`,
          value: "",
        },
      ],
    });
  }

  if (plotData.missingStratigraphies.length > 0) {
    tooltipRowGroups.push({
      heading: "Missing stratigraphy",
      rows: plotData.missingStratigraphies.map((missingStratigraphy) => ({
        label: missingStratigraphy.name,
        value: `${missingStratigraphy.top}m - ${missingStratigraphy.bottom}m`,
      })),
    });
  }

  return {
    groups: tooltipRowGroups,
  };
};
