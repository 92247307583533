import React, { FC } from "react";
import { Stack } from "@fluentui/react";

import { VIEWER_MARGIN } from "../stage/config";

export const WIDTH_OF_SIDEBAR_MENU = 40;

const sidebarStyles = {
  root: {
    width: WIDTH_OF_SIDEBAR_MENU,
    paddingTop: VIEWER_MARGIN.top,
    paddingBottom: VIEWER_MARGIN.bottom,
    zIndex: 0,
  },
};

export const SidebarMenu: FC = ({ children }) => {
  return (
    <Stack styles={sidebarStyles} className="range-finder__sidebar">
      {children}
    </Stack>
  );
};
