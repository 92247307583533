import {
  MISSING_STRATIGRAPHY_COLOR,
  MISSING_STRATIGRAPHY_NAME,
} from "../../../config/stratigraphy";
import {
  Cutting,
  TopFormation,
  TopGroup,
  WellTopsPlotData,
} from "../../../types/types";
import { getFormattedStratigraphyName } from "../../../utils";

export function getPlotData(
  topsDataForWellbore: TopGroup[],
  cuttingDepths: Pick<Cutting, "depth">[],
  realDepthModeActive = false
): WellTopsPlotData[] {
  const groupPlotData = getPlotDataForWellbore(
    "group",
    topsDataForWellbore,
    cuttingDepths,
    realDepthModeActive
  );

  const formationsForWellbore = topsDataForWellbore.reduce<TopFormation[]>(
    (acc, t) => {
      acc.push(...t.formations);
      return acc;
    },
    []
  );

  const formationPlotData = getPlotDataForWellbore(
    "formation",
    formationsForWellbore,
    cuttingDepths,
    realDepthModeActive
  );

  return [...groupPlotData, ...formationPlotData];
}

function getPlotDataForWellbore(
  type: "group" | "formation",
  topsDataForWellbore: TopGroup[] | TopFormation[],
  depths: Pick<Cutting, "depth">[],
  RealDepthModeActive = false
): WellTopsPlotData[] {
  const wellTopsPlotData: WellTopsPlotData[] = [];

  const seenBefore: (TopGroup | TopFormation)[] = [];
  const hasBeenSeenBefore = (instance: TopGroup | TopFormation) =>
    seenBefore.includes(instance);

  for (let index = 0; index < depths.length; index++) {
    const cutting = depths[index];
    const formationOrGroupOfCutting = topsDataForWellbore.find((t) => {
      const isLastCutting = index === depths.length - 1;
      if (isLastCutting) {
        return t.top <= cutting.depth && cutting.depth <= t.bottom;
      }
      return t.top <= cutting.depth && cutting.depth < t.bottom;
    });

    const lastPlotData = wellTopsPlotData[wellTopsPlotData.length - 1];

    if (!formationOrGroupOfCutting) {
      if (lastPlotData && lastPlotData.name === "Undefined") {
        lastPlotData.numberOfCuttingsInFormation += 1;
      } else {
        const undefinedFormationOrGroup = getUndefined(
          type,
          lastPlotData?.bottom ?? cutting.depth,
          index
        );
        undefinedFormationOrGroup.numberOfCuttingsInFormation += 1;
        wellTopsPlotData.push(undefinedFormationOrGroup);
      }
    } else {
      endPreviousIfItIsUndefined(lastPlotData, formationOrGroupOfCutting);

      if (hasBeenSeenBefore(formationOrGroupOfCutting)) {
        lastPlotData.numberOfCuttingsInFormation += 1;
      } else {
        const plotData = getPlotDataForType(
          type,
          formationOrGroupOfCutting,
          index
        );
        plotData.numberOfCuttingsInFormation += 1;

        wellTopsPlotData.push(plotData);
        seenBefore.push(formationOrGroupOfCutting);
      }
    }

    if (RealDepthModeActive && index > 0) {
      const formationOrGroupBetweenCutting = topsDataForWellbore.find(
        (stratigraphy) => {
          const previusDepth = depths[index - 1].depth;
          return (
            previusDepth < stratigraphy.top &&
            stratigraphy.bottom <= cutting.depth
          );
        }
      );

      if (formationOrGroupBetweenCutting) {
        const plotData = getPlotDataForType(
          type,
          formationOrGroupBetweenCutting,
          index
        );
        plotData.numberOfCuttingsInFormation = 0;
        wellTopsPlotData.push(plotData);
      }
    }
  }

  endLastGroupOrFormationIfItIsUndefined(
    wellTopsPlotData,
    depths[depths.length - 1].depth
  );

  return wellTopsPlotData;
}

function getUndefined(
  type: "formation" | "group",
  startDepth: number,
  numberOfCuttingsAbove: number
): WellTopsPlotData {
  return {
    numberOfCuttingsAbove: numberOfCuttingsAbove,
    numberOfCuttingsInFormation: 0,
    type: type,
    color: MISSING_STRATIGRAPHY_COLOR,
    name: MISSING_STRATIGRAPHY_NAME,
    top: startDepth,
    bottom: 0,
    thickness: 0,
  };
}

function getPlotDataForType(
  type: "group" | "formation",
  groupOrFormationOfCutting: TopGroup | TopFormation,
  numberOfCuttingsAbove: number
): WellTopsPlotData {
  return {
    numberOfCuttingsAbove: numberOfCuttingsAbove,
    numberOfCuttingsInFormation: 0,
    type: type,
    color: groupOrFormationOfCutting.color,
    name: getFormattedStratigraphyName(groupOrFormationOfCutting.name),
    top: groupOrFormationOfCutting.top,
    bottom: groupOrFormationOfCutting.bottom,
    thickness: groupOrFormationOfCutting.thickness,
  };
}

function endPreviousIfItIsUndefined(
  lastPlotData: WellTopsPlotData,
  nextGroupOrFormation: TopGroup | TopFormation
): void {
  if (lastPlotData && lastPlotData.name === MISSING_STRATIGRAPHY_NAME) {
    lastPlotData.bottom = nextGroupOrFormation.top;
    lastPlotData.thickness = lastPlotData.bottom - lastPlotData.top;
  }
}

function endLastGroupOrFormationIfItIsUndefined(
  wellTopsPlotData: WellTopsPlotData[],
  lastDepth: number
) {
  const lastWellTopsPlotData = wellTopsPlotData[wellTopsPlotData.length - 1];

  if (lastWellTopsPlotData.name === MISSING_STRATIGRAPHY_NAME) {
    lastWellTopsPlotData.bottom = lastDepth;
    lastWellTopsPlotData.thickness =
      lastWellTopsPlotData.bottom - lastWellTopsPlotData.top;
  }
}
