import { v4 as uuidv4 } from "uuid";
import { getChartTypeToPlot } from "../components/cuttings-insight/dataAnalyticsPane/config";
import { getElementOriginalName, UNITS } from "../config/analytics";
import {
  JsonWithoutNull,
  Optional,
  PlotState,
  StoredPlot,
  StoredSelection,
} from "../types";
import { AnalyticsSiftGroup, AnalyticsValue } from "../types/sift/storeTypes";
import { getNewDefaultAnalyticsGroup, getQueryParam, getValue } from "../utils";
import { AnalyticsName } from "./../constants";
import { NumberRange } from "./../types/types";

export const WELLBORE_QUERY_PARAM_NAME = "well";
export const IMAGE_A_QUERY_PARAM_NAME = "imgA";
export const DEPTH_QUERY_PARAM_NAME = "depth";
export const TAB_QUERY_PARAM_NAME = "tab";
export const PLOTS_QUERY_PARAM_NAME = "plots";
export const GROUP_QUERY_PARAM_SUFFIX = "-gr";
export const ELEMENT_QUERY_PARAM_SUFFIX = "-el";
export const SIFT_ELEMENT_QUERY_PARAM_SUFFIX = "-siftel";
export const WELLBORE_QUERY_PARAM_NPDID = "npdId";
export const SELECTED_WELL_TOPS_QUERY_PARAM = "tops";

const DEFAULT_TOC_SELECTION = ["TOC"];

export const initialUrlSelections: Record<AnalyticsName, StoredSelection> = {
  [AnalyticsName.XRF]: {
    elements:
      getQueryParam<string[]>(
        `${AnalyticsName.XRF}${ELEMENT_QUERY_PARAM_SUFFIX}`
      ) ?? [],
    groups:
      getQueryParam<string[]>(
        `${AnalyticsName.XRF}${GROUP_QUERY_PARAM_SUFFIX}`
      ) ?? [],
  },
  [AnalyticsName.XRD]: {
    elements:
      getQueryParam<string[]>(
        `${AnalyticsName.XRD}${ELEMENT_QUERY_PARAM_SUFFIX}`
      ) ?? [],
    groups:
      getQueryParam<string[]>(
        `${AnalyticsName.XRD}${GROUP_QUERY_PARAM_SUFFIX}`
      ) ?? [],
  },
  [AnalyticsName.TOC]: {
    elements:
      getQueryParam<string[]>(
        `${AnalyticsName.TOC}${ELEMENT_QUERY_PARAM_SUFFIX}`
      ) ?? DEFAULT_TOC_SELECTION,
    groups:
      getQueryParam<string[]>(
        `${AnalyticsName.TOC}${GROUP_QUERY_PARAM_SUFFIX}`
      ) ?? [],
  },
  [AnalyticsName.SPEC_CAM]: {
    elements:
      getQueryParam<string[]>(
        `${AnalyticsName.SPEC_CAM}${ELEMENT_QUERY_PARAM_SUFFIX}`
      ) ?? [],
    groups:
      getQueryParam<string[]>(
        `${AnalyticsName.SPEC_CAM}${GROUP_QUERY_PARAM_SUFFIX}`
      ) ?? [],
  },
  [AnalyticsName.QEM_SCAN]: {
    elements:
      getQueryParam<string[]>(
        `${AnalyticsName.QEM_SCAN}${ELEMENT_QUERY_PARAM_SUFFIX}`
      ) ?? [],
    groups:
      getQueryParam<string[]>(
        `${AnalyticsName.QEM_SCAN}${GROUP_QUERY_PARAM_SUFFIX}`
      ) ?? [],
  },
};

export const initialUrlTab = getQueryParam(TAB_QUERY_PARAM_NAME);

export const initialUrlPlotStates: PlotState[] = (
  getQueryParam<JsonWithoutNull[]>(PLOTS_QUERY_PARAM_NAME) ?? []
).map((storedPlot) => {
  const plot = storedPlot as unknown as StoredPlot;
  return {
    id: uuidv4(),
    tab: plot.t,
    selectedChartType: plot.c,
    chartTypeToPlot: getChartTypeToPlot(plot.c, plot.t),
    data: undefined,
    units: UNITS[plot.t],
    domain: undefined,
    selectedElements: { elements: plot.e, groups: plot.g },
  };
});

export const initialUrlWellboreName = getQueryParam(
  WELLBORE_QUERY_PARAM_NAME
) as Optional<string>;

export const initialUrlWellboreNpdId = getQueryParam(
  WELLBORE_QUERY_PARAM_NPDID
) as Optional<string>;

export const initialUrlCuttingDepth = getQueryParam<number>(
  DEPTH_QUERY_PARAM_NAME
);
export const initialUrlImageA = getQueryParam<string>(IMAGE_A_QUERY_PARAM_NAME);

export const initialSelectedWellTops = getQueryParam<string>(
  SELECTED_WELL_TOPS_QUERY_PARAM
);

interface ParameterObjectType {
  name: string;
  value: number;
}

function getAutofillGeneratedValues(UNIT: string, input: number): NumberRange {
  const changeValue: number = input <= 100 ? 0.5 : input <= 1000 ? 5 : 50;
  const PERCENTAGE_MAX = 100;
  const MAX_VALUE = 1000000;
  return {
    low: Math.max(input - changeValue, 0),
    high: Math.min(
      Number(input) + Number(changeValue),
      UNIT.includes("%") ? PERCENTAGE_MAX : MAX_VALUE
    ),
  };
}

export const initialSiftUrlSelections: Record<
  AnalyticsName,
  JsonWithoutNull[]
> = {
  [AnalyticsName.XRF]:
    getQueryParam(`${AnalyticsName.XRF}${SIFT_ELEMENT_QUERY_PARAM_SUFFIX}`) ??
    [],
  [AnalyticsName.XRD]:
    getQueryParam(`${AnalyticsName.XRD}${SIFT_ELEMENT_QUERY_PARAM_SUFFIX}`) ??
    [],
  [AnalyticsName.TOC]:
    getQueryParam(`${AnalyticsName.TOC}${SIFT_ELEMENT_QUERY_PARAM_SUFFIX}`) ??
    [],
  [AnalyticsName.SPEC_CAM]:
    getQueryParam(
      `${AnalyticsName.SPEC_CAM}${SIFT_ELEMENT_QUERY_PARAM_SUFFIX}`
    ) ?? [],
  [AnalyticsName.QEM_SCAN]:
    getQueryParam(
      `${AnalyticsName.QEM_SCAN}${SIFT_ELEMENT_QUERY_PARAM_SUFFIX}`
    ) ?? [],
};

export const getInitialAnalyticsParameters = (): AnalyticsSiftGroup[] => {
  const siftGroup = getNewDefaultAnalyticsGroup();

  Object.entries(initialSiftUrlSelections).forEach(
    ([stringAnalyticsName, storedParameters]) => {
      const parameters = storedParameters as unknown as ParameterObjectType[];
      const analyticsName = stringAnalyticsName as AnalyticsName;
      siftGroup.analyticsParameters[analyticsName] = parameters.map(
        (parameterObject): AnalyticsValue => {
          const autoFilledValues = getAutofillGeneratedValues(
            UNITS[analyticsName][parameterObject.name],
            parameterObject.value
          );
          return {
            outputName: parameterObject.name,
            analyticsName: analyticsName,
            originalName: getElementOriginalName(
              analyticsName,
              parameterObject.name
            ),
            unit: UNITS[analyticsName][parameterObject.name],
            lowerValue: getValue(autoFilledValues.low.toString(), "", true, 2),
            higherValue: getValue(
              autoFilledValues.high.toString(),
              "",
              true,
              2
            ),
            lowerError: false,
            higherError: false,
            errorMessage: undefined,
            isInitialValue: true,
          };
        }
      );
    }
  );

  return [siftGroup];
};

export const initialAnalyticsParameters = getInitialAnalyticsParameters();
