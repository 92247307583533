import "firebase/compat/analytics";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { FirebaseConfig } from "../types";

class AppConfig implements Pick<FirebaseConfig, "apiKey" | "measurementId"> {
  apiKey = "";
  measurementId = "";

  set(config: FirebaseConfig) {
    this.apiKey = config.apiKey;
    this.measurementId = config.measurementId;
  }
}

export const firebaseConfig = new AppConfig();

const configureFirebase = async (): Promise<void> => {
  const response = await fetch("/__/firebase/init.json");
  const config = await response.json();
  if (process.env.REACT_APP_FIREBASE_CUSTOM_AUTH_DOMAIN) {
    config.authDomain = process.env.REACT_APP_FIREBASE_CUSTOM_AUTH_DOMAIN;
  }
  firebase.initializeApp(config);

  if (config.measurementId) {
    firebase.analytics();
  }

  firebaseConfig.set(config);
};

export default configureFirebase;
