import {
  Depths,
  IButtonStyles,
  mergeStyleSets,
  NeutralColors,
} from "@fluentui/react";
import React, { FC } from "react";
import { Breakpoint } from "../../../constants";
import {
  IconButton,
  navButtonStyles,
} from "../../common/styledFluentComponents";

const mapViewButtonStyles: IButtonStyles = {
  root: {
    boxShadow: Depths.depth16,
    color: NeutralColors.gray160,
    borderRadius: "2px",

    [Breakpoint.S]: {
      width: "40px",
      height: "40px",
    },
    [Breakpoint.M]: {
      width: "56px",
      height: "56px",
    },
    [Breakpoint.L]: {
      width: "72px",
      height: "72px",
      marginRight: "4px",
    },
  },
  iconChecked: {
    color: "white",
  },
  icon: {
    [Breakpoint.S]: {
      fontSize: 18,
    },
    [Breakpoint.M]: {
      fontSize: 22,
    },
    [Breakpoint.L]: {
      fontSize: 26,
    },
  },
};

interface MapButtonProps {
  onClick: () => void;
  ariaLabel?: string;
  checked: boolean;
}
const MapButton: FC<MapButtonProps> = ({ onClick, ariaLabel, checked }) => {
  return (
    <IconButton
      iconProps={{ iconName: "POISolid" }}
      ariaLabel={ariaLabel ? ariaLabel : "Toggle map view"}
      styles={mergeStyleSets(navButtonStyles, mapViewButtonStyles)}
      checked={checked}
      onClick={onClick}
    />
  );
};

export { MapButton };
