import { arrayIntersection, toFloat } from ".";
import { CurveKeys, LasFileJson } from "../types";

const curveKeyPosibilities: Record<CurveKeys, string[]> = {
  DEPTH: [
    "DEPTH",
    "DEPT",
    "TDEP",
    "Depth MD",
    "MDEP",
    "TOT_DEPTH",
    "Ret Dept",
    "DEPTH_1",
    "MD",
    "DMEA",
    "DPTH",
    "BOREHOLE-DEPTH",
    "DRTM",
    "Depth",
  ],
  GR: [
    "GR",
    "GRSL",
    "CGR",
    "ECGR",
    "EHGR",
    "GCGR",
    "GAMM",
    "GKGRAM",
    "GRAX",
    "GRBG",
    "GRC",
    "GRCO",
    "GRD",
    "GREC",
    "GRGC",
    "GRI",
    "GRL",
    "GRLL",
    "GRSG",
    "HCGR",
    "HGR",
    "HSGR",
    "SGR",
    "SGRC",
  ],
  CAL: [
    "CAL",
    "CALM",
    "CALS",
    "CALX",
    "CALY",
    "HCAL",
    "HCALI",
    "HDAR",
    "HDMI",
    "HDMX",
    "HLCA",
    "LA",
    "MCAL",
    "SA",
    "CALI",
  ],
};

export const NO_VALUES = [-999.25, 0];

export function getCurve(
  log: LasFileJson,
  logCurveName: CurveKeys
): (number | undefined)[] {
  const possibleCurveKeys = curveKeyPosibilities[logCurveName];
  const usedCurveKeys = Object.keys(log["CURVES"]);

  const curveName = arrayIntersection(possibleCurveKeys, usedCurveKeys).pop();

  if (curveName && log?.["CURVES"]?.[curveName]) {
    return log["CURVES"][curveName].map((d) =>
      toFloat(d, undefined, NO_VALUES)
    );
  }
  return [];
}
