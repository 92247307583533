import cloneDeep from "lodash/cloneDeep";
import { useEffect, useState } from "react";
import { Optional, Thumbnail, Wellbore } from "../../types";

export function useThumbnail(
  activeWellbore: Optional<Wellbore>,
  selectedImageType: Optional<string>
): Optional<Thumbnail> {
  const [thumbnail, setThumbnail] = useState<Thumbnail>();

  useEffect(() => {
    const thumbnail = cloneDeep(
      activeWellbore?.thumbnails?.find((t) => t.imageType === selectedImageType)
    );
    if (thumbnail) {
      thumbnail.chunks = thumbnail.chunks.sort((a, b) =>
        a.startIndex >= b.startIndex ? 1 : -1
      );
      setThumbnail(thumbnail);
    } else {
      setThumbnail(undefined);
    }
  }, [selectedImageType, activeWellbore]);

  return thumbnail;
}
