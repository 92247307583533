import {
  ContextualMenu as FluentContextualMenu,
  ContextualMenuItemType,
  IContextualMenuItem,
  IContextualMenuProps,
  IContextualMenuStyles,
  mergeStyleSets,
} from "@fluentui/react";
import { NeutralColors } from "@fluentui/theme";
import React, { FC, useMemo } from "react";
import { Colors } from "../../../constants";

export enum ContextualMenuSelectionMode {
  MULTIPLE,
  SINGLE,
}

export const contextualMenuStyles: Partial<IContextualMenuStyles> = {
  root: {
    background: NeutralColors.gray30,
  },
  title: {},
  container: {
    background: NeutralColors.gray30,
  },
  header: {
    background: NeutralColors.gray30,
    color: Colors.primary,
  },
  list: {
    selectors: {
      "button:hover": {
        background: NeutralColors.gray40,
      },
      "button:hover::after": {
        outline: "0 !important",
      },
      "button:focus": {
        background: `${NeutralColors.gray40} !important`,
      },
      "button:focus::after": {
        outline: `1px solid ${Colors.primary} !important`,
      },
      "button:active": {
        background: `${Colors.primary} !important`,
        color: NeutralColors.white,
      },
      "button:active .ms-ContextualMenu-secondaryText": {
        color: NeutralColors.white,
      },
      ".ms-ContextualMenu-icon": {
        color: "inherit",
      },
    },
  },
};

const multipleSelectionContextualMenuStyles = {
  header: {
    selectors: {
      ".ms-ContextualMenu-linkContent": {
        position: "relative",
      },
      ".ms-ContextualMenu-itemText": {
        position: "absolute",
        left: 0,
      },
    },
  },
  list: {
    selectors: {
      "button.is-checked": {
        background: NeutralColors.gray30,
        color: NeutralColors.gray160,
      },
      "button.is-checked:focus": {
        background: `${NeutralColors.gray40} !important`,
        color: `${NeutralColors.gray160} !important`,
      },
      "button.is-checked:hover": {
        background: `${NeutralColors.gray40} !important`,
        color: `${NeutralColors.gray160} !important`,
      },
      "button.is-checked:active": {
        background: `${Colors.primary} !important`,
        color: `${NeutralColors.white} !important`,
      },
      "button.is-disabled": {
        color: `${NeutralColors.gray90} !important`,
      },
    },
  },
};

const singleSelectionContextualMenuStyles = {
  list: {
    selectors: {
      "button.is-checked": {
        background: `${Colors.primary}`,
        color: "white",
      },
      "button.is-checked:focus": {
        background: `${Colors.primary} !important`,
        color: "white !important",
      },
      "button.is-checked:hover": {
        background: `${Colors.primary} !important`,
        color: "white !important",
      },
      "button.is-checked .ms-ContextualMenu-secondaryText": {
        color: "white",
      },
    },
  },
};

const getSelectionModeStyle = (selectionMode: ContextualMenuSelectionMode) => {
  switch (selectionMode) {
    case ContextualMenuSelectionMode.SINGLE:
      return singleSelectionContextualMenuStyles;
    case ContextualMenuSelectionMode.MULTIPLE:
      return multipleSelectionContextualMenuStyles;
  }
};

export const ContextualMenu: FC<
  IContextualMenuProps & {
    selectionMode?: ContextualMenuSelectionMode;
  }
> = ({ styles, selectionMode, ...restProps }) => {
  const mergedStyles = useMemo(
    () =>
      mergeStyleSets(
        contextualMenuStyles,
        selectionMode ? getSelectionModeStyle(selectionMode) : {},
        styles
      ),
    [selectionMode, styles]
  );
  return <FluentContextualMenu styles={mergedStyles} {...restProps} />;
};

export function getContextualMenuItems(
  title: string,
  items: IContextualMenuItem[]
): IContextualMenuItem[] {
  return [
    {
      key: `${title}-key`,
      itemType: ContextualMenuItemType.Section,
      sectionProps: {
        topDivider: true,
        bottomDivider: false,
        title: title,
        items: items,
      },
    },
  ];
}
