import { initializeIcons, registerIcons, ThemeProvider } from "@fluentui/react";
import { initializeFileTypeIcons } from "@fluentui/react-file-type-icons";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { NpdIcon, ResetIcon } from "./components/common/icons";
import "./index.scss";
import configureFirebase from "./services/configureFirebase";
import { WellifyTheme } from "./theme";

// Initalize icons for fluentUI
initializeIcons();
initializeFileTypeIcons();
registerIcons({
  icons: {
    Npd: <NpdIcon />,
    Reset: <ResetIcon />,
  },
});

configureFirebase()
  .then(async () => {
    ReactDOM.render(
      <React.StrictMode>
        <ThemeProvider applyTo="body" theme={WellifyTheme}>
          <App />
        </ThemeProvider>
      </React.StrictMode>,
      document.getElementById("root")
    );
  })
  .catch((error) => console.log(error.message));
