import { useCallback, useState } from "react";
import throttle from "lodash/throttle";

export function useThrottledState<T>(
  value: T,
  delay: number
): [T, (value: T) => void] {
  const [val, set] = useState<T>(value);

  // eslint-disable-next-line
  const throttledSetter = useCallback(
    throttle((newValue: T) => {
      set(newValue);
    }, delay),
    []
  );

  return [val, throttledSetter];
}
