import { v4 as uuidv4 } from "uuid";
import { Optional } from "../types";
import {
  AnalyticsSiftGroup,
  AnalyticsSiftParam,
  GroupType,
  SiftParameters,
} from "./../types/sift/storeTypes";

export function hasSomeAnalyticsParameters(
  analyticsParameters: AnalyticsSiftParam
): boolean {
  return Object.values(analyticsParameters).some(
    (values) => values.length !== 0
  );
}

export function siftParametersHasErrors(
  siftParameters: SiftParameters
): boolean {
  return (
    siftParameters.selectedAreaOfInterestBlocks.length === 0 ||
    siftParameters.selectedAnalyticsParameters.some(
      (group) =>
        !hasSomeAnalyticsParameters(group.analyticsParameters) ||
        Object.values(group.analyticsParameters).some((values) =>
          values.some(
            (value) =>
              value.higherError ||
              value.lowerError ||
              value.higherValue === "" ||
              value.lowerValue === ""
          )
        )
    )
  );
}

export const getNewDefaultAnalyticsGroup = (
  previusRelation: Optional<GroupType> = undefined
): AnalyticsSiftGroup => ({
  type: GroupType.AND,
  previusRelation: previusRelation,
  analyticsParameters: {
    qemscan: [],
    speccam: [],
    toc: [],
    xrd: [],
    xrf: [],
  },
  id: uuidv4(),
});
