import { useEffect } from "react";
import { useSelector } from "react-redux";
import { TooltipType } from "../../constants";
import { usePinnedTooltips } from "../../context";
import { StoreState } from "../../store/store";

export function useTooltipClearSelector<T>(
  selectFn: (state: StoreState) => T,
  pinnedTooltipType: TooltipType
): T {
  const value = useSelector(selectFn);

  const { clearPinnedTooltipsOfType } = usePinnedTooltips();

  useEffect(() => {
    clearPinnedTooltipsOfType(pinnedTooltipType);
  }, [clearPinnedTooltipsOfType, pinnedTooltipType, value]);

  return value;
}
