import { useState, useEffect } from "react";

export function useShouldRender<T = boolean>(signalToRender: T): boolean {
  const [shouldRender, setShouldRender] = useState(false);

  useEffect(() => {
    if (signalToRender) {
      setShouldRender(true);
    }
  }, [signalToRender]);

  return shouldRender;
}
