export function roundUp(
  val: number,
  numberOfDecimals?: 0 | 1 | 2 | 3 | 4 | 5
): number {
  if (!numberOfDecimals) {
    return Math.ceil(val);
  }
  return (
    Math.ceil(val * Math.pow(10, numberOfDecimals)) /
    Math.pow(10, numberOfDecimals)
  );
}

export function round(
  val: number,
  numberOfDecimals?: 0 | 1 | 2 | 3 | 4 | 5
): number {
  if (!numberOfDecimals) {
    return Math.round(val);
  }
  return (
    Math.round(val * Math.pow(10, numberOfDecimals)) /
    Math.pow(10, numberOfDecimals)
  );
}

export function roundByStep(val: number, step: number): number {
  const inverse = 1.0 / step;
  return Math.round(val * inverse) / inverse;
}

export function roundUpByStep(val: number, step: number): number {
  const inverse = 1.0 / step;
  return Math.ceil(val * inverse) / inverse;
}
