import {
  Callout,
  DirectionalHint,
  ICalloutContentStyles,
  SearchBox,
} from "@fluentui/react";
import { clamp } from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Colors } from "../../../constants";
import { getClasses, naturalOrderStringAscendingSort } from "../../../utils";

interface ItemSearchProps<T> {
  maxNumberOfSuggestions: number;
  placeHolderText?: string;
  suggestionBoxHeaderText: string;
  noSuggestionsFoundText: string;
  items: T[];
  onItemSelect: (item: T) => void;
  onRenderItem?: (item: T) => JSX.Element;
  getItemKey: (item: T) => string;
  getItemName: (item: T) => string;
}

export function ItemSearchBox<T>({
  maxNumberOfSuggestions,
  placeHolderText = "Search...",
  suggestionBoxHeaderText,
  noSuggestionsFoundText,
  items,
  onItemSelect,
  onRenderItem,
  getItemKey,
  getItemName,
}: ItemSearchProps<T>): JSX.Element {
  const [inputText, setInputText] = useState("");
  const [focusIndex, setFocusIndex] = useState(0);
  const [showCallout, setShowCallout] = useState(false);
  const [itemSuggestions, setItemSuggestions] = useState<T[]>([]);
  const searchBoxRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setFocusIndex(0);
    if (inputText === "") {
      setItemSuggestions([]);
      setShowCallout(false);
      return;
    }

    const itemSuggestions = items
      .filter(itemMatchesFilterText(getItemName, inputText))
      .sort(sortItemBySimilarity(getItemName, inputText))
      .slice(0, maxNumberOfSuggestions);

    setItemSuggestions(itemSuggestions);
    setShowCallout(true);
  }, [getItemName, inputText, items, maxNumberOfSuggestions]);

  const onKeyDownHandler = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      event.nativeEvent.stopImmediatePropagation();
      if (event.key === "ArrowUp") {
        event.preventDefault();
        setFocusIndex((prevIndex) => Math.max(0, prevIndex - 1));
      } else if (event.key === "ArrowDown") {
        event.preventDefault();
        setFocusIndex((prevIndex) =>
          Math.min(prevIndex + 1, itemSuggestions.length - 1)
        );
      }
    },
    [itemSuggestions]
  );

  const onInputChange = useCallback((_e?: unknown, newValue?: string) => {
    setInputText(newValue ?? "");
  }, []);

  const onSearchHandler = useCallback(() => {
    if (focusIndex < itemSuggestions.length) {
      setInputText("");
      onItemSelect(itemSuggestions[focusIndex]);
    }
  }, [focusIndex, itemSuggestions, onItemSelect]);

  const onFocusHandler = useCallback(() => {
    if (inputText) {
      setShowCallout(true);
    }
  }, [inputText]);

  const onCalloutDismiss = useCallback(() => {
    setShowCallout(false);
  }, []);

  const calloutStyles: Partial<ICalloutContentStyles> = {
    root: {
      background: Colors.white,
      width: clamp(searchBoxRef.current?.offsetWidth ?? 0, 150, 300),
    },
  };

  return (
    <>
      <div ref={searchBoxRef}>
        <SearchBox
          placeholder={placeHolderText}
          value={inputText}
          onChange={onInputChange}
          onSearch={onSearchHandler}
          onKeyDown={onKeyDownHandler}
          onFocus={onFocusHandler}
          autoFocus
        />
      </div>
      {showCallout && (
        <Callout
          className="suggestion-box"
          gapSpace={4}
          target={searchBoxRef.current}
          setInitialFocus
          onDismiss={onCalloutDismiss}
          isBeakVisible={false}
          directionalHint={DirectionalHint.bottomLeftEdge}
          styles={calloutStyles}
        >
          <h3>{suggestionBoxHeaderText}</h3>
          <div className="list-box">
            {itemSuggestions.map((suggestion, index) => (
              <div data-is-focusable key={getItemKey(suggestion)}>
                <button
                  className={getClasses("listing", {
                    focus: index === focusIndex,
                  })}
                  onClick={onSearchHandler}
                  onMouseOver={() => setFocusIndex(index)}
                >
                  {onRenderItem
                    ? onRenderItem(suggestion)
                    : getItemName(suggestion)}
                </button>
              </div>
            ))}
          </div>
          {itemSuggestions.length === 0 && (
            <div className="no-suggestions">{noSuggestionsFoundText}</div>
          )}
        </Callout>
      )}
    </>
  );
}
function itemMatchesFilterText<T>(
  getItemName: (item: T) => string,
  inputText: string
): (value: T, index: number, array: T[]) => unknown {
  return (item) =>
    getItemName(item).toLowerCase().includes(inputText.toLowerCase());
}

function sortItemBySimilarity<T>(
  getItemName: (item: T) => string,
  inputText: string
): ((a: T, b: T) => number) | undefined {
  return (a, b) => {
    const aName = getItemName(a).toLowerCase();
    const bName = getItemName(b).toLowerCase();
    const aIndex = aName.indexOf(inputText.toLowerCase());
    const bIndex = bName.indexOf(inputText.toLowerCase());
    if (aIndex === -1 || bIndex === -1) {
      return 0;
    }
    if (aIndex === bIndex) {
      return naturalOrderStringAscendingSort(aName, bName);
    }
    return aIndex - bIndex;
  };
}
