export const quadrants = [
  "1",
  "10",
  "11",
  "12",
  "13",
  "15",
  "16",
  "17",
  "18",
  "19",
  "2",
  "20",
  "24",
  "25",
  "26",
  "27",
  "28",
  "29",
  "3",
  "30",
  "31",
  "32",
  "33",
  "34",
  "35",
  "36",
  "4",
  "5",
  "6",
  "6200",
  "6201",
  "6202",
  "6203",
  "6204",
  "6205",
  "6206",
  "6300",
  "6301",
  "6302",
  "6303",
  "6304",
  "6305",
  "6306",
  "6307",
  "6308",
  "630W",
  "6401",
  "6402",
  "6403",
  "6404",
  "6405",
  "6406",
  "6407",
  "6408",
  "6409",
  "6501",
  "6502",
  "6503",
  "6504",
  "6505",
  "6506",
  "6507",
  "6508",
  "6509",
  "6510",
  "6601",
  "6602",
  "6603",
  "6604",
  "6605",
  "6606",
  "6607",
  "6608",
  "6609",
  "6610",
  "6611",
  "6703",
  "6704",
  "6705",
  "6706",
  "6707",
  "6708",
  "6709",
  "6710",
  "6711",
  "6808",
  "6809",
  "6810",
  "6811",
  "6918",
  "7",
  "7016",
  "7017",
  "7018",
  "7019",
  "7020",
  "7021",
  "7022",
  "7023",
  "7028",
  "7029",
  "7030",
  "7031",
  "7032",
  "7116",
  "7117",
  "7118",
  "7119",
  "7120",
  "7121",
  "7122",
  "7123",
  "7124",
  "7125",
  "7126",
  "7127",
  "7128",
  "7129",
  "7130",
  "7131",
  "7132",
  "7133",
  "7134",
  "7215",
  "7216",
  "7217",
  "7218",
  "7219",
  "7220",
  "7221",
  "7222",
  "7223",
  "7224",
  "7225",
  "7226",
  "7227",
  "7228",
  "7229",
  "7230",
  "7231",
  "7232",
  "7233",
  "7234",
  "7235",
  "7315",
  "7316",
  "7317",
  "7318",
  "7319",
  "7320",
  "7321",
  "7322",
  "7323",
  "7324",
  "7325",
  "7326",
  "7327",
  "7328",
  "7329",
  "7330",
  "7331",
  "7332",
  "7333",
  "7334",
  "7335",
  "7336",
  "7337",
  "7415",
  "7416",
  "7417",
  "7418",
  "7419",
  "7420",
  "7421",
  "7422",
  "7423",
  "7424",
  "7425",
  "7426",
  "7427",
  "7428",
  "7429",
  "7430",
  "7431",
  "7432",
  "7433",
  "7434",
  "7435",
  "7436",
  "7437",
  "8",
  "9",
];
