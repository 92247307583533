export function arraysIntersect<T>(arr1: T[], arr2: T[]): boolean {
  for (const element of arr1) {
    if (arr2.includes(element)) {
      return true;
    }
  }
  return false;
}

export function arrayIntersection<T>(arr1: T[], arr2: T[]): T[] {
  return arr1.filter((element) => arr2.includes(element));
}

export function arraysHaveSameElements<T extends string | number>(
  arr1: T[],
  arr2: T[]
): boolean {
  if (arr1.length !== arr2.length) {
    return false;
  }

  arr1.sort();
  arr2.sort();

  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) {
      return false;
    }
  }

  return true;
}

export function removeDuplicates<T>(arr: T[]): T[] {
  return Array.from(new Set(arr));
}

export function getNumItemsBetween(index1: number, index2: number): number {
  return Math.abs(index1 - index2) + 1;
}

export function toRecord<
  T extends { [K in keyof T]: string | number | symbol },
  K extends keyof T
>(array: T[], selector: K): Record<T[K], T> {
  return array.reduce((acc, item) => {
    acc[item[selector]] = item;
    return acc;
  }, {} as Record<T[K], T>);
}

/**
 * Filter functions
 */
export function out<T>(element: T): (el: T, index: number) => boolean {
  return (el) => el !== element;
}
