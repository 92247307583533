import { IIconStyles } from "@fluentui/react";
import React, { FC } from "react";
import { useSelector } from "react-redux";
import { Breakpoint } from "../../../constants";
import { selectSelectedWellbore } from "../../../store";
import { Optional, Wellbore } from "../../../types";
import { isTWellbore } from "../../../utils";
import { IconLink } from "../../common/styledFluentComponents";

const NPD_FACTPAGE_URL =
  "https://factpages.npd.no/en/wellbore/PageView/Exploration/All/";

export const NpdIconButton: FC = () => {
  const selectedWellbore = useSelector(selectSelectedWellbore);

  const disabled = !selectedWellbore?.npdId;
  const npdId = getNpdId(selectedWellbore);

  return (
    <IconLink
      disabled={disabled}
      href={`${NPD_FACTPAGE_URL}${npdId}`}
      target={npdId}
      toolTipText={
        !disabled
          ? "Open wellbore in NPD FactPages"
          : "Wellbore not available in FactPages"
      }
      iconProps={{
        iconName: "Npd",
        styles: npdIconStyles,
      }}
    />
  );
};

function getNpdId(wellbore: Optional<Wellbore>) {
  if (wellbore?.npdId) {
    if (isTWellbore(wellbore.name)) {
      return wellbore.npdId.substring(0, wellbore.npdId.indexOf("-"));
    } else {
      return wellbore.npdId;
    }
  }
  return undefined;
}

const npdIconStyles: IIconStyles = {
  root: {
    [Breakpoint.S]: {
      width: "24px",
      height: "24px",
    },
    [Breakpoint.M]: {
      width: "28px",
      height: "28px",
    },
    [Breakpoint.L]: {
      width: "32px",
      height: "32px",
    },
  },
};
