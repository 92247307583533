import { IColumn, IDropdownOption } from "@fluentui/react";
import { AnalyticsName, ChartType } from "../../../constants";
import { SortMethod } from "../../../utils/sorting";
import { HEAD_FOOT_HEIGHT } from "../track";

// Sidebar size configs
export const PLOTTER_SIDEBAR_WIDTH = 32;

// DataPlotter size config
export const MIN_WIDTH_OF_PLOTTER = 200;
export const DEFAULT_WIDTH_OF_PLOTTER = 600;
export const MAX_DATA_HEIGHT = 20;
export const MIN_POINT_RADIUS = 4;
export const EXTRA_VERTICAL_PLOT_MARGIN = 10;

export const PLOTTER_MARGIN = {
  top: HEAD_FOOT_HEIGHT + EXTRA_VERTICAL_PLOT_MARGIN,
  bottom: HEAD_FOOT_HEIGHT + EXTRA_VERTICAL_PLOT_MARGIN,
  left: 40,
  right: 45,
};

// Anyltics Pane size configs
export const HEIGHT_OF_MENU = 44;
export const WIDTH_OF_MENU = 379;
export const WIDTH_OF_SCROLLBAR = 4;
export const WIDTH_OF_ANALYTICS_LIST = WIDTH_OF_MENU + WIDTH_OF_SCROLLBAR;

export const MAX_WIDTH_OF_PLOTTERS = 1500;

export const ANALYTICS_PANE_DEFAULT_WIDTH =
  WIDTH_OF_ANALYTICS_LIST + PLOTTER_SIDEBAR_WIDTH + DEFAULT_WIDTH_OF_PLOTTER;
export const ANALYTICS_PANE_MIN_WIDTH = WIDTH_OF_ANALYTICS_LIST;
export const ANALYTICS_PANE_MAX_WIDTH =
  ANALYTICS_PANE_MIN_WIDTH + MAX_WIDTH_OF_PLOTTERS;

export const MAX_NUMBER_OF_PLOTS = 3;

export const DEFAULT_CHART_TYPE = ChartType.STACKED_BAR;
export const DEFAULT_CHART_TYPES: Record<
  AnalyticsName,
  Exclude<ChartType, ChartType.NONE>
> = {
  [AnalyticsName.XRF]: ChartType.STACKED_BAR,
  [AnalyticsName.TOC]: ChartType.STACKED_BAR,
  [AnalyticsName.XRD]: ChartType.STACKED_BAR,
  [AnalyticsName.QEM_SCAN]: ChartType.STACKED_BAR,
  [AnalyticsName.SPEC_CAM]: ChartType.STACKED_BAR,
};

export function getChartTypeToPlot(
  chartType: ChartType,
  tab: AnalyticsName
): Exclude<ChartType, ChartType.NONE> {
  return chartType === ChartType.NONE ? DEFAULT_CHART_TYPES[tab] : chartType;
}

export const sortOptions: IDropdownOption[] = [
  { key: SortMethod.STRING_ASCENDING, text: "Name: A to Z" },
  { key: SortMethod.STRING_DECENDING, text: "Name: Z to A" },
  { key: SortMethod.STRING_NUMBER_ASCENDING, text: "Value: Low to high" },
  { key: SortMethod.STRING_NUMBER_DECENDING, text: "Value: High to low" },
];

export const sortMethodDropdownStyles = { root: { width: 160 } };
export const infoIcon = { iconName: "Info" };
export const downloadIcon = { iconName: "Download" };
export const filterIcon = { iconName: "Filter" };
export const cancelIcon = { iconName: "Cancel" };
export const moreVerticalIcon = { iconName: "MoreVertical" };

export const columnsConfig: IColumn[] = [
  {
    key: "key",
    name: "key",
    fieldName: "key",
    minWidth: 173,
    maxWidth: 173,
    isResizable: false,
  },
  {
    key: "value",
    name: "value",
    fieldName: "value",
    minWidth: 82,
    maxWidth: 82,
    isResizable: false,
  },
  {
    key: "menu-bar",
    name: "menu-bar",
    fieldName: "menu-bar",
    className: "no-padding",
    minWidth: 16,
    maxWidth: 16,
    isResizable: false,
  },
];

export const PDFtoolTipTexts = {
  xrfData: "XRF info file from Diskos",
  xrfSample: "Sample Quality info file from Diskos",
  speccamData: "SpecCam info file from Diskos",
  xrdData: "XRD info file from Diskos",
  tocData: "TOC info file from Diskos",
  qemscanData: "QemScan detailed mineral info file from Diskos",
  qemscanSampleText: "QemScan info file from Diskos",
};

export const getExportToExcelTooltipText = (wellboreName: string): string =>
  `Export analytics data for wellbore ${wellboreName} to Excel`;
