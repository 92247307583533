import { useCallback } from "react";
import { Optional } from "../../types";
import { usePromise } from "./usePromise";

export function useFetchJson<T>(url: Optional<string>): Optional<T> {
  const fetchData = useCallback(() => {
    if (!url) return Promise.resolve(undefined);
    return fetch(url).then((res) => res.json());
  }, [url]);

  const [data] = usePromise<T>(fetchData);

  return data;
}
