import { ITag } from "@fluentui/react";
import { NumberRange } from "..";
import { AnalyticsName } from "../../constants";
import { SimpleAnalyticsElement, Wellbore } from "../types";
import { MissingDataCollection } from "./../types";
import { Optional } from "./../utils/base";

export interface AnalyticsValue extends SimpleAnalyticsElement {
  lowerValue: string;
  higherValue: string;
  lowerError: boolean;
  higherError: boolean;
  errorMessage: Optional<string>;
  isInitialValue: boolean;
}

export interface NumberRangeDetails {
  groupIndex: number;
  parameterIndex: number;
  analyticsValue: AnalyticsValue;
}

export type AnalyticsSiftParam = Record<AnalyticsName, AnalyticsValue[]>;

export enum GroupType {
  AND = "And",
  OR = "Or",
}

export interface AnalyticsSiftGroup {
  type: GroupType;
  previusRelation: Optional<GroupType>;
  analyticsParameters: AnalyticsSiftParam;
  id: string;
}

export interface SiftParameters {
  selectedAnalyticsParameters: AnalyticsSiftGroup[];
  selectedDepthInterval: Optional<NumberRange>;
  defaultDepthInterval: Optional<NumberRange>;
  selectedStratigraphyTags: ITag[];
  includeWellboresWithoutStratigraphy: boolean;
  showAllErrors: boolean;
  selectedAreaOfInterestBlocks: string[];
}

export interface DepthResult {
  depth: number;
  value: number;
}

export interface ElementResult {
  name: string;
  type: AnalyticsName;
  depthResults: DepthResult[];
  unit: string;
}
export interface SiftResults {
  wellboreResults: WellboreSiftResult[];
  pinnedWellboreResult: WellboreSiftResult;
  selectedStratigraphies: string[];
}

export interface WellboreSiftResult {
  wellbore: Wellbore;
  hits: number;
  elementResults: ElementResult[];
  distanceFromPinnedWellbore?: number;
  minDepth: number;
  maxDepth: number;
  hidden: boolean;
  cuttingsInsightUrl: string;
  filtered: boolean;
  missingDataWarnings: MissingDataCollection[];
}

export type SiftParameterDetail = {
  analyticsName: AnalyticsName;
  parameterIndex: number;
  groupIndex: number;
};

export type SiftElementDetail = {
  groupIndex: number;
  element: SimpleAnalyticsElement;
};
