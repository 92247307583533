import { AnalyticsName, WellTopsName } from "../constants";
import {
  AnalyticsDataKey,
  Cutting,
  Optional,
  PlotState,
  ProcessedTops,
  StoredSelection,
  StructuredData,
  Wellbore,
  ZoomArea,
} from "../types";
import { MissingDataCollection } from "./../types/types";
import { CuttingsInsightSliceState } from "./cuttingsInsightSlice";
import { StoreState } from "./store";

/**
 * Analytics Slice
 */
export const selectPlots = (state: StoreState): PlotState[] =>
  state.analytics.plots;

export const selectSelectedPlot = (state: StoreState): Optional<PlotState> =>
  state.analytics.plots.find((s) => s.id === state.analytics.selectedPlot);

export const selectSelectedTab = (state: StoreState): Optional<AnalyticsName> =>
  state.analytics.selectedTab;

export const selectAnalyticsPaneIsOpen = (state: StoreState): boolean =>
  Boolean(state.analytics.selectedTab);

export const selectOverlayAnalytics = (state: StoreState): boolean =>
  state.analytics.overlayAnalytics;

export const selectAllAnalyticsData = (
  state: StoreState
): Record<AnalyticsDataKey, Optional<StructuredData>> => ({
  xrfSampleInformation: state.analytics.srcData.xrfSampleInformation,
  qemscanSampleInformation: state.analytics.srcData.qemscanSampleInformation,
  xrf: state.analytics.srcData.xrf,
  speccam: state.analytics.srcData.speccam,
  xrd: state.analytics.srcData.xrd,
  toc: state.analytics.srcData.toc,
  qemscan: state.analytics.srcData.qemscan,
});

export const createSelectSelectedElements =
  (tab: AnalyticsName) =>
  (state: StoreState): StoredSelection =>
    state.analytics.tabSelections[tab];

export const selectTabSelections = (
  state: StoreState
): Record<AnalyticsName, StoredSelection> => state.analytics.tabSelections;

/**
 * Cuttings Insight slice
 */
export const selectWellbores = (state: StoreState): Wellbore[] =>
  state.cuttingsInsight.wellbores;

export const selectCuttingsState = (
  state: StoreState
): CuttingsInsightSliceState => state.cuttingsInsight;

export const selectSelectedWellbore = (state: StoreState): Optional<Wellbore> =>
  state.cuttingsInsight.selectedWellbore;

export const selectActiveWellbore = (state: StoreState): Optional<Wellbore> =>
  state.cuttingsInsight.activeWellbore;

export const selectSelectedCutting = (state: StoreState): Optional<Cutting> =>
  state.cuttingsInsight.selectedCutting;

export const selectSelectedImageType = (state: StoreState): Optional<string> =>
  state.cuttingsInsight.selectedImageType;

export const selectSelectedImageType2 = (state: StoreState): Optional<string> =>
  state.cuttingsInsight.selectedImageType2;

export const selectCuttings = (state: StoreState): Optional<Cutting[]> =>
  state.cuttingsInsight.cuttings;

export const selectActiveCuttings = (state: StoreState): Optional<Cutting[]> =>
  state.cuttingsInsight.activeCuttings;

export const selectCuttingsDepths = (state: StoreState): number[] =>
  state.cuttingsInsight.depths;

export const selectCuttingsDepthIndexes = (
  state: StoreState
): { [depth: number]: number } => state.cuttingsInsight.depthIndexes;

export const selectSelectedCuttingIndex = (
  state: StoreState
): Optional<number> =>
  state.cuttingsInsight.selectedCutting?.depth
    ? state.cuttingsInsight.depthIndexes[
        state.cuttingsInsight.selectedCutting.depth
      ]
    : undefined;

export const selectSelectedCuttingDepth = (
  state: StoreState
): Optional<number> => state.cuttingsInsight.selectedCutting?.depth;

export const selectSyncSelectedCutting = (state: StoreState): boolean =>
  state.cuttingsInsight.syncSelectedCutting;

export const selectWellTopsData = (
  state: StoreState
): Optional<ProcessedTops> => state.cuttingsInsight.wellTopsData;

export const selectSelectedWellTops = (
  state: StoreState
): Optional<WellTopsName> => state.cuttingsInsight.selectedWellTops;

export const selectShowWellTops = (state: StoreState): boolean =>
  Boolean(state.cuttingsInsight.selectedWellTops);

export const selectSelectedZoomArea = (state: StoreState): Optional<ZoomArea> =>
  state.cuttingsInsight.selectedZoomArea;

export const selectActiveZoomArea = (state: StoreState): Optional<ZoomArea> =>
  state.cuttingsInsight.activeZoomArea;

export const selectShowZoomAreaTrack = (state: StoreState): boolean =>
  state.cuttingsInsight.selectedZoomArea !== undefined &&
  state.cuttingsInsight.cuttings !== undefined &&
  !isDefaultZoomArea(
    state.cuttingsInsight.selectedZoomArea,
    state.cuttingsInsight.cuttings.length
  );

export const selectWellboreDataWarnings = (
  state: StoreState
): MissingDataCollection[] => state.cuttingsInsight.wellboreDataWarnings;

export const selectShowDataWarningTrack = (state: StoreState): boolean =>
  state.cuttingsInsight.showDataWarningTrack;

export const selectRealDepthModeActive = (state: StoreState): boolean =>
  state.cuttingsInsight.realDepthModeActive;

/**
 * Helpers
 */

export function isDefaultZoomArea(
  zoomArea: Optional<ZoomArea>,
  numberOfCuttings: number
): boolean {
  if (!zoomArea) {
    return false;
  }
  return zoomArea.topIdx === 0 && zoomArea.bottomIdx === numberOfCuttings - 1;
}
