import ls from "localstorage-slim";
import { WellDataSymbol } from "../components/cuttings-insight";
import { Optional } from "../types";

export const SELECTED_WELLBORE_LOCAL_STORAGE_KEY = "selectedWellbore";
export const SELECTED_WELLTOPS_LOCAL_STORAGE_KEY = "selectedWelltops";
export const SELECTED_WELLSYMBOLS_LOCAL_STORAGE_KEY = "selectedWellsymbols";
export const REAL_DEPTH_MODE_ACTIVE_LOCAL_STORAGE_KEY = "realDepthModeActive";
export const SHOW_DATA_WARNING_TRACK_LOCAL_STORAGE_KEY = "showDataWarningTrack";

export const SELECTED_SIFT_BACKDROP_LOCAL_STORAGE_KEY = "selectedSiftBackdrop";
export const SHOW_SIFT_STRATIGRAPHY_LOCAL_STORAGE_KEY = "showSiftStratigraphy";
export const SHOW_SIFT_DATA_WARNINGS_LOCAL_STORAGE_KEY = "showSiftDataWarnings";
export const SIFT_REAL_DEPTH_MODE_ACTIVE_LOCAL_STORAGE_KEY =
  "siftRealDepthModeActive";

function tryGetValueFromLocalStorage<T>(key: string, fallback: T) {
  try {
    return ls.get<T>(key) ?? fallback;
  } catch (err) {
    return fallback;
  }
}

/* Cuttings Insight initial localstorage values */
export const initialLocalStorageWellboreName = tryGetValueFromLocalStorage<
  Optional<string>
>(SELECTED_WELLBORE_LOCAL_STORAGE_KEY, undefined);

export const initialLocalStorageSelectedWelltops = tryGetValueFromLocalStorage<
  Optional<string>
>(SELECTED_WELLTOPS_LOCAL_STORAGE_KEY, undefined);

export const initialWellDataSymbolsFromLocalStorage =
  tryGetValueFromLocalStorage<Optional<WellDataSymbol[]>>(
    SELECTED_WELLSYMBOLS_LOCAL_STORAGE_KEY,
    undefined
  );

export const initialRealDepthModeActiveFromLocalStorage =
  tryGetValueFromLocalStorage<boolean>(
    REAL_DEPTH_MODE_ACTIVE_LOCAL_STORAGE_KEY,
    false
  );

export const initialShowDataWarningTrackFromLocalStorage =
  tryGetValueFromLocalStorage<boolean>(
    SHOW_DATA_WARNING_TRACK_LOCAL_STORAGE_KEY,
    false
  );

/* Sift initial localstorage values */
export const initialSiftSelectedBackdropFromLocalStorage =
  tryGetValueFromLocalStorage<string>(
    SELECTED_SIFT_BACKDROP_LOCAL_STORAGE_KEY,
    "W_1"
  );

export const initialSiftShowStratigraphyFromLocalStorage =
  tryGetValueFromLocalStorage<boolean>(
    SHOW_SIFT_STRATIGRAPHY_LOCAL_STORAGE_KEY,
    false
  );

export const initialSiftShowDataWarningsFromLocalStorage =
  tryGetValueFromLocalStorage<boolean>(
    SHOW_SIFT_DATA_WARNINGS_LOCAL_STORAGE_KEY,
    false
  );

export const initialSiftRealDepthModeActiveFromLocalStorage =
  tryGetValueFromLocalStorage<boolean>(
    SIFT_REAL_DEPTH_MODE_ACTIVE_LOCAL_STORAGE_KEY,
    false
  );
