import {
  DirectionalHint,
  Icon,
  IIconProps,
  IIconStyles,
  ILinkProps,
  ILinkStyles,
  ITooltipStyles,
  Link,
  mergeStyleSets,
  TooltipHost,
} from "@fluentui/react";
import { useId } from "@fluentui/react-hooks";
import { NeutralColors } from "@fluentui/theme";
import React, { FC, useMemo } from "react";
import { Colors } from "../../../constants";
import { getClasses } from "../../../utils";

export const IconLink: FC<
  ILinkProps & {
    toolTipText?: string;
    iconProps?: IIconProps;
  }
> = ({ styles, iconProps, toolTipText, disabled, ...restProps }) => {
  const mergedLinkStyles = useMemo(
    () =>
      mergeStyleSets(
        linkStyles,
        disabled ? disabledLinkStyles : undefined,
        styles
      ),
    [styles, disabled]
  );
  const mergedIconStyles = useMemo(
    () =>
      mergeStyleSets(
        iconStyles,
        disabled ? disabledIconStyles : undefined,
        iconProps?.styles ? iconProps.styles : undefined
      ),
    [iconProps?.styles, disabled]
  );

  // Use useId() to ensure that the ID is unique on the page.
  const tooltipId = useId("tooltip");

  if (toolTipText) {
    return (
      <span onClick={(e) => e.stopPropagation()}>
        <Link
          disabled={disabled} // => disabling the link will not fire onClick events, making it impossible to stop event propagation
          {...restProps}
          styles={mergedLinkStyles}
          aria-describedby={tooltipId}
          underline={false}
        >
          <TooltipHost
            content={toolTipText}
            id={tooltipId}
            directionalHint={DirectionalHint.rightCenter}
            styles={tooltipStyles}
          >
            <Icon
              {...iconProps}
              className={getClasses("icon-link__icon", iconProps?.className)}
              styles={mergedIconStyles}
            />
          </TooltipHost>
        </Link>
      </span>
    );
  }
  return (
    <Link
      {...restProps}
      styles={mergedLinkStyles}
      aria-describedby={tooltipId}
      underline={false}
    >
      <Icon
        {...iconProps}
        className={getClasses("icon-link__icon", iconProps?.className)}
        styles={mergedIconStyles}
      />
    </Link>
  );
};

const linkStyles: Partial<ILinkStyles> = {
  root: {
    display: "inline-block",
    selectors: {
      "&:hover, &:focus, &:active, &:active:hover": {
        textDecoration: "none",
      },
      "&:hover .icon-link__icon, &:focus .icon-link__icon": {
        background: NeutralColors.gray50,
      },
      "&:focus": {
        outline: `1px solid ${Colors.primary} !important`,
        outlineOffset: "-1px",
        boxShadow: "none !important",
      },
    },
  },
};

const disabledLinkStyles: ILinkStyles = {
  root: {
    selectors: {
      "&:hover .icon-link__icon, &:focus .icon-link__icon": {
        background: "transparent",
      },
    },
  },
};

const iconStyles: IIconStyles = {
  root: {
    width: "32px",
    height: "32px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "transparent",
    fontSize: 16,
    svg: {
      fill: NeutralColors.gray130,
    },
  },
};
const disabledIconStyles: IIconStyles = {
  root: {
    svg: {
      fill: NeutralColors.gray110,
    },
  },
};

const tooltipStyles: Partial<ITooltipStyles> = {
  root: {
    background: NeutralColors.gray10,
    color: NeutralColors.gray160,
  },
};
